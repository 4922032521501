import React from "react";
import { Container } from "reactstrap";
import FormChangePassword from "../components/FormChangePassword";

const ChangePassword = ({ profile, logout }) => {
    return (
        <div className="container-app py-3">
            <FormChangePassword profile={profile} logout={logout} />
        </div>
    )
}

export default ChangePassword;