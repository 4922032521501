import React, { useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText, Badge } from 'reactstrap';

import iconFollow from '../assets/icons/follow-navbar-icon.svg';
import iconLike from '../assets/icons/like-navbar-icon.svg' 
import iconPlay from '../assets/icons/play-navbar-icon.svg' 
import iconInvested from '../assets/icons/invested-navbar-icon.svg' 
import iconCircle from '../assets/icons/icon-circle-navbar.svg'
import menuBg from '../assets/images/menu-bg.png'

const TabBar = ({ incompleteProfile }) => {

    const location = useLocation();
    const navigate = useNavigate();

    const navigateTo = async (to) => {
        if(incompleteProfile) {
            await alert(incompleteProfile)
            navigate('/profile/')
        } else {
            navigate(to)
        }
    }

    return (
        <div className="menu">
            <div className="menu-bg">
                <div className="menu-bar" style={{backgroundImage: `url('${menuBg}')`}}>
                    {/* <img src="/menu-bar.svg"  /> */}
                </div>
                <img src="/menu.svg" className="menu-curve" />
                <div className="menu-bar" style={{backgroundImage: `url('${menuBg}')`}}>
                    {/* <img src="/menu-bar.svg" className="menu-flip" /> */}
                </div>
            </div>
            <div className="menu-content">
                <Navbar color="light" light>
                    <NavbarText className="tour-step-1">
                        <a onClick={() => navigateTo('/follow')} className={`d-flex flex-column align-items-center justify-content-center text-center text-decoration-none ${location.pathname.includes('/follow') ? 'text-danger' : 'text-secondary'}`}>
                            <img src={iconFollow} className="menu-icon mb-1" />
                            <small>SEGUIR</small>
                        </a>
                    </NavbarText>
                    <NavbarText className="tour-step-2">
                        <a onClick={() => navigateTo('/like')} className={`d-flex flex-column align-items-center justify-content-center text-center text-decoration-none ${location.pathname.includes('/like') ? 'text-danger' : 'text-secondary'}`}>
                            <img src={iconLike} className="menu-icon mb-1" />
                            <small>DAR LIKE</small>
                        </a>
                    </NavbarText>
                    <NavbarText className="tour-step-3 active">
                        <a onClick={() => navigateTo('/recharge')} className={` d-flex flex-column align-items-center justify-content-center text-center text-decoration-none ${location.pathname.includes('/recharge') ? 'text-danger' : 'text-secondary'}`}>
                            <img src={iconCircle} className="menu-icon" />
                        </a>
                    </NavbarText>
                    <NavbarText className="tour-step-4">
                        <a onClick={() => navigateTo('/video')} className={`d-flex flex-column align-items-center justify-content-center text-center text-decoration-none ${location.pathname.includes('/video') ? 'text-danger' : 'text-secondary'}`}>
                            <img src={iconPlay} className="menu-icon mb-1" />
                            <small>VER VIDEOS</small>
                        </a>
                    </NavbarText>
                    <NavbarText className="tour-step-5">
                        <a onClick={() => navigateTo('/promote#2')} className={`d-flex flex-column align-items-center justify-content-center text-center text-decoration-none ${location.pathname.includes('/promote') ? 'text-danger' : 'text-secondary'}`}>
                            <img src={iconInvested} className="menu-icon mb-1" />
                            <small>PROMOCIONATE</small>
                        </a>
                    </NavbarText>
                </Navbar>
            </div>
        </div>
    )
}

export default TabBar;