import React, { useEffect, useState, useRef } from 'react';
import { Container, Button, Label, Form, FormGroup, Input, FormFeedback, Row, Col, Spinner, Card, CardBody, Alert, InputGroup, InputGroupText } from 'reactstrap';
import Select from 'react-select';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import axios from 'axios';
import moment from 'moment';
// import { QrReader } from 'react-qr-reader';
import QrReader from 'modern-react-qr-reader'
import { toast } from 'react-toastify';
import { ModalFull, ModalFullHeader, ModalFullBody } from '../components/ModalFull';
import { getWebProfileInfo, createInstagramAccount, isMobile, viewImage, encrypt } from '../utils';

import avatar from '../assets/images/avatar.png';
import iconPlus from  '../assets/icons/icon-plus.svg'
import iconProfile from '../assets/icons/icon-profile.svg'
import iconBalance from '../assets/icons/icon-balance.svg';
import iconHistory from '../assets/icons/icon-history.svg';
import iconShazu from '../assets/images/shazu.svg';
import iconPen from '../assets/icons/icon-pen.svg';
import iconEye2 from '../assets/icons/icon-eye-2.svg';
import iconEye from '../assets/icons/icon-eye.svg';

const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

let sendingForm = false;

const steps = [
  {
    selector: '.tour-step-1',
    content: 'Sigue a perfiles españoles de Instagram para conseguir imanes y promociona tu perfil gratis.',
  },
  {
    selector: '.tour-step-2',
    content: 'Haz like a los post que más te gusten para conseguir imanes y promociona tus publicaciones gratis',
  },
  {
    selector: '.tour-step-3',
    content: 'Puedes recargar tu crédito en cualquier momento comprando imanes y ahorrarás mucho tiempo en interacción.'
  },
  {
    selector: '.tour-step-4',
    content: 'Reproduce los vídeos que más te gusten para conseguir imanes de una forma divertida y así promocinar los tuyos gratis.'
  },
  {
    selector: '.tour-step-5',
    content: 'Crea campañas personalizadas para conseguir crecer tu perfil de Instagram y obtener más interacción en tus publicaciones.'
  },
  {
    selector: '.tour-step-6',
    content: 'Consulta en cualquier momento y a tiempo real, el crédito e imanes que tienes disponibles en tu cuenta para promocionarte en SHAZU.'
  },
  {
    selector: '.tour-step-7',
    content: 'Guarda, agrupa y revisa varias compras en el carrito antes de pagar para ahorrarte pasar por caja una y otra vez.'
  },
  {
    selector: '.tour-step-8',
    content: 'Recibe notificaciones al instante para estar al día de todas las novedades, actividades y actualizaciones de tu cuenta de SHAZU.'
  },
  {
    selector: '.tour-step-9',
    content: 'Puedes agregar hasta 3 cuentas de Instagram a tu perfil de usuario de SHAZU para interactuar o crear promociones.'
  },
  {
    selector: '.tour-step-10',
    content: 'Accede de forma directa y rápida al historial completo de compras, podrás ordenar y filtrar todas tus transacciones.'
  },
  {
    selector: '.tour-step-11',
    content: 'El ID de usuario de SHAZU es un identificador de cliente único e intransferible, que se te solicitará para cualquier gestión en la app'
  },
  {
    selector: '.tour-step-12',
    content: 'El crédito indica la cantidad exacta de imanes que tienes para poder promocionar tus cuentas o publicaciones de Inastagram.'
  }
]

export default function Register({ checkedProfile, isOpenTour, isRegisterPage, logout, profile, accounts, defaultAccount, setAccounts, getAccounts, getProfile, incompleteProfile, setDefault }) {
  const [originalFields, setOriginalFields] = useState({});
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingNewAccount, setLoadingNewAccount] = useState(false);
  const [genders, setGenders] = useState([])
  const [provinces, setProvinces] = useState([])
  const [allCities, setAllCities] = useState([])
  const [cities, setCities] = useState([]);
  const [likesAndInterests, setLikesAndInterests] = useState([])
  const [changePassword, setChangePassword] = useState(false);
  const [showInvitationCodeField, setShowInvitationCodeField] = useState(false);
  const [initialData, setInitialData] = useState(profile);
  const [modalQRCode, setModalQRCode] = useState(false);
  const [qrCodeResult, setQRCodeResult] = useState(null);
  const [sendingSessionId, setSendingSessionId] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [sync, setSync] = useState(null);

  const { code, username } = useParams(); 
  const location = useLocation();
  const navigate = useNavigate();

  const formRef = useRef(null);

  const { setIsOpen, setSteps } = useTour()

  const isEditable = (field) => {
    if(originalFields[field] && originalFields[field] != '') {
      return true;
    }
    return false;
  }

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = async () => {
    let errors = {};
    let formIsValid = true;

    if (!fields['first_name']) {
      formIsValid = false;
      errors['first_name'] = 'Campo obligatorio.';
    }

    if (!fields['last_name']) {
      formIsValid = false;
      errors['last_name'] = 'Campo obligatorio.';
    }

    if (!fields['date_of_birth']) {
      formIsValid = false;
      errors['date_of_birth'] = 'Campo obligatorio.';
    }

    if (!fields['gender']) {
      formIsValid = false;
      errors['gender'] = 'Campo obligatorio.';
    }

    if (!fields['province']) {
      formIsValid = false;
      errors['province'] = 'Campo obligatorio.';
    }

    if (!fields['city']) {
      formIsValid = false;
      errors['city'] = 'Campo obligatorio.';
    }

    if (!fields['likes_and_interests'] || (fields['likes_and_interests'] && fields['likes_and_interests'].length === 0)) {
      formIsValid = false;
      errors['likes_and_interests'] = 'Campo obligatorio.';
    }

    if (fields['likes_and_interests'] && fields['likes_and_interests'].length > 0 && fields['likes_and_interests'].length < 5) {
      formIsValid = false;
      errors['likes_and_interests'] = 'Seleccione 5 opciones';
    }

    if (!fields['email']) {
      formIsValid = false;
      errors['email'] = 'Campo obligatorio.';
    }

    if (fields['email']) {
      let lastAtPos = fields['email'].lastIndexOf('@');
      let lastDotPos = fields['email'].lastIndexOf('.');

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields['email'].indexOf('@@') == -1 &&
          lastDotPos > 2 &&
          fields['email'].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors['email'] = 'El correo no es válido';
      }
    }

    if (!fields['phone']) {
      formIsValid = false;
      errors['phone'] = 'Campo obligatorio.';
    }

    if(fields['phone']) {
      try {
        let phoneNumber = phoneUtil.parseAndKeepRawInput(fields['phone'], 'ES');
        if (!phoneUtil.isValidNumber(phoneNumber)) {
          formIsValid = false;
          errors['phone'] = 'El teléfono no es válido';
        } else {
          let phone = phoneUtil.format(phoneNumber, PNF.E164);
          setFields({ ...fields, phone: phone });
        }
      } catch(err) {
        formIsValid = false;
        errors['phone'] = 'El teléfono no es válido';
      }
    }

    if(changePassword || isRegisterPage) {
        if (!fields['password']) {
            formIsValid = false;
            errors['password'] = 'Campo obligatorio.';
        }

        if (fields['password']) {
            let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if(!re.test(fields['password'])) {
            formIsValid = false;
            errors['password'] = 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caracter especial.';
            }
        }

        if (!fields['confirm_password']) {
            formIsValid = false;
            errors['confirm_password'] = 'Campo obligatorio.';
        }

        if (fields['confirm_password'] && fields['password'] && (fields['confirm_password'] !== fields['password'])) {
            formIsValid = false;
            errors['confirm_password'] = 'Las contraseñas no coinciden.';
        }
    }

    setErrors(errors);

    if(Object.keys(errors).length > 0) await alert('Hay errores en los datos del formulario. Revise el formulario y vuelva a enviarlo')
    
    return formIsValid;
  }

  const validatePhone = (e) => {
    e.preventDefault()
    let errors = {};

    if(fields['phone']) {
      try {
        let phoneNumber = phoneUtil.parseAndKeepRawInput(fields['phone'], 'ES');
        if (!phoneUtil.isValidNumber(phoneNumber)) {
          setErrors({...errors, phone: 'El teléfono no es válido'});
        } else {
          let phone = phoneUtil.format(phoneNumber, PNF.E164);
          setFields({...fields, phone: phone})
          setErrors({...errors, phone: null});
        }
      } catch(err) {
        setErrors({...errors, phone: 'El teléfono no es válido'});
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = await handleValidation();
    if (isValid) {
      registerOrUpdate();
    }
  }

  const registerOrUpdate = () => {
    setLoading(true);

    let _fields = {
        ...fields,
        username: isRegisterPage ? fields.email : profile.username,
    }

    validateInvitationCode(async (result) => {
      if(result) {
        axios[isRegisterPage ? 'post' : 'put'](isRegisterPage ? '/api/auth/register/' : '/api/auth/profile/', _fields)
          .then(async res => {
            
            setLoading(false);
            setErrors({});
            if(!isRegisterPage) {
              getProfile();

              if(changePassword) savePassword();

              var profileId = profile.id;
              sendInvitationCode(profileId, async (result) => {
                if(result) {
                  if(fields['invitation_code_guest']) {
                    setShowInvitationCodeField(false);
                    await alert('Código de invitación enviado.');
                  }
                  let data = {
                    ...res.data,
                    // date_of_birth: res.data.date_of_birth ? moment(res.data.date_of_birth, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
                  }
                  setInitialData({...profile, ...data})

                  getProfile();
                }
                toast('Guardado', {type: 'success'})
              })
            } else {
              var profileId = res.data.id;
              sendInvitationCode(profileId, async (result) => {
                setShowInvitationCodeField(false);
                await alert('Le enviamos un correo con el enlace de activación de la cuenta.');
                navigate('/login')
              })
            }
          })
          .catch(async err => {
            console.log('err register ', err.response.data)
            setLoading(false);
            if(isRegisterPage) {
              if(err.response.data.detail) {
                await alert(err.response.data.detail);
                setErrors({});
              } else {
                let errors = {};
                for(let key in err.response.data) {
                  errors[key] = err.response.data[key];
                }
                setErrors(errors)
              }
            } else {
              let errors = {};
              let typeKey = null
              for(let key in err.response.data) {
                typeKey = typeof key
                errors[key] = err.response.data[key];
              }
              if(typeKey === 'string') {
                setErrors(errors)
                await alert('Hay errores en los datos del formulario. Revise el formulario y vuelva a enviarlo')
              }
              else {
                await alert(JSON.stringify(err.response.data))
              }
            }
          })
      } else {
        setLoading(false);
        await alert('Código de invitación no válido');
      }
    });
  }

  const objectToList = (obj) => {
    let list = [];
    obj.forEach((item) => {
      list.push(item.value);
    })
    return list;
  }

  const getData = () => {
    axios.get('/api/config/genders/')
    .then((res) => {
      setGenders(res.data)
    });
    
  axios.get('/api/config/provinces/')
    .then((res) => {
      setProvinces(res.data)
    });

  axios.get('/api/config/cities/')
    .then((res) => {
      setAllCities(res.data)
    });

  axios.get('/api/config/likes_and_interests/')
    .then((res) => {
      let likesAndInterests = [];
      res.data.forEach((item, key) => {
        likesAndInterests.push({ 
          value: item.id, 
          label: item.name
        })
      })
      setLikesAndInterests(likesAndInterests)
    });
  }

  useEffect(() => {
    if(modalQRCode) {
      // formRef.current.submit();
    }
  }, [modalQRCode])

  useEffect(() => {
    if(window.location.hash === '#missing_session' && profile?.id) {
      if(isMobile()) {
        setTimeout(() => {
          setModalQRCode(true)
        }, 200)
        navigate('/profile')
      }
      else {
        setTimeout(() => {
          goToPopUp()
        }, 200)
      }
    }
  }, [location.hash, profile])

  useEffect(() => {
    document.body.className = 'register';

    if(code && username) {
      setLoadingNewAccount(true)
      getWebProfileInfo(username, (data) => {
        createInstagramAccount(
          username,
          data.data.user.id,
          localStorage.token,
          (data) => {
            navigate('/profile/')
            getAccounts(() => {
              setLoadingNewAccount(false)
            })
          },
          async (error) => {
            setLoadingNewAccount(false)
            console.log(error)
            for (let key in error.response.data) {
              if(error.response.data[key].toString() === 'Ya existe un/a user instagram con este/a iuser id.')
              await alert('Ya existe un usuario con esta cuenta de Instagram.');
              else
                console.log(error.response.data[key]);
            }
          }
        )
      })
    }

    if(isRegisterPage) {
      logout(false);
      getData();
    } else {
      getAccounts();
      getProfile(async (data) => {
        setOriginalFields(data);
        setFields(data);
        if(!data.first_name && !localStorage.registration_message_displayed && checkedProfile) {
          localStorage.registration_message_displayed = true;
          await alert('Para poder prestarte un servicio de mejor calidad y mayor seguridad, ¡vamos a hacerte unas preguntas!')
        }
        
        if(!data.has_password) {
          setChangePassword(true);
        } 

        setTimeout(() => {
          getData();
        }, 200)
      })     
    }

    if(localStorage.viewed_tour_1 !== 'true' && !isRegisterPage && isOpenTour) {
      localStorage.viewed_tour_1 = 'true'
      setIsOpen(true)
      setSteps(steps)
    }

    return () => { document.body.className = ''; }
  }, [])

  const savePassword = () => {
    let data = {password: fields.password}
    if(profile.has_password) {
      data = {
        old_password: fields.password,
        new_password: fields.password,
      }
    }

    axios.put(`/api/auth/${profile.has_password ? 'change-password' : 'set-password'}/`, data)
      .then(async (res) => {
        if(profile.has_password) {
          await alert(`Inicie sesión con su nueva contraseña.`)
          logout();
        }
      })
      .catch((err) => {
        let errors = {};
        for(let key in err.response.data) {
          errors[key] = err.response.data[key];
        }
        setErrors(errors)
      })
  }

  const validateInvitationCode = (callback) => {
    if(fields['invitation_code_guest']) {
      axios.post('/api/shareandwin/validate_code/', {
        invitation_code: fields.invitation_code_guest
      })
        .then(res => {
          callback(true);
        })
        .catch(err => {
          callback(false);
        })
    } else {
      callback(true);
    }
  }

  const sendInvitationCode = (profileId, callback) => {
    if(fields['invitation_code_guest']) {
      axios.post('/api/shareandwin/', {
        guest_user_id: profileId,
        invitation_code: fields.invitation_code_guest
      })
        .then(res => {
          setShowInvitationCodeField(false);
          if(callback) callback(true);
        })
        .catch(err => {
          if(callback) callback(false);
        })
    } else {
      callback(true);
    }
  }

  const getLikesAndInterestsSelected = () => {
    let likesAndInterestsSelected = [];
    if(likesAndInterests.length > 0 && fields?.likes_and_interests?.length > 0){
      likesAndInterests?.forEach((item) => {
        fields?.likes_and_interests?.forEach((like) => {
          if(item.value === like) {
            likesAndInterestsSelected.push(item)
          }
        })
      })
    }
    return likesAndInterestsSelected;
  }

  const removeAccount = async (account) => {
    if(accounts.length === 1) {
      await alert('Necesitas tener al menos una cuenta de Instagram.')
    }
    else if (window.confirm(`¿Quieres eliminar la cuenta @${account?.iusername} de Shazu?`) == true) {
      axios.patch(`/api/account/instagram/${account.id}/`, {is_active: false})
        .then(async (res) => {
          if(res.status === 200) {
            let _accounts = accounts.filter((item) => item.id !== account.id)
            if(account.is_default && _accounts.length > 0) {
              axios.post(`/api/account/instagram/${_accounts[0].id}/set_default/`)
                .then((res) => {
                  _accounts[0].is_default = true
                  setAccounts(_accounts)
                })
            } else {
              setAccounts(_accounts)
            }
          }
        })
        .catch(async error => {
          for (let key in error.response.data) {
            await alert(error.response.data[key]);
          }
        });
    }
  }

  const goToPopUp = async () => {
    if(profile?.id) {
      if(!document.body.getAttribute('shazu-extension-popup-url')) {
        await alert('Instale la extensión Shazu para continuar.');
        window.location.href = 'https://chrome.google.com/webstore/category/extensions'
      } else {
        let hash = encrypt(profile.id.toString())
        let url = document.body.getAttribute('shazu-extension-popup-url') + '?hash='+ hash + '&redirect='+ encodeURIComponent(process.env.REACT_APP_API_URL+'/auth/create-account') + '&back='+ window.location.href.split('#')[0] + '#fullwidth';
        window.location.href = url
      }
    }
  }

  const syncWithInstagram = (id) => {
    if(isMobile()) setModalQRCode(true)
    else goToPopUp()
  }

  const syncInstagramAccount = (account) => {
    setSync(account.iuser_id);
    axios.get('/api/account/instagram/check_username_update/', {
      withCredentials: true
    })
      .then(async (res) => {
        if(res.data.message.length > 0 && Array.isArray(res.data.message)) {
          getAccounts(async () => {
            let items = '';
            res.data.message.forEach(async (item) => {
              items += `${item}<br/>`
            })
            setSync(null);
            await alert(items);
          });
        } else {
          setSync(null);
          await alert(`Se han actualizado los datos del usuario @${account.iusername}.`)
        }
      })
      .catch((err) => {
        setSync(null);
      })
  }

  useEffect(() => {
    if(cities.length > 0 && fields?.province) setFields({ ...fields, city: null })
    setCities([]);
    if(fields?.province && allCities.length > 0) {
      let province = provinces.find(item => parseInt(item.id) === parseInt(fields?.province));
      let cities = allCities.filter(item => item.code?.substring(0, 2) === province.code?.substring(0, 2));
      setCities(cities);
    }
  }, [fields.province, allCities])

  return (
    <div className="register">
      <div className="register-circle" />
      <Container fluid className="mt-3 position-relative" style={{zIndex: 2}}>
          {
            isRegisterPage ?
              <img src={iconShazu} className="register-logo" />
            : null
          }

          {
            !isRegisterPage ? 
            <>
              <div className="register-profiles d-flex mb-3">
                {accounts && Array(3).fill().map((value, key) => {
                  return (
                    key !== 2 || (key === 2 && accounts && accounts[2]) ? 
                    <div className="register-profile d-flex align-items-center" style={{width: accounts && accounts[2] ? '33.33%' : '40%', minWidth: accounts && accounts[2] ? '33.33%' : '40%'}}>
                      <Card className={`w-100 text-center border-0 text-white p-1 ${accounts && accounts[key]?.is_default ? 'bg-danger-gradient' : 'bg-gray-1'} h-100`}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center p-2" onClick={() => accounts && accounts[key] ? syncWithInstagram() : null}>
                              <i className={`material-icons text-white font-size-20`} style={{visibility: accounts && accounts[key] ? 'visible' : 'hidden', cursor: 'pointer'}}>sync</i>
                          </div>
                          <div className="d-flex align-items-center p-2" onClick={() => accounts && accounts[key] ? syncInstagramAccount(accounts[key]) : null}>
                              {sync === accounts[key]?.iuser_id ? <Spinner size="sm" /> : <i className={`material-icons text-white font-size-20`} style={{visibility: accounts && accounts[key] ? 'visible' : 'hidden', cursor: 'pointer'}}>sync_alt</i>}
                          </div>
                          <div className="d-flex align-items-center p-2" onClick={() => accounts && accounts[key] ? removeAccount(accounts[key]) : null}>
                              <i className={`material-icons text-white ms-auto font-size-20`} style={{visibility: accounts && accounts[key] ? 'visible' : 'hidden', cursor: 'pointer'}}>delete</i>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <div className="font-metropolis font-size-12 text-center w-100">{accounts && accounts[key] ? 'Perfil' + (key+1) : 'Agregar'}</div>
                        </div>
                        <CardBody className='pt-0 p-0 d-flex flex-column align-items-center' onClick={() => accounts && accounts[key] ? setDefault(key) : syncWithInstagram()}>
                          <div className="position-relative">
                            <img src={accounts && accounts[key] ? accounts[key]?.iprofile_pic : avatar} className="register-photo border border-white border-6 d-block" />
                            {!accounts[key]?.iprofile_pic ? <div className="register-plus"></div> : null}
                          </div>
                          <div className="font-metropolis font-size-10 text-center w-100 mt-1 mb-2">{accounts && accounts[key] ? accounts[key].iusername : 'Nuevo Usuario'}</div>
                        </CardBody>
                      </Card>
                    </div>
                    : 
                    <div className="register-profile d-flex align-items-center" id="shazu-extension" style={{width: '33.33%'}}>
                      <img src={iconPlus} className="register-profile-plus tour-step-9" onClick={() => syncWithInstagram()} />
                      {/* <Button className="tour-step-9" onClick={() => syncWithInstagram()}>{true ? '+' : <Spinner size="sm" />}</Button> */}
                    </div> 
                  )
                })}
              </div>
            </>
            : null
          }

          {
            !isRegisterPage ? 
            <>
              <b className="text-dark-2 font-bebas-neue fw-bold font-size-16 mb-2 d-block">OPCIONES</b>
              <div className="register-top-row d-flex">
                <div className="register-top-col" style={{width: '40%'}}>
                  <Card className="register-top-card h-100 tour-step-11 p-2">
                    <img src={iconProfile} className="register-top-icon me-2" /> 
                    <div className="text-dark-3 font-size-10">
                      Id de Usuario <br />
                      <span className="text-danger-2 font-size-18">{profile?.id}</span>
                    </div>
                  </Card>
                </div>
                <div className="register-top-col" style={{width: '40%'}}>
                  <Card className="register-top-card h-100 tour-step-12 p-2">
                    <img src={iconBalance} className="register-top-icon me-2" /> 
                    <div className="text-dark-3 font-size-10">
                      Balance <br />
                      <span className="text-danger-2 font-size-18">{profile?.magnets}</span>
                    </div>
                  </Card>
                </div>
                <div className="register-top-col" style={{width: '20%'}}>
                  <Card className={`register-top-card h-100 d-flex align-items-center justify-content-center tour-step-10 ${incompleteProfile ? 'opacity-50' : ''}`} onClick={() => !incompleteProfile ? navigate('/balance/') : null}>
                    <img src={iconHistory} className="register-top-icon register-top-icon-history" /> 
                  </Card>
                </div>
              </div>

              <b className="text-dark-2 font-bebas-neue fw-bold font-size-16 mb-2 d-block">GENERALES</b>
            </>
            : null
          }

          <Form onSubmit={handleSubmit} noValidate>
              <Row>
                  <Col>
                      <FormGroup>
                          <Label className="text-uppercase">NOMBRE</Label>
                          <Input
                              id="first_name"
                              name="first_name"
                              type="text"
                              value={fields?.first_name}
                              invalid={errors?.first_name}
                              disabled={isEditable('first_name')}
                              onChange={(e) => handleChange('first_name', e.target.value)}
                          />
                          <FormFeedback>{errors?.first_name}</FormFeedback>
                      </FormGroup>
                  </Col>
                  <Col>
                      <FormGroup>
                          <Label className="text-uppercase">APELLIDO</Label>
                          <Input
                              id="last_name"
                              name="last_name"
                              type="text"
                              value={fields?.last_name}
                              invalid={errors?.last_name}
                              disabled={isEditable('last_name')}
                              onChange={(e) => handleChange('last_name', e.target.value)}
                          />
                          <FormFeedback>{errors?.last_name}</FormFeedback>
                      </FormGroup>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <FormGroup>
                          <Label className="text-uppercase">FECHA DE NACIMIENTO</Label>
                          <Input
                              id="date_of_birth"
                              name="date_of_birth"
                              type="date"
                              value={fields?.date_of_birth}
                              invalid={errors?.date_of_birth}
                              disabled={isEditable('date_of_birth')}
                              max={moment().subtract(18, 'years').format('YYYY-MM-DD')}
                              onChange={(e) => handleChange('date_of_birth', e.target.value)}
                          />
                          <FormFeedback>{errors?.date_of_birth}</FormFeedback>
                      </FormGroup>
                  </Col>
              </Row>
              <Row>
                  <Col>   
                      <FormGroup>
                          <Label className="text-uppercase">SEXO <img src={iconPen} className="form-icon-edit" /></Label>
                          <Input
                              id="gender"
                              name="gender"
                              type="select"
                              value={fields?.gender}
                              invalid={errors?.gender}
                              onChange={(e) => handleChange('gender', e.target.value)}
                          >
                              <option value={''} selected={!fields?.gender}></option>
                              {genders.map((gender, key) => {
                                  return <option key={key} value={gender.id} selected={fields?.gender === gender.id}>{gender.name}</option>
                              })}                            
                          </Input>
                          <FormFeedback>{errors?.gender}</FormFeedback>
                      </FormGroup>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <FormGroup>
                          <Label className="text-uppercase">Provincia</Label>
                          <Input
                              id="province"
                              name="province"
                              type="select"
                              value={fields?.province}
                              invalid={errors?.province}
                              disabled={isEditable('province')}
                              onChange={(e) => handleChange('province', e.target.value)}
                          >
                              <option value={''} selected={!fields?.province}></option>
                              {provinces.map((province, key) => {
                                  return <option key={key} value={province.id} selected={fields?.province === province.id}>{province.name}</option>
                              })}   
                          </Input>
                          <FormFeedback>{errors?.province}</FormFeedback>
                      </FormGroup>
                  </Col>
                  <Col>
                      <FormGroup>
                          <Label className="text-uppercase">Ciudad</Label>
                          <Input
                              id="city"
                              name="city"
                              type="select"
                              value={fields?.city}
                              invalid={errors?.city}
                              disabled={!fields?.province}
                              disabled={isEditable('city')}
                              onChange={(e) => handleChange('city', e.target.value)}
                          >
                              <option value={''} selected={!fields?.city}></option>
                              {cities.map((city, key) => {
                                  return <option key={key} value={city.id} selected={fields?.city === city.id}>{city.name}</option>
                              })} 
                          </Input>
                          <FormFeedback>{errors?.city}</FormFeedback>
                      </FormGroup>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <FormGroup>
                          <Label className="text-uppercase">GUSTOS E INTERESES <img src={iconPen} className="form-icon-edit" /></Label>
                          <Select
                              className={`form-select-custom ${errors?.likes_and_interests ? 'is-invalid' : ''}`}
                              placeholder=""
                              isMulti
                              name="likes_and_interests"
                              value={getLikesAndInterestsSelected()}
                              options={likesAndInterests} 
                              maxLength={2}
                              isClearable={false}
                              onInputChange={(e) => console.log(e)}
                              onChange={(e) => e.length <= 5 ? handleChange('likes_and_interests', objectToList(e)) : e.preventDefault()}
                          />
                          <FormFeedback>{errors?.likes_and_interests}</FormFeedback>
                      </FormGroup>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <FormGroup>
                          <Label className="text-uppercase">CORREO <img src={iconPen} className="form-icon-edit" /></Label>
                          <Input
                              id="email"
                              name="email"
                              type="email"
                              value={fields?.email}
                              invalid={errors?.email}
                              onChange={(e) => handleChange('email', e.target.value)}
                          />
                          <FormFeedback>{errors?.email}</FormFeedback>
                      </FormGroup>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <FormGroup>
                          <Label className="text-uppercase">TELÉFONO <img src={iconPen} className="form-icon-edit" /></Label>
                          <Input
                              id="phone"
                              name="phone"
                              type="tel"
                              invalid={errors?.phone}
                              value={fields?.phone}
                              onBlur={(e) => validatePhone(e)}
                              onChange={(e) => handleChange('phone', e.target.value)}
                          />
                          <FormFeedback>{errors?.phone}</FormFeedback>
                      </FormGroup>
                  </Col>
              </Row>

              {isRegisterPage || (!isRegisterPage && showInvitationCodeField) ?
              <Row>
                  <Col>
                      <FormGroup>
                          <Label className="text-uppercase">CÓDIGO DE INVITACIÓN</Label>
                          <Input
                              id="invitation_code_guest"
                              name="invitation_code_guest"
                              type="text"
                              value={fields?.invitation_code_guest}
                              invalid={errors?.invitation_code_guest}
                              onChange={(e) => handleChange('invitation_code_guest', e.target.value)}
                          />
                          <FormFeedback>{errors?.invitation_code_guest}</FormFeedback>
                      </FormGroup>
                  </Col>
              </Row>
              : null}
            
              {/* {(!changePassword && !isRegisterPage) || (changePassword && profile.has_password) ? 
              <p className="text-danger" onClick={() => setChangePassword(!changePassword)}>
                {!changePassword && !isRegisterPage ? 'Escribir una contraseña' : ''}
                {changePassword && profile.has_password ? 'No editar contraseña' : ''} 
              </p>    
              : null}      */}

              {isRegisterPage || changePassword ?
              <>
                <Row>
                    <Col>
                        <FormGroup>
                          <Label className="text-uppercase">Contraseña</Label>
                          <InputGroup className="input-group-password">
                            <Input
                                id="password"
                                name="password"
                                type={!showPassword1 ? 'password' : 'text' }
                                invalid={errors?.password}
                                onChange={(e) => handleChange('password', e.target.value)}
                            />
                            <InputGroupText onClick={() => setShowPassword1(!showPassword1)}>
                              <img src={!showPassword1 ? iconEye : iconEye2} />
                            </InputGroupText>
                          </InputGroup>
                          <FormFeedback className={errors?.password ? 'd-block' : ''}>{errors?.password}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                          <Label className="text-uppercase">Confirmar contraseña</Label>
                          <InputGroup className="input-group-password">
                            <Input
                                id="confirm_password"
                                name="confirm_password"
                                type={!showPassword2 ? 'password' : 'text' }
                                invalid={errors?.confirm_password}
                                onChange={(e) => handleChange('confirm_password', e.target.value)}
                            />
                            <InputGroupText onClick={() => setShowPassword2(!showPassword2)}>
                              <img src={!showPassword2 ? iconEye : iconEye2} />
                            </InputGroupText>
                          </InputGroup>
                          <FormFeedback className={errors?.confirm_password ? 'd-block' : ''}>{errors?.confirm_password}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
              </>
              : null}

              <Button type="submit" color="danger" className="font-size-16" block disabled={loading}>{!loading ? (isRegisterPage ? 'REGISTRAR' : 'GUARDAR') : 'AGUARDE...'} {loading ? <Spinner size="sm" /> : null}</Button>
              {/* {isRegisterPage ? <Button type="button" color="link" block className="login-bottom-text font-size-16 text-decoration-none" onClick={() => navigate('/login')}>ENTRAR</Button> : null} */}
              <br />
          </Form>
          
            <ModalFull className="register-scanner" isOpen={modalQRCode} toggle={() => setModalQRCode(false)}>
              <ModalFullHeader 
                center="Escanear código QR"
                toggle={() => setModalQRCode(false)} 
              />
              <ModalFullBody className="d-flex flex-column">
                <Alert className="rounded-0 p-3 font-size-12 m-0 bg-gray-1 text-dark-4">
                  Para vincular una cuenta de Instagram, instala la extensión de Google Chrome <b>Shazu</b> en tu computadora{isMobile() ? ' y escanee el código QR' : ''}. <br/>
                  <a href={!isMobile() ? 'https://chrome.google.com/webstore/' : null} target={!isMobile() ? '_blank' : ''}>https://chrome.google.com/webstore/</a>
                </Alert>

                <form method='post' id="form" action={process.env.REACT_APP_API_URL + '/auth/create-account'} className="mt-auto h-100 form-cam">
                  <input type="hidden" name="sessionid" value="" />
                  <input type="hidden" name="userid" value="" />
                  <input type="hidden" name="token" value="" />
                  {/* <input type="text" name="profile_pic_url" value="" /> */}

                  {
                    isMobile() ?
                      !sendingSessionId ? 
                      <QrReader
                        // constraints={{
                        //   facingMode: 'environment',
                        //   legacyMode: true
                        // }}
                        facingMode={"environment"}
                        onScan={(data) => {
                          if (data) {
                            setQRCodeResult(data)

                            if(!sendingForm) {
                              setSendingSessionId(true);
                              sendingForm = true;

                              let _data = JSON.parse(atob(data))
                              let hash = encrypt(profile.id.toString())
                              document.querySelector('[name="sessionid"]').value = JSON.stringify(_data?.sessionid)
                              document.querySelector('[name="userid"]').value = _data?.userid
                              document.querySelector('[name="token"]').value = hash
                              // document.querySelector('[name="profile_pic_url"]').value = data?.profile_pic_url
                              document.getElementById('form').submit()
                            }
                          }
                        }}
                        style={{ width: '100%' }}
                      />
                      :
                      <div className="d-flex flex-column justify-content-center align-items-center mb-4">
                        Enviando...
                        <Spinner />
                      </div>
                    : null
                  }
                </form>                
              </ModalFullBody>
            </ModalFull>
      </Container>      
    </div>

  );
}
