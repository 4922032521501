import React, { useEffect, useState } from 'react';
import { Container, Button, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import iconOrder from '../assets/icons/icon-order.svg';
import iconFilter from '../assets/icons/icon-filter.svg';
import iconMagnet from '../assets/icons/icon-magnet.svg';

const PromoteHistory = ({ profile, getProfile }) => {
    const [modalOrderVisible, setModalOrderVisible] = useState(false);
    const [order, setOrder] = useState('-created_at');
    const [modalFilterVisible, setModalFilterVisible] = useState(false);
    const [filter, setFilter] = useState([]);
    const [orders, setOrders] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [page, setPage] = useState(1);
    const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

    const navigate = useNavigate();

    const getOrders = (pagination=1, ordering='created_at', filter='') => {
        // if(pagination === 1) setOrders([]);
        setIsFetchingNextPage(true);
        axios.get(`/api/orders/history/?page=${pagination}&ordering=${ordering}${filter}`)
            .then(res => {
                if(pagination > 1) setOrders(orders.concat(res.data.results));
                else setOrders(res.data.results);
                setRefreshing(false);
                setHasNextPage(res.data.next);
                setIsFetchingNextPage(false);
            })
            .catch(err => {
                setRefreshing(false);
                setHasNextPage(false);
                console.log(err);
                setIsFetchingNextPage(false);
            })
    }

    const getFilters = (obj) => {
        let filters = '';
        obj.forEach((filter, index) => {
            let key = Object.keys(filter)[0]
            let value = filter[key];
            filters += `&${key}=${value}`;
        })
        return filters;
    }

    const getGasNextPage = () => {
        if(hasNextPage) {
            let _page = page + 1;
            setPage(_page);

            let filters = getFilters(filter);
            getOrders(_page, order, filters);
        }
    }

    const orderBy = (value) => {
        setOrder(value);

        setPage(1);

        let filters = getFilters(filter);
        getOrders(1, value, filters);
    }

    const filterBy = (value) => {
        setFilter(value);

        setPage(1);

        let filters = getFilters(value);
        console.log(filters)
        getOrders(1, order, filters);
    }

    useEffect(() => {
        let filters = getFilters(filter);
        setPage(1);
        getOrders(1, order, filters);
        getProfile();
    }, [])
        
    return (
        <>
            <div className="promote-history-container mt-4">
                <div className="d-flex align-items-center my-3">
                    <div className="text-uppercase text-dark-2 font-bebas-neue fw-bold font-size-16 mt-1">Ultimas ordenes</div>
                    <div className="ms-auto d-flex">
                        <div className="promote-history-select">
                            <img src={iconOrder} />
                            <select className="border-0" style={{width: 90}} onChange={(e) => orderBy(e.target.value)}>
                                <option value="-created_at">ORDENAR</option>
                                <option value="created_at">MENOR FECHA</option>
                                <option value="-created_at">MAYOR FECHA</option>
                            </select>
                        </div>
                        <div className="promote-history-filter ms-2 mt-1" onClick={() => setModalFilterVisible(true)}>
                            <img src={iconFilter} />
                            FILTRAR
                        </div>
                    </div>
                </div>
                {orders.map((item) => {
                    return (
                        <div className="py-3 border-bottom">
                            <div className="d-flex">
                                <div className={`promote-history-box ${item.status === 'finished' ? 'bg-success-gradient' : (item.status === 'canceled' ? 'bg-danger-gradient' : 'bg-secondary-gradient')} rounded me-2`}></div>
                                <div className="promote-history-type text-dark-2 font-size-14">
                                    <b className="fw-medium">
                                        {item.objective === 'followers' ? 'Seguidores' : null}
                                        {item.objective === 'likes' ? 'Likes' : null}
                                        {item.objective === 'views' ? 'Reproducciones' : null}
                                    </b><span className="mx-2">|</span>
                                    {item.quantity}
                                </div>
                                <div>
                                    {/* {item.items.map((itemCart, key) => {
                                        return <><small>{itemCart.quantity + ` x Pack${itemCart.quantity !== 1 ? 's' : ''} de ` + itemCart.product.magnets}</small><br/></>
                                    })} */}
                                </div>
                                <div className="ms-auto text-dark-2 font-size-14">
                                {item.magnets * item.quantity} <img src={iconMagnet} className="promote-history-magnet" />                      
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{height: 0}} className="me-2 promote-history-box"></div>
                                <div className="promote-history-status font-size-10">
                                    {item.status === 'in-queue' ? <span className="text-secondary">En fila</span> : null}
                                    {item.status === 'in-progress' ? <span className="text-secondary">En proceso</span> : null}
                                    {item.status === 'finished' ? <span className="text-success">Completado</span> : null}
                                    {item.status === 'canceled' ? <span className="text-danger">Cancelada</span> : null}
                                    
                                    <span>&nbsp;</span>
                                    <span className="font-size-6 ms-2 text-dark-5">{moment(item.created_at).format('DD/MM/YYYY')}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}
                
                {hasNextPage ?
                    <div className="d-flex justify-content-end mt-5">
                        <Button color="link" className="text-uppercase font-bebas-neue fw-bold text-decoration-none text-danger-2 font-size-12 mt-4 d-flex align-items-center" onClick={() => getGasNextPage()}>VER MÁS <span className="promote-history-arrow"></span></Button>
                    </div>
                : null}

                <Modal className="promote-history" isOpen={modalFilterVisible} toggle={() => setModalFilterVisible(false)}>
                    <ModalHeader toggle={() => setModalFilterVisible(false)}>Filtrar</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="exampleSelect">Estado</Label>
                            <Input
                                type="select"
                                onChange={(e) => filterBy([{status: e.target.value}])}
                            >
                                <option value=""></option>
                                <option value="in-queue">En fila</option>
                                <option value="in-progress">En proceso</option>
                                <option value="finished">Completado</option>
                                {/* <option value="canceled">Cancelada</option> */}
                            </Input>
                        </FormGroup>
                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}

export default PromoteHistory;