import React, { useEffect, useState } from 'react';
import { Container, Button, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

const Notifications = ({ profile }) => {
    const [modalOrderVisible, setModalOrderVisible] = useState(false);
    const [order, setOrder] = useState('-created_at');
    const [modalFilterVisible, setModalFilterVisible] = useState(false);
    const [filter, setFilter] = useState([]);
    const [balances, setBalances] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [page, setPage] = useState(1);
    const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

    const navigate = useNavigate();

    const getBalances = (pagination=1, ordering='created_at', filter='') => {
        // if(pagination === 1) setBalances([]);
        setIsFetchingNextPage(true);
        axios.get(`/api/notifications/?page=${pagination}&ordering=${ordering}${filter}`)
            .then(res => {
                if(pagination > 1) setBalances(balances.concat(res.data.results));
                else setBalances(res.data.results);
                setRefreshing(false);
                setHasNextPage(res.data.next);
                setIsFetchingNextPage(false);
            })
            .catch(err => {
                setRefreshing(false);
                setHasNextPage(false);
                console.log(err);
                setIsFetchingNextPage(false);
            })
    }

    const getFilters = (obj) => {
        let filters = '';
        obj.forEach((filter, index) => {
            let key = Object.keys(filter)[0]
            let value = filter[key];
            filters += `&${key}=${value}`;
        })
        return filters;
    }

    const getGasNextPage = () => {
        if(hasNextPage) {
            let _page = page + 1;
            setPage(_page);

            let filters = getFilters(filter);
            getBalances(_page, order, filters);
        }
    }

    const orderBy = (value) => {
        setOrder(value);

        setPage(1);

        let filters = getFilters(filter);
        getBalances(1, value, filters);
    }

    const filterBy = (value) => {
        setFilter(value);

        setPage(1);

        let filters = getFilters(value);
        console.log(filters)
        getBalances(1, order, filters);
    }

    useEffect(() => {
        let filters = getFilters(filter);
        setPage(1);
        getBalances(1, order, filters);
    }, [])
        
    return (
        <>
            <div className={`container-app pt-3 ${balances.length === 0 ? 'h-100' : ''}`}>
                {/* <div className="d-flex my-3">
                    <div className="text-uppercase"><small><b>Ultimas notificaciones</b></small></div>
                    <div className="ms-auto d-flex">
                        <div>
                        <small>
                                <select className="border-0" style={{width: 90}} onChange={(e) => orderBy(e.target.value)}>
                                    <option value="">ORDENAR</option>
                                    <option value="created_at">MENOR FECHA</option>
                                    <option value="-created_at">MAYOR FECHA</option>
                                </select>
                        </small>
                        </div>
                    
                    </div>
                </div> */}
                {balances.map((item) => {
                    return (
                        <div className="py-3 border-bottom" onClick={() => navigate('/notifications/' + item.id)}>
                            <div className="d-flex">
                                <div className={`text-truncate font-size-16 ${!item.read ? 'fw-bold' : ''}`}>
                                    {item?.message}
                                </div>
                                <div className="ms-auto font-size-16">
                                    {moment(item.created_at).format('DD/MM/YYYY')}                         
                                </div>
                            </div>
                        </div>
                    )
                })}

                {balances.length === 0 ?
                <div className="d-flex flex-column justify-content-center align-items-center text-center h-100">
                    <p className="font-size-12 mb-0">
                        Todavía no tienes ninguna notificación.
                    </p>
                </div>
                : null}
                
                {hasNextPage ?
                    <div className="text-center my-3">
                        <Button color="danger" size="sm" className="px-4" onClick={() => getGasNextPage()}>VER MÁS</Button>
                    </div>
                : null}

                {/* <Modal isOpen={modalFilterVisible} toggle={() => setModalFilterVisible(false)}>
                    <ModalHeader toggle={() => setModalFilterVisible(false)}>Filtrar</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="exampleSelect">Estado</Label>
                            <Input
                                type="select"
                                onChange={(e) => filterBy([{payment_status: e.target.value}])}
                            >
                                <option value=""></option>
                                <option value="processing">En proceso</option>
                                <option value="succeeded">Completada</option>
                                <option value="failed">Rechazada</option>
                            </Input>
                        </FormGroup>
                    </ModalBody>
                </Modal> */}
            </div>
        </>
    )
}

export default Notifications;