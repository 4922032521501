import axios from 'axios';
import React, { useState } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button, Input } from 'reactstrap';
import { viewImage } from '../utils';

import iconVideo from '../assets/icons/icon-video.svg';

const PromoteCards = ({ type, video, onChange, magnetsPerItem, value=0, maxInteractions, images, imageSize }) => {
    const [showMessage, setShowMessage] = useState(false);
    const [isPremium, setIsPremium] = useState(false);
    const [magnetsPerAction, setMagnetsPerAction] = useState(magnetsPerItem);

    let img = images.find((img) => img.width === imageSize.width)

    return (
        <div className="promote-card mb-3">
            <Card className="border-0 bg-secondary bg-opacity-10 p-0">
                <CardBody className="p-2">
                    <div className="position-relative border">
                        <img src={img ? viewImage(img.url) : null} className="promote-card-image w-100" />
                        {video || type === 'views' ? <img src={iconVideo} className="promote-card-video"/> : null}
                    </div>
                    <div className="promote-card-plus my-2 d-flex align-items-center" style={{fontSize: 6}}>
                        <label className={`font-bebas-neue fw-normal text-dark-4 font-size-8 ${value === 0 ? 'text-center d-block w-100' : ''}`}>
                            {
                                value === 0 ? 
                                    `NUMERO DE ${type.toUpperCase()} QUE QUIERES CONSEGUIR`
                                :
                                    `QUIERES DESTACAR MAS? `
                            }
                            {value > 0 ? <Input type="checkbox" onChange={(e) => setIsPremium(e.target.checked)} /> : null}
                        </label>

                        {value > 0 ?
                        <div className="ms-auto">
                            <input 
                                type="number" 
                                className="promote-card-input appearance-none" 
                                value={magnetsPerAction} 
                                disabled={!isPremium}
                                onChange={(e) => {
                                    let _magnets = e.target.value ? parseInt(e.target.value) : 0
                                    setMagnetsPerAction(parseInt(e.target.value))
                                    onChange(value, _magnets, isPremium, img.url)
                                }}
                                onBlur={(e) => {
                                    let _magnets = parseInt(e.target.value) >= magnetsPerItem ? parseInt(e.target.value) : magnetsPerItem
                                    setMagnetsPerAction(_magnets)
                                    onChange(value, _magnets, isPremium, img.url)
                                }}
                            />
                        </div>
                        : null}
                    </div>
                    <ButtonGroup size="sm" className="w-100">
                        <Button color="danger" onClick={() => {
                            onChange(value > 0 ? value - 1 : value, magnetsPerAction, isPremium, img.url);
                            setShowMessage(false);
                        }}>-</Button>
                        <Button color="white" className={`w-100 tour-step-promote-3`}>{value}</Button>
                        <Button color="danger" onClick={() => {
                            if(value + 1 <= maxInteractions) onChange(value <= maxInteractions ? value + 1 : value, magnetsPerAction, isPremium, img.url);
                            setShowMessage(value + 1 > maxInteractions)
                        }}>+</Button>
                    </ButtonGroup>
                    {showMessage ? <div style={{fontSize: 8}} className="mt-1">MAXIMO {maxInteractions} POR ORDEN</div> : null}
                </CardBody>
                <div className="w-100 bg-danger rounded-bottom" style={{height: 10}}></div>
            </Card>
        </div>
    )
}

export default PromoteCards;