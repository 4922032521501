import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


function PrevArrow(props) {
    const { className, style, onClick, onPrev, text, goToHome } = props;
    return (
        <div
            className={'text-uppercase fw-bold text-secondary'}
            // style={{ ...style }}
            onClick={(e) => text === 'SALTAR' ? goToHome() : onClick(e)}
        >{text}</div>
    );
}

function NextArrow(props) {
    const { className, style, onClick, text, goToHome } = props;
    return (
        <div
            className={'text-uppercase fw-bold text-danger'}
            // style={{ ...style }}
            onClick={(e) => text === 'EMPEZAR' ? goToHome() : onClick(e)}
        >{text}</div>
    );
}

export default function Tutorial({ config, viewedTutorial, setViewedTutorial }) {
    const slickRef = useRef(null);
    const [first, setFirst] = useState(false)
    const [last, setLast] = useState(false)
    const [slickIndex, setSlickIndex] = useState(0)
    const [data, setData] = useState(null)
    const [prev, setPrev] = useState('SALTAR')
    const [next, setNext] = useState('SIGUIENTE')

    const navigate = useNavigate();

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow text={next} goToHome={() => goToHome()} />,
        prevArrow: <PrevArrow text={prev} goToHome={() => goToHome()} />,
    };

    useEffect(() => {
      document.body.className = 'page-tutorial';
      if(localStorage.viewed_tutorial) goToHome()

      setViewedTutorial(true)

      axios.get('/api/cms/tutorial-community-norms/')
        .then((res) => {
          setData(res.data.results)
        })

    //   try {
    //     setInterval(() => {
    //       if(slickRef.current) {
    //         setSlickIndex(slickRef.current.state.index)
    //         setFirst(slickRef.current.state.index === 0)
    //         setLast(slickRef.current.state.index === slickRef.current.state.total - 1)
    //       }
    //     }, 100)
    //   } catch(err) {}

      return () => { document.body.className = ''; }
    }, [])

    const goToHome = async () => {
        setViewedTutorial(false)
        localStorage.viewed_tutorial = true
        navigate('/profile')
    }

    return (
        <div className="tutorial container-app h-100">
              <div className={`tutorial-close ${slickIndex > 0 ? 'd-flex' : 'd-none'}`} onClick={() => goToHome()}>
                  <i className="material-icons">close</i>
              </div>
            
            {data ? 
            <Slider 
                {...settings}
                className="slider h-100"
                afterChange={(e) => {
                    setSlickIndex(e)
                    setPrev(e === 0 ? 'SALTAR' : 'ATRÁS')
                    setNext(e === data.length -1 ? 'EMPEZAR' : 'SIGUIENTE')
                }}
            >
                {data && data.map((item, index) => {
                  return (
                    <div key={index} className="h-100" style={styles.slide}>
                        <div dangerouslySetInnerHTML={{__html: item.content}} />
                    </div>
                  )
                })}
            </Slider>
            : null}

          {/* {data && Platform.OS !== 'web' ? 
            <Slick 
              ref={slickRef}
              style={styles.wrapper} 
              showsButtons={true}
              showsPagination={false}
              loop={false}
              
              // dots={true}
              // infinite={true}
              // speed={500}
              // slidesToShow={1}
              // slidesToScroll={1}

              buttonWrapperStyle={{alignItems: 'flex-end', paddingLeft: 30, paddingRight: 30, paddingTop: 30, paddingBottom: 30}}
              prevButton={!first ?<p style={styles.prev}>ATRÁS</p> : <p></p>}
              nextButton={<p style={styles.next}>SIGUIENTE</p>}
            >
                {data && data.map((item, index) => {
                  return (
                    <div key={index} style={styles.slide}>
                      <ScrollView>
                         {index === 0 ? <Image source={{uri: config?.logo}} style={styles.logo} /> : null}
                        <Markdown 
                          styles={markdownStyle.collectiveMd} 
                          onLink={(url) => Linking.openURL(url)}
                        >
                          { item.content } 
                        </Markdown>
                      </ScrollView>
                    </div>
                  )
                })}
            </Slick>
            : null} */}
            {first ? <div style={styles.jump} onClick={() => goToHome()}><p style={styles.prev}>SALTAR</p></div> : null}
            {last ? <div style={styles.jumpNext} onClick={() => goToHome()}><p style={styles.next}>EMPEZAR</p></div> : null}
        </div>
    )
}

var styles = {
    slide: {
      flex: 1,
      backgroundColor: '#f2f3f4',
      padding: '40px',
      paddingTop: '60px',
      paddingBottom: '60px',
      textAlign: 'left',
    },
    logo: {
      width: '170px',
      height: '46px',
      marginBottom: '80px',
      alignSelf: 'center',
    },
    text: {
      color: '#000',
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    jump: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      padding: '30px'
    },
    jumpNext: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      padding: '30px'
    },
    prev: {
      color: '#717273',
      fontSize: '12px',
      fontWeight: 'bold',
    },
    next: {
      color: '#ff0000',
      fontSize: '14px',
      fontWeight: 'bold',
    },
}