import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../components/Header'
import MiniCart from '../components/MiniCart';
import TabBar from '../components/TabBar';

const Application = ({ config, pageTitle, defaultAccount, isOpenTour, setTourSteps, profile, incompleteProfile, cartItems, isOpenMiniCart, toggleMiniCart, updateCartItem, removeCartItem, getCartItems, logout, getAccounts, isOpenMenu, toggleMenu, notifications, hideHeader, hideHeaderButtons, hideFooter }) => {
    const navigate = useNavigate()

    useEffect(() => {
        document.getElementById('root').className = 'application';
        if(!localStorage?.token) navigate('/login')
        getAccounts();
        return () => { document.getElementById('root').className = ''; }
    }, [])

    return (
        <div className="application d-flex flex-column" style={{height: '100%'}}>
            {!hideHeader ? <Header config={config} pageTitle={pageTitle} defaultAccount={defaultAccount} hideHeaderButtons={hideHeaderButtons} logout={logout} incompleteProfile={incompleteProfile} profile={profile} cartItems={cartItems} toggleMiniCart={toggleMiniCart} isOpenMenu={isOpenMenu} toggleMenu={toggleMenu} notifications={notifications} /> : null}
            <div id="menu"></div>
            <MiniCart isOpenMiniCart={isOpenMiniCart} toggleMiniCart={toggleMiniCart} cartItems={cartItems} updateCartItem={updateCartItem} removeCartItem={removeCartItem} getCartItems={getCartItems} />
            <div className="application-content h-100" style={{overflow: 'hidden', overflowY: 'auto', paddingBottom: '20vw'}}>
                <Outlet />
            </div>
            {!hideFooter ? <TabBar incompleteProfile={incompleteProfile} /> : null}
        </div>
    )
}

export default Application;