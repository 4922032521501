import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, FormFeedback, FormGroup, Input, Button, Spinner, Label, InputGroup, InputGroupText } from 'reactstrap';

import ReactCodeInput from 'react-code-input';

const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export default function CheckPhone({ phoneNotVerified, setPhoneNotVerified, profile }) {
  const [fields, setFields] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [showInputNewNumber, setShowInputNewNumber] = React.useState(false);
  const [showInputVerificationCode, setShowInputVerificationCode] = React.useState(false);
  const [data, setData] = React.useState(null);

  const [value, setValue] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if(!phoneNotVerified) navigate('/')

    axios.get('/api/cms/phoneverification/')
      .then((res) => {
        setData(res.data.results[0])
      })

    handleChange('phone', profile?.phone)
    if(profile?.phone === null || profile?.phone === '') {
        setShowInputNewNumber(true)
    }
  }, [])

  useEffect(() => {
    if(value.length === 4) {
      checkCode(value);
    }
  }, [value])

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!fields['phone']) {
      formIsValid = false;
      errors['phone'] = 'Campo obligatorio.';
    }

    // if(fields['phone']) {
    //   try {
    //     let phone = fields?.phone?.trim()?.replace(/ /g, '')
    //     let phoneNumber = phoneUtil.parseAndKeepRawInput(phone, 'ES');
    //     if (!phoneUtil.isValidNumber(phoneNumber)) {
    //       formIsValid = false;
    //       errors['phone'] = 'El teléfono no es válido';
    //     } else {
    //       let phone = phoneUtil.format(phoneNumber, PNF.E164);
    //       setFields({ ...fields, phone: phone });
    //     }
    //   } catch(err) {
    //     formIsValid = false;
    //     errors['phone'] = 'El teléfono no es válido';
    //   }
    // }

    setErrors(errors);
    
    return formIsValid;
  }

  const validatePhone = (phone) => {
    let errors = {};
    phone = phone?.trim()?.replace(/ /g, '')

    // if(fields['phone']) {
    //   try {
    //     let phoneNumber = phoneUtil.parseAndKeepRawInput(phone, 'ES');
    //     if (!phoneUtil.isValidNumber(phoneNumber)) {
    //       setErrors({...errors, phone: 'El teléfono no es válido'});
    //     } else {
    //       let phone = phoneUtil.format(phoneNumber, PNF.E164);
    //       setFields({ ...fields, phone: phone });
    //       setErrors({...errors, phone: ''});
    //     }
    //   } catch(err) {
    //     console.log('err', err)
    //     // setErrors({...errors, phone: 'El teléfono no es válido'});
    //   }
    // }
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      sendCode();
    }
  }

  const sendCode = () => {
    setValue('');
    setLoading(true);

    axios.post('/api/account/send_code/', {...fields, phone: '+34' + fields?.phone.replace('+34', '')})
      .then(res => {
        if(res.status === 200) setShowInputVerificationCode(true);
        setLoading(false);
        setErrors({});
      })
      .catch(async err => {
        setLoading(false);
        console.log('SMS Error: ', err.response.data)
        let error_numbers = err.response.data?.error?.validation_errors?.numbers
        if(error_numbers) {
          for(let key in error_numbers) {
            await alert(error_numbers[key][0] === 'Number not valid' ? 'Número no válido' : error_numbers[key][0])
          }
        }
        else if(err.response.data.detail) {
          await alert(err.response.data.detail);
          setErrors({});
        } else {
          await alert('Ocurrió un error al intentar enviar el SMS. Por favor, inténtelo de nuevo más tarde.')
          let errors = {};
          for(let key in err.response.data) {
            errors[key] = err.response.data[key];
          }
          setErrors(errors)
        }
      })
  }

  const checkCode = (verification_code) => {
    setLoading(true);

    axios.post('/api/account/check_code/', {verification_code: verification_code})
      .then(res => {
        setLoading(false);
        if(res.status === 200) {
            setPhoneNotVerified(false);
            navigate('/')
        } 
      })
      .catch(async err => {
        await alert('El código no es válido o ha expirado.');
        setValue('');
        setLoading(false);
      })
  }

  return (
    <Fragment>
        <Container className="check-phone d-flex flex-column justify-content-center" style={styles.container}>
            {!showInputVerificationCode 
              ?
                !showInputNewNumber 
                ?
                    <p style={styles.text}>{data?.text_with_unverified_phone} <p style={styles.textBold}>{fields?.phone}</p></p>
                :
                <p style={styles.text}>{profile?.phone === null || profile?.phone === '' ? data?.text_without_phone : data?.text_change_phone_number}</p>
              : 
                <p style={styles.text}>{data?.text_verification_code}</p>
            }
            
            {!showInputVerificationCode
            ?
              <Fragment>
                {showInputNewNumber ?
                <div style={styles.row}>
                    <div style={styles.col}>
                        <FormGroup>
                            <Label>TELÉFONO</Label>
                            <InputGroup>
                              <InputGroupText>+34</InputGroupText>
                              <Input
                                type="number"
                                maxLength={100}
                                name="phone"
                                invalid={errors["phone"]}
                                value={fields?.phone?.replace('+34', '')}
                                onChange={(e) => {handleChange('phone', e.target.value?.trim()?.replace(/ /g, '').replace('+34', ''))}}
                                // onBlur={() => validatePhone()}
                              />
                            </InputGroup>
                            <FormFeedback>{errors["phone"]}</FormFeedback>
                        </FormGroup>
                    </div>
                </div>
                : null}
              
                <Button onClick={() => handleSubmit()}>{!loading ? 'ENVIAR' : 'ENVIANDO'} {loading ? <Spinner size="sm" /> : null }</Button>
              
                {!showInputNewNumber ?
                <div style={{marginTop: '20px'}} onClick={() => setShowInputNewNumber(true)}><p style={styles.link}>{data?.text_action_phone_change}</p></div>
                : null}
              </Fragment>
            : 
              <div style={{
                alignItems: 'center',
              }}>
                <div style={{flexDirection: 'row',}}>
                  <div className="d-flex justify-content-center" style={styles.col}>
                    <ReactCodeInput 
                        type='number' 
                        fields={4} 
                        value={value}
                        onChange={setValue} 
                    />
                  </div>
                </div>
                <div style={{marginTop: '20px'}} onClick={() => {setShowInputVerificationCode(false); setShowInputNewNumber(true);}}><p style={styles.link}>{data?.code_resend_action_text}</p></div>
              </div>
            } 
        </Container>

        {/* {loading && showInputVerificationCode ? <Loading /> : null} */}
    </Fragment>
  );
}

const styles = { 
  bgTop: {
    width: '100%',
    height: '350px',
    position: 'absolute',
    top: '-150px',
    left: 0,
    zIndex: -1,
  },  
  container: {
    flex: 1,
    padding: '15px',
    backgroundColor: '#fff',
    height: '100%',
  },
  row: {
    flexDirection: 'row',
    marginLeft: '-6px',
    marginRight: '-6px'
  },
  col: {
    flex: 1,
    marginLeft: '6px',
    marginRight: '6px'
  },
  logoContent: {
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '40px'
  },  
  logo: {
    width: '150px',
    height: '40px',
    marginBottom: '10px'
  },
  text: {
    fontFamily: 'Poppins-Regular',
    textAlign: 'center',
    fontSize: '12px',
    color: '#1a1413',
    marginBottom: '20px',
  },
  textBold: {
    fontFamily: 'Poppins-Regular',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#1a1413',
    marginBottom: '20px',
  },
  link: {
    fontFamily: 'Poppins-Regular',
    textAlign: 'center',
    padding: '10px',
    fontSize: '14px',
    color: '#536080',
  },


  root: {flex: 1, padding: '20px'},
  title: {textAlign: 'center', fontSize: '30px'},
  codeFieldRoot: {marginTop: 20},
  cell: {
    width: '40px',
    height: '40px',
    lineHeight: '38px',
    fontSize: '24px',
    borderWidth: '1px',
    borderColor: '#00000050',
    textAlign: 'center',
    borderRadius: '10px',
  },
  focusCell: {
    borderColor: '#000',
  },
}
