import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Input, InputGroup, InputGroupText, FormFeedback, Spinner } from 'reactstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { loginWithInstagram, loginWithFacebook, encrypt, decrypt, setCookie, getCookie, deleteCookie } from '../utils'; 

import iconInstagram from '../assets/icons/icon-instagram.svg';
import iconFacebook from '../assets/icons/icon-facebook.svg';
import iconUser from '../assets/icons/icon-user.svg';
import iconPassword from '../assets/icons/icon-password.svg';
import iconEye2 from '../assets/icons/icon-eye-2.svg';
import iconEye from '../assets/icons/icon-eye.svg';

var CryptoJS = require("crypto-js");

export default function Login({ config, logout }) {
  const [fields, setFields] = useState({});
  const [fieldsRP, setFieldsRP] = useState({});
  const [errors, setErrors] = useState({});
  const [errorsRP, setErrorsRP] = useState({});
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false)
  const [modalLogin, setModalLogin] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    //username
    if (!fields['username']) {
      formIsValid = false;
      errors['username'] = 'Campo obligatorio.';
    }

    //password
    if (!fields['password']) {
      formIsValid = false;
      errors['password'] = 'Campo obligatorio.';
    }

    setErrors(errors);
    
    return formIsValid;
  }

  const handleValidationRecoverPassword = () => {
    let errors = {};
    let formIsValid = true;

    //email
    if (!fieldsRP['email']) {
      formIsValid = false;
      errors['email'] = 'Campo obligatorio.';
    }

    if (fieldsRP['email']) {
      let lastAtPos = fieldsRP['email'].lastIndexOf('@');
      let lastDotPos = fieldsRP['email'].lastIndexOf('.');

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fieldsRP['email'].indexOf('@@') == -1 &&
          lastDotPos > 2 &&
          fieldsRP['email'].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors['email'] = 'El correo no es válido';
      }
    }

    setErrorsRP(errors);
    
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      loginWithJWT();
    }
  }

  const handleSubmitRecoverPassword = (e) => {
    e.preventDefault();
    if (handleValidationRecoverPassword()) {
      recoverPassword();
    }
  }

  const toggleRememberMe = (checked) => {
    setRememberMe(checked)
    if(!checked) {
      deleteCookie('rememberme')
    }
  }

  const saveRememberMe = () => {
    const payload = JSON.stringify({username: fields.username, password: fields.password})
    var hash = encrypt(payload);
    setCookie('rememberme', hash, 360)
  }

  const loginWithJWT = () => {
    setLoading(true);
    axios.post('/api/auth/login/', fields)
      .then(res => {
        setLoading(false);
        setErrors({});
        
        if(rememberMe) saveRememberMe()

        afterLogin(res.data.token)
      })
      .catch(async err => {
        setLoading(false);
        if(err.response.data.detail) {
          await alert(err.response.data.detail);
          setErrors({});
        } else {
          let errors = {};
          for(let key in err.response.data) {
            errors[key] = err.response.data[key];
          }
          setErrors(errors)
        }
      })
  }

  const recoverPassword = () => {
    setLoading(true);
    axios.post('/api/auth/reset-password/', fieldsRP)
      .then(async res => {
        setLoading(false);
        setErrorsRP({});
        await alert('Le hemos enviado un correo con instrucciones para recuperar su contraseña.');
        setModalLogin(false);
      })
      .catch(async err => {
        setLoading(false);
        if(err.response.data.detail) {
          await alert(err.response.data.detail);
          setErrorsRP({});
        } else {
          let errors = {};
          for(let key in err.response.data) {
            errors[key] = err.response.data[key];
          }
          setErrorsRP(errors)
        }
      })
  }

  const loginSocial = (accessToken, provider, success, error) => {  
    axios.post('/api/auth/login/social/', {
      access_token: accessToken,
      provider: provider
    })
      .then(res => {
        if(res.data?.token) {
          success(res.data)
        }
      })
      .catch((err) => {
        error(err)
      })
  }

  // useEffect(() => {
  //   if(route.params?.registered) {
  //     setModalLoginVisible(true)
  //     setTimeout(() => {
  //       alert('Hemos enviado un enlace de activación de cuenta a su correo electrónico.');
  //     }, 1000)
  //   }
  // }, [searchParams])

  const validateAccount = (count, min) => {
    if(count < min) {
      setLoading(false);
      return `Debe tener al menos ${min} publicaciones de Instagram para registrarse.`;
    }
    return null
  }

  useEffect(() => {  
    document.body.className = 'login';

    let query = new URLSearchParams(location.search);
    let accessToken = query.get('code_ig');
    let accessTokenFB = query.get('code_fb');
    let username = query.get('username');
    let code = query.get('code');
    

    const cookie_rememberme =  getCookie('rememberme');
    if(cookie_rememberme !== '') {
      let data = JSON.parse(decrypt(cookie_rememberme));
      setFields({
        ...fields,
        username: data.username,
        password: data.password
      })
      setRememberMe(true);
    }


    axios.get('/api/cms/homescreen/')
      .then((res) => {
        setData(res.data.results[0])
      })
      .catch((err) => {
        console.log('err', err.response)
      })

    if(accessToken && username && localStorage.token && localStorage.token !== 'undefined') {
      navigate(`/profile-register/${accessToken}/${username}/`)
      return;
    }


    // limpia el localStorage
    logout();

    if(searchParams.get('registered') === 'true') {
      setModalLogin('login')
      setTimeout(async () => {
        await alert('Hemos enviado un enlace de activación de cuenta a su correo electrónico.');
      }, 1000)
    }
    
    console.clear()
    console.log('accessTokenFB', accessTokenFB)
    

    if(accessToken || accessTokenFB) {
      loginSocial(
        accessToken ? accessToken : accessTokenFB, 
        accessToken ? 'instagram' : 'facebook', 
        (data) => {
          afterLogin(data.token);
        },
        (error) => {
        
        }
      );
    }


    // getWebProfileInfo(username, 
    //   (data) => {
    //     let userId = data.data.user.id
    //     let accountError = validateAccount(data.data.user.edge_owner_to_timeline_media.count, config?.min_posts)
    //     if(accountError) {
    //       alert(accountError);
    //       return;
    //     }

    //     loginSocial(
    //       accessToken, 
    //       'instagram', 
    //       (data) => {
    //         let token = data.token;

    //         if(!data.is_new) {
    //           afterLogin(data.token);
    //         } else {
    //           console.log('userId', userId)
    //           createInstagramAccount(
    //             username,
    //             userId,
    //             token,
    //             (data) => {
    //               afterLogin(token);
    //             },
    //             (error) => {
    //               for (let key in error.response.data) {
    //                 if(error.response.data[key].toString() === 'Ya existe un/a user instagram con este/a iuser id.')
    //                   alert('Ya existe un usuario con esta cuenta de Instagram.');
    //                 else
    //                   console.log(error.response.data[key]);
    //               }
    //             }
    //           )
    //         }
    //       },
    //       (error) => {
          
    //       }
    //     );
    //   },
    //   (error) => {
    //     alert(error)
    //   }
    // )

    return () => { document.body.className = ''; }
  }, [])

  const afterLogin = (token) => {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    localStorage.setItem('token', token)
    if(localStorage.viewed_tutorial === 'true') navigate('/')
    else navigate('/tutorial')
  }

  return (
    <div className="login text-center">
      {/* <div className="login-background">
        <img src={data?.image} />
        <div className="login-background-gradient" />
      </div> */}
      <div 
        className="login-content h-100 d-flex flex-column" 
        style={{backgroundImage: `url("${data?.image}")`}}
      >
        <img src={config?.logo} className="login-logo" />
        <p>{data?.slogan}</p>

        <div className="login-container">
          <Card>
            <CardBody>
              <Button color="danger" block className="login-button login-button-instagram" onClick={() => loginWithInstagram()}>
                <div>
                  <img src={iconInstagram} className="login-icon-instagram" />
                </div>
                <span />
                Accede con Instagram
              </Button>
              <hr />
              {/* <FacebookLogin
                appId="305951608195605"
                autoLoad={false}
                callback={(response) => {
                  if(response?.accessToken) {
                    loginSocial(
                      response.accessToken, 
                      'facebook', 
                      (data) => {
                        afterLogin(data.token);
                      },
                      (error) => {
                      
                      }
                    );
                  }
                }}
                render={renderProps => (
                  <Button color="primary" className="login-button login-button-facebook"  block onClick={renderProps.onClick}>
                    <div>
                      <img src={iconFacebook} className="login-icon-facebook" />
                    </div>
                    <span />
                    Accede con Facebook
                  </Button>
                )}
              /> */}
              <Button color="primary" className="login-button login-button-facebook"  block onClick={() => loginWithFacebook()}>
                <div>
                  <img src={iconFacebook} className="login-icon-facebook" />
                </div>
                <span />
                Accede con Facebook
              </Button>
              <hr />
              <Button color="link" block className="login-button-email" onClick={() => setModalLogin('login')}>Accede con tu cuenta de SHAZU</Button>
            </CardBody>
          </Card>
        </div>

        <div className="login-background-bottom">
          <div className="login-bottom" onClick={() => setModalLogin('reset-password')}>
            <p className="m-0 login-bottom-text">Problemas para acceder?</p>
            <Button color="link" className="login-bottom-link"><b>Pulsa aqui</b></Button>
          </div>
        </div>
        <div className="login-background-bottom-bar" />

        {/* <img src={require('../assets/images/bg-home.png')} className="login-background-bottom" /> */}
      </div>
      
      <Modal className="login-modal" isOpen={modalLogin} toggle={() => setModalLogin(false)} backdrop='static' keyboard={false}>
        <ModalBody className="pb-3">
          <div className="modal-close" onClick={() => setModalLogin(false)}>
            <div className="modal-close-btn" />
          </div>
          <img src={config?.logo} className="modal-logo" />

          {modalLogin === 'login' ?
          <>
            <p className="modal-lead">ACCEDE A TU CUENTA</p>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <InputGroup className="input-group-1">
                  <InputGroupText>
                    <img src={iconUser} />
                  </InputGroupText>
                  <Input
                    className="form-control-1"
                    id="username"
                    name="username"
                    placeholder="Email"
                    type="text"
                    value={fields?.username}
                    invalid={errors?.username}
                    onChange={(e) => handleChange('username', e.target.value)}
                  />
                  <FormFeedback>{errors?.username}</FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-1">
                  <InputGroupText>
                    <img src={iconPassword} />
                  </InputGroupText>
                  <Input
                    className="form-control-1"
                    id="password"
                    name="password"
                    placeholder="Contraseña"
                    type={!showPassword ? 'password' : 'text' }
                    value={fields?.password}
                    invalid={errors?.password}
                    onChange={(e) => handleChange('password', e.target.value)}
                  />
                  <InputGroupText className="input-group-text-1-password" onClick={() => setShowPassword(!showPassword)}>
                    <img src={!showPassword ? iconEye : iconEye2} />
                  </InputGroupText>
                  <FormFeedback>{errors?.password}</FormFeedback>
                </InputGroup>
              </FormGroup>
              <div className="d-flex align-items-center mt-4">
                <div className="w-50">
                  <Button type="submit" color="danger" className="rounded-0 font-bebas-neue fw-light font-size-20 py-1" block disabled={loading}>{!loading ? 'ENTRAR' : 'AGUARDE...'} {loading ? <Spinner size="sm" /> : null}</Button>
                </div>
                <div className="ms-auto">
                  <div className="login-remember-me font-bebas-neue fw-light font-size-10">
                    <div className="login-remember-me-input d-flex align-items-center mb-2" onClick={() => toggleRememberMe(!rememberMe)}>
                      <div className={`login-remember-me-checkbox me-1 ${rememberMe ? 'checked' : ''}`}></div>
                      Recuerdame
                    </div>
                    <div className="login-remember-me-text" onClick={() => setModalLogin('reset-password')}>OLVIDASTE TU CONTRASEÑA?</div>
                  </div>
                </div>
              </div>
              <Button color="link" block className="login-bottom-text text-uppercase text-decoration-none font-size-14 mt-3" onClick={() => navigate(`/register/`)}>Crear cuenta</Button>
            </Form>
          </>
          : null}

          {modalLogin === 'reset-password' ?
          <>
            <p className="modal-lead text-uppercase">recupera tu contraseña</p>
            <Form onSubmit={handleSubmitRecoverPassword}>
              <FormGroup>
                <InputGroup className="input-group-1">
                  <InputGroupText>
                    <img src={iconUser} />
                  </InputGroupText>
                  <Input
                    className="form-control-1"
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="text"
                    value={fieldsRP?.email}
                    invalid={errorsRP?.email}
                    onChange={(e) => setFieldsRP({email: e.target.value})}
                  />
                  <FormFeedback>{errorsRP?.email}</FormFeedback>
                </InputGroup>
              </FormGroup>
              <div className="d-flex align-items-center mt-4">
                <div className="w-100">
                  <Button type="submit" color="danger" className="rounded-0 font-bebas-neue fw-light font-size-20 py-1" block disabled={loading}>{!loading ? 'ENVIAR' : 'ENVIADO...'} {loading ? <Spinner size="sm" /> : null}</Button>
                </div>
              </div>
            </Form>
          </>
          : null}
        </ModalBody>
      </Modal>
      
    </div>
  );
}
