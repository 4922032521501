import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import axios from 'axios';

const PayButton = ({ cartItems }) => {
    const [loading, setLoading] = useState(false);

    const checkout = () => {
        setLoading(true);
        axios.post('/api/checkout/create_checkout_session/')
            .then((res) => {
                setLoading(false);
                window.location.href = res.data
            })
            .catch(() => {
                setLoading(false);
            })
    }

    return (
        <Button className="text-uppercase mt-4 font-size-16" color="danger" block disabled={!cartItems || cartItems.length === 0} onClick={() => checkout()}>{!loading ? 'Pagar' : 'Aguarde'} {loading ? <Spinner size="sm" /> : null}</Button>
    )
}

export default PayButton;