import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { TourProvider } from '@reactour/tour'
import Application from './layouts/Application';
import Login from './containers/Login';
import Register from './containers/Register';
import ChangePassword from './containers/ChangePassword';
import Home from './containers/Home';
import Recharge from './containers/Recharge';
import Balance from './containers/Balance';
import Promote from './containers/Promote';
import PromoteHistory from './containers/PromoteHistory';
import Follow from './containers/Follow';
import Like from './containers/Like';
import Video from './containers/Video';
import ShareAndWin from './containers/ShareAndWin';
import FrequentQuestions from './containers/FrequentQuestions';
import Notifications from './containers/Notifications';
import Notification from './containers/Notification';
import Karma from './containers/Karma';
import CheckEmail from './containers/CheckEmail';
import CheckPhone from './containers/CheckPhone';
import Tutorial from './containers/Tutorial';
import Page from './containers/Page';
import axios from 'axios';

import 'react-toastify/dist/ReactToastify.css';

import './styles/styles-mobile.scss'
// import './styles/styles-desktop.scss'

import { kdf } from 'crypto-js';

// if(document.body.clientWidth >= 992) {
//   require('./styles/styles-desktop.scss');
// } else {
//   require('./styles/styles-mobile.scss');
// }

var clientWidth = document.body.clientWidth

window.alertResolve = null;

window.closeAlert = () => {
  let alert = document.getElementById('alert')
  if(alert) alert.remove()
  window.alertResolve()
}


window.alert = async (text='') => {
  return new Promise((resolve, reject) => {
    if(document.getElementById('alert')) {
      resolve()
    } else {
      window.alertResolve = resolve
      setTimeout(() => {
        let div = document.createElement('div');
        div.id = 'alert';
        div.classList.add('custom-alert')
        div.innerHTML = `
          <div class="custom-alert-content p-4">
            <div class="h-100">
              <div class="custom-alert-close" onclick="window.closeAlert()">
                <i class="material-icons">close</i>
              </div>
              <div class="font-size-30 fw-medium text-uppercase mb-2" style="color: #c6c8cb">AVISO</div>
              <div class="font-size-16 fw-medium mb-2" style="color: #f9f9f9">${text}</div>
            </div>
            <div class="custom-alert-bottom pb-4 px-4">
              <div id="custom-alert-close" role="button" class="font-size-14 fw-normal mt bg-transparent border-0 text-white" style={{color: '#f9f9f9'}} onclick="window.closeAlert()">ENTENDIDO</div>
            </div>
          </div>
        `
        if(!document.getElementById('alert')) {
          document.body.appendChild(div);
          setTimeout(() => {
            document.getElementById('custom-alert-close').focus();
          }, 200)
        }
      });
    }
  });
}

const App = () => {
  const [config, setConfig] = useState(null);
  const [profile, setProfile] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [isOpenMiniCart, setIsOpenMiniCart] = useState(false)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [accounts, setAccounts] = useState(null)
  const [phoneNotVerified, setPhoneNotVerified] = useState(false)
  const [emailNotVerified, setEmailNotVerified] = useState(false)
  const [incompleteProfile, setIncompleteProfile] = useState(false)
  const [notifications, setNotifications] = useState(0)
  const [viewedTutorial, setViewedTutorial] = useState(false);
  const [isOpenTour, setIsOpenTour] = useState(false);
  const [tourSteps, setTourSteps] = useState([]);
  const [pageTitle, setPageTitle] = useState(null);
  const [checkedProfile, setCheckedProfile] = useState(false);

  const [postsAllAccounts, setPostsAllAccounts] = useState(null);
  const [posts, setPosts] = useState(null);
  const [videosAllAccounts, setVideosAllAccounts] = useState(null);
  const [videos, setVideos] = useState(null);

  const navigate = useNavigate()

  const defaultAccount = accounts?.find((account) => account.is_default === true)

  const getProfile = (callback=null) => {
    axios.get('/api/auth/profile/')
      .then((res) => {
        setProfile(res.data);
        if(callback) callback(res.data)
      })
  }

  const getCartItems = (callback) => {
    axios.get('/api/checkout/cart/')
        .then((res) => {
            setCartItems(res.data)
            if(callback) callback(res.data);
        })
  }

  const toggleMiniCart = () => {
    setIsOpenMiniCart(!isOpenMiniCart)
  }

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu)
  }

  const getConfig = () => {
    axios.get('/api/config/global/')
      .then((res) => {
        setConfig(res.data.results[0])
      })
  }

  const logout = (redirect=true) => {
    delete axios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token')
    setIsOpenMenu(false)
    if(redirect) navigate('/login')
  }

  const getAccounts = (callback=null) => {
    axios.get('/api/account/instagram/')
      .then(async (res) => {
        setAccounts(res.data)    
        if(callback) callback(res.data)   
      })
  }

  const checkProfile = async () => {
    let _emailNotVerified = profile.verification_code_email !== null && profile.verification_code_email !== ''
    let _phoneNotVerified = !profile.phone || (profile.phone && (profile.phone_verified === null || profile.phone_verified === '' || profile.phone !== profile.phone_verified))
    
    let has_alert = false;
    
    if(window.location.search.includes('?messageError')) {
      has_alert = true
      await alert(decodeURIComponent(window.location.search.split('?messageError=')[1]));
    }
    
    if(_emailNotVerified) {
      setEmailNotVerified(_emailNotVerified)
      navigate('/check-email')
    } 
    else if(_phoneNotVerified) {
      setPhoneNotVerified(_phoneNotVerified)
      navigate('/check-phone')
    }
    else if(accounts?.length === 0) {
      if(!window.alertActivateAccount) {
        window.alertActivateAccount = true;
        let msg = 'Para continuar debes activar al menos un perfil de Instagram'
        await alert(msg);
        setIncompleteProfile(msg);
        setTimeout(() => {
          delete window.alertActivateAccount;
        }, 200)
        if(!has_alert) navigate('/profile/#missing_session')
      }
    }
    else if(
      (profile.first_name === null || profile.first_name === '') ||
      (profile.last_name === null || profile.last_name === '') ||
      (profile.email === null || profile.email === '') ||
      (profile.date_of_birth === null || profile.date_of_birth === '') ||
      (profile.gender === null || profile.gender === '') ||
      (profile.province === null || profile.province === '') ||
      (profile.city === null || profile.city === '') ||
      (profile.likes_and_interests.length === 0)
      // (accounts?.length === 0)
    ) {
      // if(accounts?.length === 0) {
      //   setIncompleteProfile('Para continuar debes activar al menos un perfil de Instagram');
      // }
      // else {
      //   setIncompleteProfile('Completa tu perfil para continuar');
      // }

      setIncompleteProfile('Completa tu perfil para continuar');

      setCheckedProfile(true)
      navigate('/profile/' + window.location.search)
    } else {
      setIncompleteProfile(false);
      setTimeout(() => {
        setIsOpenTour(true) //start tour
      }, 200)
    }
  }

  const setDefault = (key) => {
    axios.post(`/api/account/instagram/${accounts[key].id}/set_default/`)
      .then((res) => {
        let _accounts = [...accounts]
        _accounts?.forEach((account, _key) => {
          _accounts[_key].is_default = _key === key ? true : false
        })
        setAccounts(_accounts)
      })
  }

  const getNotifications = () => {
    axios.get(`/api/notifications/count/`)
      .then(res => {
        setNotifications(res.data.count)
      })
  }

  useEffect(() => {    
    intercept();
    
    getConfig();
    getProfile();
    getCartItems();
    getNotifications();

  
    // window.addEventListener('resize', function(event) {
    //   if((document.body.clientWidth >= 992 && clientWidth < 992) || (document.body.clientWidth < 992 && clientWidth >= 992)) {
    //     window.location.reload()
    //   }
    // }, true);

    // let worker = new Worker("/notification_worker.js");
    // Notification.requestPermission();
  }, [])

  const intercept = () => {
    axios.interceptors.request.use(function (config) {
      // Do something before request is sent
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
    
    axios.interceptors.response.use(async function (response) {
      // Do something with response data
      if(response.data?.message === 'challenge_required') {
        let account = accounts?.find((account) => account.is_default === true)
        await alert(`Instagram te pide que hagas un desafío con la cuenta. \nSe requiere la resolución del desafío para continuar usando Shazu con la cuenta.`)
        window.location.href = 'https://i.instagram.com' + response.data.challenge.api_path + '?next=' + window.location.href
      } else if(response.data?.require_login && response.data?.status === 'fail') {
        await alert('Has sido desconectado de Instagram. \nSincroniza tu cuenta de nuevo.')
        navigate('/profile/#missing_session')
      }
      return response;
    }, async function (error) {
      // Do something with response error
      if(error?.response.data?.message_type === 'missing_session') {
        await alert(error?.response.data?.detail)
        navigate('/profile#' + error?.response.data?.message_type)
      } else if(error?.response.data?.message_type) {
        await alert(error?.response.data?.detail)
      }
      return Promise.reject(error);
    });
  }

  useEffect(() => {
    if(profile && accounts) checkProfile();
  }, [profile, accounts])

  return (
    <React.Fragment>
      <TourProvider 
        steps={tourSteps}
        styles={{
          popover: (base) => ({
            ...base,
            '--reactour-accent': '#ffffff',
          }),
          maskArea: (base) => ({ ...base }),
          badge: (base) => ({ ...base }),
          controls: (base) => ({ ...base, color: '#fff' }),
          close: (base) => ({ ...base, color: '#000' }),
        }}
      >
        <Routes>
          <Route path="/login" element={<Login config={config} logout={logout} />} />
          <Route path="/register" element={<Register isRegisterPage logout={logout} />} />
          <Route path="/" element={<Application config={config} pageTitle={pageTitle} defaultAccount={defaultAccount} isOpenTour={isOpenTour} setTourSteps={setTourSteps} hideHeader={emailNotVerified || phoneNotVerified} hideHeaderButtons={viewedTutorial} hideFooter={emailNotVerified || phoneNotVerified || viewedTutorial} logout={logout} profile={profile} incompleteProfile={incompleteProfile} cartItems={cartItems} isOpenMiniCart={isOpenMiniCart} toggleMiniCart={toggleMiniCart} getCartItems={getCartItems} getAccounts={getAccounts} isOpenMenu={isOpenMenu} toggleMenu={toggleMenu} notifications={notifications} />}>
            <Route index element={<Home getProfile={getProfile} cartItems={cartItems} getCartItems={getCartItems} toggleMiniCart={toggleMiniCart} />} />
            <Route path="/check-email" element={<CheckEmail profile={profile} emailNotVerified={emailNotVerified} setEmailNotVerified={setEmailNotVerified} />} />
            <Route path="/check-phone" element={<CheckPhone profile={profile} phoneNotVerified={phoneNotVerified} setPhoneNotVerified={setPhoneNotVerified} />} />
            <Route path="/tutorial" element={<Tutorial config={config} viewedTutorial={viewedTutorial} setViewedTutorial={setViewedTutorial} />} />
            <Route path="/profile" element={<Register checkedProfile={checkedProfile} isOpenTour={isOpenTour} logout={logout} getProfile={getProfile} profile={profile} accounts={accounts} defaultAccount={defaultAccount} setAccounts={setAccounts} getAccounts={getAccounts} incompleteProfile={incompleteProfile} setDefault={setDefault} />} />
            <Route path="/profile-register/:code/:username/" element={<Register logout={logout} getProfile={getProfile} profile={profile} accounts={accounts} setAccounts={setAccounts} getAccounts={getAccounts} />} />
            <Route path="/change-password" element={<ChangePassword profile={profile} logout={logout} />} />
            <Route path="/recharge/" element={<Recharge cartItems={cartItems} getCartItems={getCartItems} toggleMiniCart={toggleMiniCart} />} />
            <Route path="/balance" element={<Balance profile={profile} getProfile={getProfile} />} />
            <Route path="/promote/" element={<Promote posts={posts} setPosts={setPosts} postsAllAccounts={postsAllAccounts} setPostsAllAccounts={setPostsAllAccounts} videos={videos} setVideos={setVideos} videosAllAccounts={videosAllAccounts} setVideosAllAccounts={setVideosAllAccounts} getProfile={getProfile} profile={profile} config={config} accounts={accounts} setDefault={setDefault} />} />
            <Route path="/promote/history" element={<PromoteHistory profile={profile} getProfile={getProfile} />} />
            <Route path="/follow" element={<Follow defaultAccount={defaultAccount} getProfile={getProfile} />} />
            <Route path="/like" element={<Like defaultAccount={defaultAccount} getProfile={getProfile} />} />
            <Route path="/video" element={<Video defaultAccount={defaultAccount} getProfile={getProfile} />} />
            <Route path="/frequent-questions" element={<FrequentQuestions />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/notifications/:id" element={<Notification getNotifications={getNotifications} />} />
            <Route path="/shareandwin" element={<ShareAndWin profile={profile} />} />
            <Route path="/karma/" element={<Karma accounts={accounts} getProfile={getProfile} setDefault={setDefault} />} />
            <Route path="/page/:slug" element={<Page setPageTitle={setPageTitle} />} />
            <Route path="*" element={<h1>Page Not Found 404</h1>}/>
          </Route>
        </Routes>
      </TourProvider>
      <ToastContainer />
    </React.Fragment>
  )
}

export default App;