import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Container } from 'reactstrap';
import axios from 'axios';

const Page = ({ setPageTitle }) => {
    const [page, setPage] = useState(null);

    const params = useParams();

    useEffect(() => {
        axios.get('/api/cms/page/?slug=' + params.slug)
            .then((res) => {
                let data = res.data?.title ? res.data : res.data[0]
                setPage(data)
                setPageTitle(data.title)
            })
        return () => { setPageTitle(null); }
    }, [])

    return (
        <div className="page py-3">
            <div className="page-container">
                <div dangerouslySetInnerHTML={{__html: page?.content.replace(/\r\n/g, '<br/>')}} />
            </div>
        </div>
    )
}

export default Page;