import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Badge, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import axios from 'axios';
import { useTour } from '@reactour/tour';
import Skeleton from 'react-loading-skeleton';
import ViewSelector from '../components/ViewSelector';
import CardFlick from '../components/CardFlick';
import { viewImage } from '../utils';

import iconVideo from '../assets/icons/icon-video.svg';
import iconHeart from '../assets/icons/icon-heart.svg';
import iconChat from '../assets/icons/icon-chat.svg';
import iconMagnets from '../assets/icons/icon-circle-navbar.svg';
import iconPremium from '../assets/icons/icon-premium.svg';

var CryptoJS = require("crypto-js");

var profileToAction = null;

const colors = [
    '#36a9e1', // blue
    '#f39200', // yellow
    '#e71d73', // pink
    '#2fac66', // green
    '#ca9e67', // brown
    '#a3195b', // purple
]

const steps = [
    {
        selector: '.tour-step-like-1',
        content: <p className="font-size-10">Estás a punto de ser dirigid@ a la publicación de instagram seleccionada para que puedas dar like, a cambio recibirás los imanes acordados, pero si en algún momento retiras el like a los usuarios de SHAZU que te compensaron por ello, serás penalizad@ y tu cuenta podría ser eliminada de la comunidad. <br/><br/> Para detectar este tipo de acciones, realizamos rastreos continuos de cada acción asegurandonos de que secumplan nuestras normas comunitarias.</p>,
    }
]

var __orders = []

const Like = ({ getProfile, defaultAccount }) => {
    const [view, setView] = useState('grid');
    const [intialLoad, setIntialLoad] = useState(true);
    const [orders, setOrders] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [page, setPage] = useState(1)
    const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
    const [modal, setModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [status, setStatus] = useState('');
    const [showButton, setShowButton] = useState(null);
    const [checking, setChecking] = useState(false);
    const [checkingFail, setCheckingFail] = useState(false);

    const { setIsOpen, setSteps, setCurrentStep } = useTour()

    const _setOrders = (_orders) => {
        setOrders(_orders);
        __orders = _orders;
    }

    const getOrders = (pagination) => {
        setIsFetchingNextPage(true);
        axios.get(`/api/orders/items/?page=${pagination}&objective=likes`)
            .then(res => {
                setIntialLoad(false);
                if(pagination > 1) _setOrders(orders.concat(res.data.results));
                else _setOrders(res.data.results);

                setHasNextPage(res.data.next);
                setIsFetchingNextPage(false);
            })
            .catch(err => {
                setHasNextPage(false);
                setIsFetchingNextPage(false);
                console.log(err);
            })
    } 

    const goToPost = async (item) => {
        setModal(true)
        setLoadingModal(true)
        setStatus(`Verificando el post de @${item.iusername}...`)
        setShowButton(null)
        setCheckingFail(false)

        try {
            let checkDailyPermission = await axios.get('/api/orders/check_daily_permission')
            if(!checkDailyPermission.data.likes) {
                setModal(false)
                setLoadingModal(false)
                setStatus('')
                setShowButton(null)
                setCheckingFail(false)      
                await alert('Ha alcanzado tu límite diario de dar likes.')      
                return;
            }
        } catch(err) {
            await alert('Hubo un error al verificar su límite diario.')
            return;
        }

        axios.get('/api/instagram/media/?media_id=' + item.media_id, {
            withCredentials: true
        })
            .then((res) => {
                setLoadingModal(false)
                if(res.data.items[0].has_liked) {
                    setStatus(`Ya diste like en este post con el usuario @${defaultAccount.iusername}`)
                    removeFromFeed(item.id)
                    setTimeout(() => {
                        setModal(false)
                        setStatus('')
                    }, 2000)
                } else {
                    setStatus(`Serás redirigido a Instagram para dar like en el post.<br /> Después de realizar la acción, debes regresar a Shazu para recibir tus imanes.`)
                    setTimeout(() => {
                        // var a = document.createElement('a');
                        // a.target="_blank";
                        // window.location.href= navigator.userAgent.match(/iPhone|iPad|iPod/i) ? `instagram://media?id=${item.media_id}` : `https://www.instagram.com/p/${item.shortcode}/`;
                        // a.click();


                        if(navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                            window.location.href = `instagram://media?id=${item.media_id}`
                        }
                        else {
                            var a = document.createElement('a');
                            a.href = `https://www.instagram.com/p/${item.shortcode}/`
                            a.target="_blank";
                            a.click()
                        }

                        profileToAction = item;
                        setShowButton(item)
                    }, 2000)
                }
            })
            .catch((err) => {
                setModal(false)
                setLoadingModal(false)
                setStatus('')
                setShowButton(null)
                setCheckingFail(false)
            })

        // let a = document.createElement('a');
        // a.target="_blank";
        // a.href=`https://www.instagram.com/${item.shortcode}/`;
        // a.click();
    }

    const like = (item, hash) => {
        let _orders = __orders.filter(order => order.id !== item.id);
        console.log('_orders', _orders)
        axios.post(`/api/orders/perform_action/?hash=${hash}`)
            .then(res => {
                setStatus(`Hemos añadido <b>+${item.magnets}</b> imanes a tu cuenta de Shazu`)
                setShowButton(null)
                getProfile()
                profileToAction = null;
                console.log('_orders 2', _orders)
                setTimeout(() => {
                    console.log('_orders 3', _orders)
                    _setOrders(_orders);
                    setModal(false)
                    setStatus('')
                    setChecking(false)
                    setCheckingFail(false)
                }, 3000)
            })
            .catch(async (err) => {
                if(err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
                    _setOrders(_orders);
                }
                await alert(err.response.data.detail);

                setModal(false)
                setStatus(false)
                setChecking(false)
                setCheckingFail(false)
            })
    }

    const check = (item) => {
        setChecking(true)
        axios.get('/api/instagram/media/?media_id=' + item.media_id, {
            withCredentials: true
        })
            .then((res) => {
                setChecking(false)
                if(res.data.items[0].has_liked) {
                    const payload = JSON.stringify({id: item.id})
                    var derived_key = CryptoJS.enc.Base64.parse("LefjQ2pEXmiy/nNZvEJ43i8hJuaAnzbA1Cbn1hOuAgA=")
                    var iv = CryptoJS.enc.Utf8.parse("1020304050607080");
                    var hash = CryptoJS.AES.encrypt(payload, derived_key, {iv: iv, mode: CryptoJS.mode.CBC}).toString();
                    
                    like(item, encodeURIComponent(hash))

                    // setStatus(`Ya hemos añadido tu ${item.magnets} a tu cuenta de Shazu`)
                    // removeFromFeed(item.id)
                    // setTimeout(() => {
                    //     setModal(false)
                    //     setStatus('')
                    // }, 2000)
                } else {
                    setStatus(`No diste like en el post con el usuario @${defaultAccount.iusername}.`)
                    setCheckingFail(true)
                }
            })
    }


    const removeFromFeed = (id) => {
        axios.post(`/api/orders/remove_from_feed/?item=${id}`)
            .then(res => {
                let _orders = orders.filter(order => order.id !== id);
                _setOrders(_orders);
            })
            .catch(async (err) => {
                await alert(err.response.data.detail);
            })
    }

    const getNextPage = () => {
        if(hasNextPage) {
            let _page = page + 1;
            setPage(_page);
            getOrders(_page);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if(orders.length > 0 && localStorage.viewed_tour_3 !== 'true') {
                localStorage.viewed_tour_3 = 'true'
                setIsOpen(true)
                setSteps(steps)
                setCurrentStep(0)
            }
        }, 200)
    }, [orders])

    useEffect(() => {
        window.addEventListener('focus', () => {
            if(profileToAction) {
                check(profileToAction)
            }
        })
        getOrders(1)
    }, [])

    return (
        <>
            <Container className={`like my-3 px-0 mx-auto ${orders.length === 0 ? 'h-100' : ''}`}>
                {orders.length ? <ViewSelector types={['grid', 'flick']} active={view} onClick={setView} /> : null}
                <Row className="like-row my-2">
                    {view === 'grid' && orders.map((item, key) => {
                        return (
                            <Col className={`like-col ${view === 'grid' ? 'like-col-grid' : 'like-col-list'}`} key={key} xs={6}>
                                <Card className={`card-post text-white mb-4 pb-1 ${item.is_premium ? 'is-premium' : ''}`} style={{backgroundColor: colors[key % 6]}} onClick={() => goToPost(item)}>
                                    <CardBody>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                                <img src={iconVideo} className="card-post-icon" />
                                                <div className="like-div bg-white mx-1" />
                                                <div className="d-flex align-items-center">
                                                    <img src={iconHeart} className="like-icon"/>
                                                    <span className="fw-bold font-size-8">&nbsp;{item?.like_count}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center ms-auto">
                                                <div className="d-flex align-items-center">
                                                    <img src={iconChat} className="like-icon" />
                                                    <span className="fw-bold font-size-8">&nbsp;{item?.comment_count}</span>
                                                </div>
                                                <div className="like-div bg-white mx-1" />
                                                <Badge color="white" className="card-post-magnets ms-auto font-bebas-neue fw-bold text-dark-2 font-size-14">+{item?.magnets}<img src={iconMagnets} /></Badge>
                                            </div>
                                        </div>
                                        <div className="mt-2 position-relative">
                                            <img src={process.env.REACT_APP_API_URL + item?.thumbnail} className="card-post-image border border-2 border-white rounded w-100 d-block" />
                                            <Badge color="white" className={`card-post-username font-bebas-neue text-secondary position-absolute font-size-8`} style={{bottom: 0, right: 0}}>@{item.iusername}</Badge>
                                            {item?.is_premium ? <img src={iconPremium} className="card-post-premium" /> : null}
                                        </div>
                                        <div className={`text-center font-size-22 fw-medium mt-1 ${key === 0 ? 'tour-step-like-1' : ''}`}>DAR LIKE</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}

                    {intialLoad ? 
                        <>
                            <Col className='like-col like-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='like-col like-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='like-col like-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='like-col like-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='like-col like-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='like-col like-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                        </>
                    : null}
                </Row>

                {view === 'flick' && orders.length > 0 ? <div className='like-flick-height'><CardFlick items={orders} colors={colors} goToPost={goToPost} /></div> : null}

                {/* {intialLoad ? 
                <div className="d-flex flex-column justify-content-center align-items-center text-center h-100">
                    <p className="font-size-16 mb-0">
                        Cargando... <Spinner size="sm" />
                    </p>
                </div>
                : null} */}

                {!intialLoad && orders.length === 0 ?
                <div className="d-flex flex-column justify-content-center align-items-center text-center h-100">
                    <p className="font-size-12 mb-0">
                        En esto momento no hay publicaciones disponibles.<br/>
                        Ten un poco de paciencia, pronto llegarán.
                    </p>
                    <Button color="danger" className="text-uppercase font-size-14 mt-3 px-4" onClick={() => getOrders(1)}>{isFetchingNextPage ? 'Actualizando...' : 'Actualizar'} {isFetchingNextPage ? <Spinner size="sm" /> : null}</Button>
                </div>
                : null}

                {hasNextPage ?
                        <div className="text-center my-3">
                            <Button color="danger" size="sm" onClick={() => getNextPage()}>VER MÁS</Button>
                        </div>
                : null}

                <Modal isOpen={modal} toggle={() => setModal(false)} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={() => setModal(false)}>Shazu</ModalHeader>
                    <ModalBody className="d-flex justify-content-center align-items-center text-center">
                        <div>
                            <p className="m-0" dangerouslySetInnerHTML={{ __html: status }} ></p>
                            {loadingModal ? <Spinner /> : null}
                            {showButton ? <Button color="danger" className="text-uppercase font-size-14 mt-3 px-4" onClick={() => check(showButton)}>{!checking ? (!checkingFail ? 'Ya di like en el post' : 'Revisar otra vez') : 'Comprobando...'} {checking ? <Spinner size="sm" /> : null}</Button> : null}
                        </div>
                    </ModalBody>
                </Modal>
            </Container>
        </>

    )
}

export default Like;