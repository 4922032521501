import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, Row, Col, Badge, Button, Spinner } from 'reactstrap';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import PayButton from '../components/PayButton';

import iconMagnet from '../assets/icons/icon-magnet.svg';

const Purchase = ({ cartItems, getCartItems, toggleMiniCart }) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    const getProducts = () => {
        setLoading(true)
        axios.get('/api/products/')
          .then((res) => {
            setLoading(false)
            setProducts(res.data)
          })
          .catch(() => {
            setLoading(false)
          })
    }

    const addToCard = (id) => {
        getCartItems((items) => {
            let itemExist = items.find(item => parseInt(item.product) === parseInt(id));

            axios[!itemExist ? 'post' : 'patch'](`/api/checkout/cart/${itemExist ? itemExist.id+'/' : ''}`, {
                product: id,
                quantity: !itemExist ? 1 : itemExist.quantity + 1
            })
                .then((res) => {
                    getCartItems();
                    toggleMiniCart();
                    // initializePaymentSheet();
                })
        });
    }

    useEffect(() => {
        getProducts()
    }, [])

    return (
        <div className="recharge pt-3 pb-4">
            <div className="recharge-container">
                <div className="text-dark-2 font-bebas-nueu fw-light font-size-16 text-uppercase text-center mt-1 mb-2">{!loading ? 'Selecciona tu paquete' : <Skeleton />}</div>
                {products.map((product, key) => {
                    return (
                        <Card key={key} className="mb-2 border-1 rounded-0 p-1">
                            <CardBody className='p-0'>
                                <div className="d-flex">
                                    <div className="recharge-image">
                                        <img src={product.image} />
                                    </div>
                                    <div className="recharge-content ps-1">
                                        <div className="p-1 text-center" style={{backgroundColor: '#e6e7e9'}}>
                                            <Badge className="bg-white text-dark-4 font-bebas-neue fw-bold font-size-16 w-100 rounded-0">{product.name}</Badge>
                                            <p className="mt-1 mb-0 font-bebas-neue fw-light text-dark-2 font-size-26">{product.price}€ x {product.magnets} <img src={iconMagnet} /></p>
                                            <Button className="text-uppercase font-size-14" color="danger" size="sm" onClick={() => addToCard(product.id)} >Comprar</Button>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    )
                })}

                {loading ? 
                    <>
                        <Skeleton className='recharge-skeleton' />
                        <Skeleton className='recharge-skeleton' />
                        <Skeleton className='recharge-skeleton' />
                    </>
                : null}

                {products.length ? <PayButton cartItems={cartItems} /> : null}
            </div>
        </div>
    )
}

export default Purchase;