import React from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { viewImage } from '../utils';

import avatar from '../assets/images/avatar.png';
import iconArrowDown from '../assets/icons/icon-arrow-down.svg';

const SelectProfile = ({ accounts, setDefault }) => {
    const navigate = useNavigate();
        
    return (
        <div>
            <h6 className="text-danger-2 font-bebas-neue fw-bold font-size-18 m-0">SELECCIONA PERFIL A PROMOCIONAR</h6>
            <div className="register-profiles d-flex">
                {Array(3).fill().map((value, key) => {
                return (
                accounts && accounts[key] ?
                  // <Col className="d-flex align-items-center" xs="4">
                  //   <Card className={`text-center border-0 text-white ${accounts && accounts[key]?.is_default ? 'bg-danger' : 'bg-secondary bg-opacity-25'} h-100`}>
                  //     <CardBody onClick={() => accounts && accounts[key] ? setDefault(key) :null}>
                  //       <small>{accounts && accounts[key] ? `Perfil ${key+1}` : 'Agregar'} </small>
                  //       <div>
                  //         <img src={accounts && accounts[key] ? accounts[key]?.iprofile_pic : 'https://gcavocats.ca/wp-content/uploads/2018/09/man-avatar-icon-flat-vector-19152370-1.jpg'} className="border border-white border-5" style={{width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '50%'}} />
                  //       </div>
                  //       <small>{accounts && accounts[key] ? accounts[key].iusername : 'Nuevo Usuario'}</small>
                  //     </CardBody>
                  //   </Card>
                  // </Col>
                  <div className="register-profile d-flex align-items-center" style={{width: '33.33%', minWidth: '33.33%'}}>
                    <Card className={`w-100 text-center border-0 text-white p-2 ${accounts && accounts[key]?.is_default ? 'bg-danger-gradient' : 'bg-gray-1'} h-100`}>
                      <div className="d-flex align-items-center">
                        <div className="font-metropolis font-size-10 text-center w-100">Perfil {key+1}</div>
                      </div>
                      <CardBody className='pt-2 p-0 d-flex flex-column align-items-center' onClick={() => accounts && accounts[key] ? setDefault(key) :null}>
                        <div className="position-relative">
                          <img src={accounts && accounts[key] ? accounts[key]?.iprofile_pic : avatar} className="select-profile-photo border border-white border-6 d-block" />
                          {!accounts[key]?.iprofile_pic ? <div className="register-plus"></div> : null}
                        </div>
                        <div className="font-metropolis font-size-10 text-center w-100 mt-2 mb-2">{accounts && accounts[key] ? accounts[key].iusername : 'Nuevo Usuario'}</div>
                      </CardBody>
                    </Card>
                    {accounts && accounts[key]?.is_default ? <img src={iconArrowDown} className="register-profile-arrow" /> : null}
                  </div>
                : null
                )
              })}
            </div>
        </div>
    )
}

export default SelectProfile;