import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, FormFeedback, FormGroup, Input, Button, Spinner, Label } from 'reactstrap';

import ReactCodeInput from 'react-code-input';

export default function CheckEmail({ emailNotVerified, setEmailNotVerified, profile }) {
  const [fields, setFields] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [showInputNewNumber, setShowInputNewNumber] = React.useState(false);
  const [showInputVerificationCode, setShowInputVerificationCode] = React.useState(false);
  const [data, setData] = React.useState(null);

  const [value, setValue] = useState('');

  const navigate = useNavigate();


  useEffect(() => {
    if(!emailNotVerified) navigate('/')

    axios.get('/api/cms/emailverification/')
      .then((res) => {
        setData(res.data.results[0])
      })

    handleChange('email', profile?.email)
    setFields({...profile, email: profile?.email})
    if(profile?.email === null || profile?.email === '') {
        setShowInputNewNumber(true)
    }
  }, [])

  useEffect(() => {
    if(value.length === 4) {
      checkCode(value);
    }
  }, [value])

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!fields['email']) {
        formIsValid = false;
        errors['email'] = 'Campo obligatorio.';
    }
  
    if (typeof fields['email'] !== 'undefined') {
        let lastAtPos = fields['email'].lastIndexOf('@');
        let lastDotPos = fields['email'].lastIndexOf('.');
  
        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields['email'].indexOf('@@') == -1 &&
            lastDotPos > 2 &&
            fields['email'].length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          errors['email'] = 'El correo no es válido';
        }
      }

    setErrors(errors);
    
    return formIsValid;
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      sendCode();
    }
  }

  const sendCode = () => {
    setValue('');
    setLoading(true);
    axios.put('/api/auth/profile/', {...fields, sendemail: true})
      .then(res => {
        if(res.status === 200) setShowInputVerificationCode(false);
        setLoading(false);
        setErrors({});
      })
      .catch(async err => {
        setLoading(false);
        if(err.response.data.detail) {
          await alert(err.response.data.detail);
          setErrors({});
        } else {
          let errors = {};
          for(let key in err.response.data) {
            errors[key] = err.response.data[key];
          }
          setErrors(errors)
        }
      })
  }

  const checkCode = (verification_code) => {
    setLoading(true);

    axios.post('/api/account/check_code_email/', {verification_code: verification_code})
      .then(res => {
        setLoading(false);
        if(res.status === 200) {
            setEmailNotVerified(false);
            navigate('/')
        }
      })
      .catch(async err => {
        await alert('El código no es válido o ha expirado.');
        setValue('');
        setLoading(false);
      })
  }

  return (
    <Fragment>
        <Container className="check-phone d-flex flex-column justify-content-center" style={styles?.container}>
            {
            showInputVerificationCode 
            ?
                <p style={styles?.text}>{profile?.phone === null || profile?.phone === '' ? data?.text_without_email : data?.text_change_email_number}</p>
            :
                <p style={styles?.text}>{data?.text_verification_code}</p>
            }
            
            {showInputVerificationCode
            ?
              <Fragment>
                {showInputNewNumber ?
                <div style={styles?.row}>
                    <div style={styles?.col}>
                        <FormGroup>
                            <Label>CORREO</Label>
                            <Input
                                maxLength={100}
                                name="email"
                                invalid={errors["email"]}
                                value={fields["email"]}
                                onChange={(e) => handleChange('email', e.target.value)}
                            />
                            <FormFeedback>{errors["email"]}</FormFeedback>
                        </FormGroup>
                    </div>
                </div>
                : null}
              
                <Button disabled={loading} onClick={() => handleSubmit()}>{!loading ? 'ENVIAR' : 'ENVIANDO'} {loading ? <Spinner size="sm" /> : null }</Button>
              
                {!showInputNewNumber ?
                <div style={{marginTop: '20px'}} onClick={() => setShowInputNewNumber(true)}><p style={styles?.link}>{data?.text_action_email_change}</p></div>
                : null}
              </Fragment>
            : 
              <div style={{
                alignItems: 'center',
              }}>
                <div style={{flexDirection: 'row',}}>
                  <div className="d-flex justify-content-center" style={styles?.col}>
                    <ReactCodeInput 
                        type='number' 
                        fields={4} 
                        value={value}
                        onChange={setValue} 
                    />
                  </div>
                </div>
                <div style={{marginTop: '20px'}} onClick={() => {setShowInputVerificationCode(true); setShowInputNewNumber(true);}}><p style={styles?.link}>{data?.code_resend_action_text}</p></div>
              </div>
            } 
        </Container>

        {/* {loading && showInputVerificationCode ? <Spinner /> : null} */}
    </Fragment>
  );
}

const styles = { 
  bgTop: {
    width: '100%',
    height: '350px',
    position: 'absolute',
    top: '-150px',
    left: 0,
    zIndex: -1,
  },  
  container: {
    flex: 1,
    padding: '15px',
    backgroundColor: '#fff',
    height: '100%',
  },
  row: {
    flexDirection: 'row',
    marginLeft: '-6px',
    marginRight: '-6px'
  },
  col: {
    flex: 1,
    marginLeft: '6px',
    marginRight: '6px'
  },
  logoContent: {
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '40px'
  },  
  logo: {
    width: '150px',
    height: '40px',
    marginBottom: '10px'
  },
  text: {
    fontFamily: 'Poppins-Regular',
    textAlign: 'center',
    fontSize: '12px',
    color: '#1a1413',
    marginBottom: '20px',
  },
  textBold: {
    fontFamily: 'Poppins-Regular',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#1a1413',
    marginBottom: '20px',
  },
  link: {
    fontFamily: 'Poppins-Regular',
    textAlign: 'center',
    padding: '10px',
    fontSize: '14px',
    color: '#536080',
  },


  root: {flex: 1, padding: '20px'},
  title: {textAlign: 'center', fontSize: '30px'},
  codeFieldRoot: {marginTop: '20px'},
  cell: {
    width: '40px',
    height: '40px',
    lineHeight: '38px',
    fontSize: '24px',
    borderWidth: '1px',
    borderColor: '#00000050',
    textAlign: 'center',
    borderRadius: '10px',
  },
  focusCell: {
    borderColor: '#000',
  },
};
