import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Button, Spinner, Card, CardBody } from 'reactstrap';
import { viewImage } from '../utils';

import avatar from '../assets/images/avatar.png';
import iconArrowDown from '../assets/icons/icon-arrow-down.svg';

var CryptoJS = require("crypto-js");

const styles = {
    text: {
        color: '#1d1d1b',
    },
    profiles: {
        flexDirection: 'row',
        marginLeft: '-3px',
        marginRight: '-3px',
        marginBottom: '15px',
    },
    profile: {
        paddingLeft: '3px',
        paddingRight: '3px',
        justifyContent: 'center',
        alignItems: 'center',
        width: '33%',
    },
    card: {
        backgroundColor: '#fff',
        borderRadius: '5px',
        borderWidth: '1px',
        borderColor: '#e1e1e1',
        padding: '5px',
        marginBottom: '20px'
    }
}

const Karma = ({ accounts, getProfile, setDefault }) => {
    const [loading, setLoading] = useState(false)
    const [exFollowers, setExFollowers] = useState([])
    const [verified, setVerified] = useState(false)
    const [log, setLog] = useState('')

    const active = accounts?.find((account) => account.is_default === true)

    const check = async () => {
        let user_id = active.iuser_id_graphql
        let count = 2000
        let max_id = null
        let followers = [];
        let followers_shazu = []
        let ex_followers = []

        setLoading(true);

        try {
            setLog(`Obteniendo la lista de seguidores de Shazu...`)
            let res = await axios.get('/api/orders/followers/')
            followers_shazu = res.data
            console.log('followers_shazu', res.data)

            setLog(`Obteniendo el número actual de seguidores de Instagram...`)
            let profile = await axios.get(`/api/instagram/web_profile_info/?username=${active.iusername}`, {
                withCredentials: true
            })

            let pages = Math.ceil(profile.data.data.user.edge_followed_by.count / count)
            for(var i = 0; i < pages; i++) {
                setLog(`Obteniendo seguidores de Instagram de la página ${i+1}/${pages}...`)
                let result = await axios.get(`/api/instagram/followers/?user_id=${active?.iuser_id}&count=${count}${max_id ? '&max_id='+ max_id : ''}`, {
                    withCredentials: true
                })
                followers = [...followers, ...result.data.users]
                max_id = result.data.next_max_id ? result.data.next_max_id : null
            }

            setLog(`Comparando la lista de seguidores de Instagram con los seguidores de Shazu...`)
            followers_shazu.forEach((follower_shazu) => {
                let follower = followers.find((item) => parseInt(item.pk) === parseInt(follower_shazu.iuser_id_graphql))
                if(!follower) {
                    ex_followers.push(follower_shazu)
                }
            })

            console.log('ex_followers', ex_followers)

            setTimeout(async () => {
                setVerified(true)
                // await alert(`${ex_followers.length} persona${ex_followers.length !== 1 ? 's' : ''} te ha${ex_followers.length !== 1 ? 'n' : ''} dejado de seguirte.`)
                setExFollowers(ex_followers)
                setLog('')
            }, 1000)

            setLoading(false);
        } catch(err) {
            setLoading(false);
        }
    }

    const karma = () => {
        const payload = JSON.stringify(exFollowers)
        var derived_key = CryptoJS.enc.Base64.parse("LefjQ2pEXmiy/nNZvEJ43i8hJuaAnzbA1Cbn1hOuAgA=")
        var iv = CryptoJS.enc.Utf8.parse("1020304050607080");
        var hash = CryptoJS.AES.encrypt(payload, derived_key, {iv: iv, mode: CryptoJS.mode.CBC}).toString();

        axios.post('/api/orders/karma/?hash=' + encodeURIComponent(hash))
            .then(async (res) => {
                if(res.status === 200) {
                    setVerified(false)
                    setExFollowers([])
                    setLog('')
                    if(res.data.magnets > 0) {
                        getProfile()
                        await alert(`Has recuperado ${res.data.magnets} ${res.data.magnets !== 1 ? 'imanes' : 'imán'}.`)
                    } else {
                        await alert('No se puede recuperar porque esta acción ya se ha realizado.')
                    }
                }
            })
            .catch(async (err) => {
                await alert('Ocurrio un error')
            })
    }

    return (
        <Fragment>
            <div className="container-app py-3">
                {!verified ?
                <>
                    <p className="font-size-14 mb-3" style={styles.text}>Comprueba si alguien te dejó de seguir, recupera tu crédito y castiga a la gente.</p>
                    <div className="register-profiles karma d-flex" style={styles.profiles}>
                        {accounts?.map((account, key) => {
                            return (
                                // <div key={key} style={styles.profile} onClick={() => setDefault(key)}>
                                //     <Card className={`w-100 text-center border-0 text-white ${accounts && accounts[key]?.is_default ? 'bg-danger' : 'bg-secondary bg-opacity-25'} h-100`}>
                                //         <CardBody onClick={() => accounts && accounts[key] ? setDefault(key) : null}>
                                //             <small>Perfil {key+1} </small>
                                //             <div>
                                //             <img src={accounts[key]?.iprofile_pic} className="border border-white border-5" style={{width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '50%'}} />
                                //             </div>
                                //             <small>{accounts && accounts[key] ? accounts[key].iusername : 'Nuevo Usuario'}</small>
                                //         </CardBody>
                                //     </Card>
                                //     {/* <CardProfile title={'Perfil '+ (key+1)} subtitle={'@'+ account.iusername} photo={{uri: account.iprofile_pic}} dark={active?.iusername !== account?.iusername} /> */}
                                // </div>

                                <div className="register-profile d-flex align-items-center" style={{width: '33.33%', minWidth: '33.33%'}} onClick={() => setDefault(key)}>
                                    <Card className={`w-100 text-center border-0 text-white p-2 ${accounts && accounts[key]?.is_default ? 'bg-danger-gradient' : 'bg-gray-1'} h-100`}>
                                        <div className="d-flex align-items-center">
                                            <div className="font-metropolis font-size-10 text-center w-100">Perfil {key+1}</div>
                                        </div>
                                        <CardBody className='pt-2 p-0 d-flex flex-column align-items-center'>
                                            <div className="position-relative">
                                            <img src={accounts && accounts[key] ? accounts[key]?.iprofile_pic : avatar} className="register-photo karma border border-white border-6 d-block" />
                                            </div>
                                            <div className="font-metropolis font-size-10 text-center w-100 mt-2 mb-2">{accounts && accounts[key] ? accounts[key].iusername : 'Nuevo Usuario'}</div>
                                        </CardBody>
                                    </Card>
                                    {/* {accounts && accounts[key]?.is_default ? <img src={iconArrowDown} className="register-profile-arrow" /> : null} */}
                                </div>
                            )
                        })}
                    </div>
                    <p className="font-size-12" style={{marginBottom: 10}}>{log}</p>
                    <Button className="text-uppercase mt-4 font-size-16" color="danger" block onClick={() => check()}>{loading ? 'Verificando...' : 'Verificar'} {loading ? <Spinner size="sm" /> : null}</Button>
                </>
                :
                <>
                    <div className="p-2" style={styles.card}>
                        <p className="font-size-14 mb-0" style={styles.text}>{exFollowers.length} persona{exFollowers.length !== 1 ? 's' : ''} te ha{exFollowers.length !== 1 ? 'n' : ''} dejado de seguirte.</p>
                        {exFollowers.length > 0 ? 
                            <>
                                <p className="font-size-14 mb-0 mt-2" style={styles.text}>{`Recupere tu${exFollowers.length !== 1 ? 's' : ''} credito${exFollowers.length !== 1 ? 's' : ''} y castigue a la${exFollowers.length !== 1 ? 's' : ''} persona${exFollowers.length !== 1 ? 's' : ''}`}</p>
                                <Button className="text-uppercase mt-4 font-size-16" color="danger" block onClick={() => karma()}>{loading ? 'Aguarde...' : 'Recuperar'} {loading ? <Spinner size="sm" /> : null}</Button> 
                            </>
                        : null}
                    </div>
                    <Button className="text-uppercase mt-4 font-size-16" color="danger" block onClick={() => {
                        setExFollowers([])
                        setVerified(false)
                    }}>{'Revisar otra vez'}</Button>
                </>
                }
            </div>
        </Fragment>
    );
}

export default Karma;