import React from 'react';
import { Card } from 'reactstrap';

const Drawer = ({ isOpen, toogle, children, direction='left', className }) => {   
    return (
        <div className={`drawer drawer-direction-${direction} ${isOpen ? 'drawer-open' : ''} ${className}`}>
            <Card className='drawer-content h-100 rounded-0'>
                {children}
            </Card>
            <div className='drawer-backdrop' onClick={() => toogle()}></div>
        </div>
    )
}

export default Drawer;