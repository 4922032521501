import axios from 'axios';

var CryptoJS = require("crypto-js");

export const loginWithInstagram = () => {
    window.location.href = process.env.REACT_APP_API_URL + '/auth/login-instagram'
}

export const loginWithFacebook = () => {
  window.location.href = process.env.REACT_APP_API_URL + '/auth/login-facebook'
}

export const getWebProfileInfo = (username, success, error) => {
    axios.get('/api/instagram/web_profile_info/?username=' + username,     {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        success(res.data)
      })
      .catch(error => {
          if(error.response.status === 429) {
            error('Para iniciar sesión necesitamos consultar los datos del perfil en Instagram. Instagram informó que se hicieron demasiadas solicitudes en un corto período de tiempo. Espere unos minutos para volver a intentarlo.')
          }
      }); 
}

export const createInstagramAccount = (username, user_id, token, success=null, error=null) => {
    axios.post('/api/account/instagram/', {
      iusername: username,
      iuser_id: user_id
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + token
      }
    })
      .then((res) => {
        if(success) success(res.data)
      })
      .catch((err) => {
        if(error) error(err)
      })
}

export const isMobile = () => {
  const userAgents = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (userAgents.Android() || userAgents.BlackBerry() || userAgents.iOS() || userAgents.Opera() || userAgents.Windows());
    }
  }
  return userAgents.any()
}

export const viewImage = (url) => {
  if(!url) return;
  const p = url.split("/");
  var t = '';
  for (let i = 0; i < p.length; i++) {
      if(i==2){
          t += p[i].replaceAll('-', '--').replaceAll('.','-')+atob('LnRyYW5zbGF0ZS5nb29n')+'/';
      } else { if(i != p.length-1){ t += p[i]+'/'; } else { t += p[i]; } }
  }
  return encodeURI(t);
}

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const deleteCookie = (cname) => {
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export const encrypt = (payload) => {
  let derived_key = CryptoJS.enc.Base64.parse("LefjQ2pEXmiy/nNZvEJ43i8hJuaAnzbA1Cbn1hOuAgA=")
  let iv = CryptoJS.enc.Utf8.parse("1020304050607080");
  let hash = CryptoJS.AES.encrypt(payload, derived_key, {iv: iv, mode: CryptoJS.mode.CBC}).toString();
  return hash
}

export const decrypt = (hash) => {
  let derived_key = CryptoJS.enc.Base64.parse("LefjQ2pEXmiy/nNZvEJ43i8hJuaAnzbA1Cbn1hOuAgA=")
  let iv = CryptoJS.enc.Utf8.parse("1020304050607080");
  let text = CryptoJS.AES.decrypt(hash, derived_key, {iv: iv, mode: CryptoJS.mode.CBC}).toString(CryptoJS.enc.Utf8)
  return text
}