import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import Slider from "react-slick";
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';

import iconUser2 from '../assets/icons/icon-user-2.svg';
import iconHeart from '../assets/icons/icon-heart-red.svg';
import iconPlay from '../assets/icons/icon-play.svg';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const Home = ({ getProfile }) => {
    const [banners, setBanners] = useState([]);
    const [loadingBanner, setLoadingBanner] = useState(false);
    const [data, setData] = useState(null);
    const [loadingData, setLoadingData] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        document.body.className = 'home';

        getProfile();

        setLoadingBanner(true);
        axios.get('/api/cms/home/banners/')
            .then(res => {
                setLoadingBanner(false);
                setBanners([...res.data, ...res.data, ...res.data]);
            })
            .catch(() => {
                setLoadingBanner(false);
            })
        
        setLoadingData(true)
        axios.get('/api/cms/home/')
            .then(res => {
                setLoadingData(false);
                setData(res.data.results[0])
            })
            .catch(() => {
                setLoadingData(false);
            })

        return () => { document.body.className = ''; }
    }, [])

    return (
        <div className="home">
            {banners.length ?
                <Slider {...settings}>
                    {banners.map((banner, key) => {
                        return (
                            <div key={key} className="home-banner position-relative">
                                <img src={banner.image} className="w-100" />
                                <div className="home-banner-desktop position-absolute p-3 d-flex flex-column h-100" style={{zIndex: 1, top: 0}}>
                                    <div className="home-banner-container">
                                        <p className='font-bebas-neue font-size-20 text-white w-50 fw-light text-uppercase'>{banner.title}</p>
                                        <div className="mt-4">
                                            {banner.btn_text ? <Button color="danger" className='border border-white fw-bold font-size-18 font-bebas-neue home-banner-button' onClick={() => banner.btn_link_externo ? (window.location.href = banner.btn_link_externo) : navigate(banner.btn_page)}>{banner.btn_text}</Button> : null}
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        )
                    })}
                </Slider>
            : null}

            {loadingBanner ? <Skeleton className="home-banner" /> : null}
                    
            <Container fluid className="p-4">
                {!loadingData ? 
                    <Card className="home-how-it-works border-0 rounded-0 p-0 mb-1">
                        <p className="text-uppercase text-center text-dark-2 font-bebas-neue fw-light font-size-30 p-0 m-0">{data?.how_it_works}</p>
                    </Card>
                : 
                    <Skeleton className="home-how-it-works border-0 rounded-0 p-0 mb-1" /> 
                }

                {!loadingData ?
                    <p className="text-uppercase font-bebas-neue fw-light font-size-10 text-dark-2">{data?.how_it_works_description}</p>                
                :
                    <Skeleton className="text-uppercase font-bebas-neue fw-light font-size-10 text-dark-2 mb-3" />
                }
                
                <div className="home-cards d-flex mb-2">
                    <div className="home-card" onClick={() => navigate('/promote#1')}>
                        {!loadingData ?
                            <Card className="p-1 rounded-0 mb-1">
                                <img src={data?.card1_image} />
                                <img src={iconUser2} className="home-card-icon" />
                                <p className="text-uppercase text-center text-dark-3 font-bebas-neue fw-bold font-size-10 m-0" style={{fontSize: '2vw'}}><b><small>{data?.card1_title}</small></b></p>
                            </Card>
                        : <Skeleton className="mb-1" count={2} />}
                        {!loadingData ?
                            <p className="text-dark-4 font-bebas-neue fw-light font-size-6 text-center mb-0 text-uppercase">{data?.card1_description}</p>
                        : <Skeleton className="mb-1" count={1} />}
                    </div>
                    <div className="home-card" onClick={() => navigate('/promote#2')}>
                        {!loadingData ?
                        <Card className="p-1 rounded-0 mb-1">
                            <img src={data?.card2_image} />
                            <img src={iconHeart} className="home-card-icon" />
                            <p className="text-uppercase text-center text-dark-3 font-bebas-neue fw-bold font-size-10 m-0" style={{fontSize: '2vw'}}><b><small>{data?.card2_title}</small></b></p>
                        </Card>
                        : <Skeleton className="mb-1" count={2} />}
                        {!loadingData ?
                        <p className="text-dark-4 font-bebas-neue fw-light font-size-6 text-center mb-0 text-uppercase">{data?.card2_description}</p>
                        : <Skeleton className="mb-1" count={1} />}
                    </div>
                    <div className="home-card" onClick={() => navigate('/promote#3')}>
                        {!loadingData ?
                        <Card className="p-1 rounded-0 mb-1">
                            <img src={data?.card3_image} />
                            <img src={iconPlay} className="home-card-icon" />
                            <p className="text-uppercase text-center text-dark-3 font-bebas-neue fw-bold font-size-10 m-0" style={{fontSize: '2vw'}}><b><small>{data?.card3_title}</small></b></p>
                        </Card>
                        : <Skeleton className="mb-1" count={2} />}
                        {!loadingData ?
                        <p className="text-dark-4 font-bebas-neue fw-light font-size-6 text-center mb-0 text-uppercase">{data?.card3_description}</p>
                        : <Skeleton className="mb-1" count={1} />}
                    </div>
                </div>
                <Row>
                    <Col>
                        {!loadingData ?
                        <Card className="px-1 py-0 bg-danger border-0 rounded-0 home-product" onClick={() => navigate('/recharge')}>
                            <Row>
                                <Col xs="4" className="d-flex">
                                    <img src={data?.banner_producto_image} className="w-100 mt-auto"/>
                                </Col>
                                <Col xs="8" className="py-1 d-flex flex-column">
                                    <div className='mb-auto'>
                                        <Card className="home-product-name p-1 rounded-0 border-0 text-uppercase text-center mb-1 text-dark-4 font-bebas-neue fw-bold font-size-16"><b>{data?.banner_producto?.name}</b></Card>
                                        <p className="text-white text-center text-uppercase font-bebas-neue fw-light font-size-8 mb-2" style={{fontSize: '2vw'}}>{data?.banner_producto_description} </p>
                                    </div>
                                    <Row className="home-product-bottom mt-auto align-items-center">
                                        <Col xs="6" className="pe-2">
                                            <Button color="light" size="sm" block className="home-product-button font-poppins fw-medium font-size-8 text-danger">COMPRAR</Button>
                                        </Col>
                                        <Col xs="6">
                                            <p className="m-0 text-white font-bebas-neue fw-light font-size-20">
                                                {data?.banner_producto?.price}€
                                                <b className="ms-2 fs-5 font-bebas-neue fw-bold font-size-34">{data?.banner_producto?.magnets}</b>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                        : <Skeleton className="mb-1" count={3} />}
                    </Col>
                </Row>
                <Row>
                    <Col>
                    {!loadingData ? <img src={data?.banner_bottom} className="w-100" /> : null}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Home;