import React, { useEffect, useState } from 'react';
import { Container, Button, Badge, Modal, ModalBody } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { useTour } from '@reactour/tour';
import {
    EmailShareButton,
    EmailIcon,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookShareButton,
    FacebookIcon
} from "react-share";

import iconShare from '../assets/icons/icon-share.svg';

const steps = [
    {
        selector: '.tour-step-share-1',
        content: <p className="font-size-12">Comparte tu código de invitación con tus conocidos, amigos y familiares para que se descarguen SHAZU APP. <br/><br/> Si permacecen registrados durante al menos 30 días, obtendás 500 imanes gratis por cada uno de ellos.</p>
    }
]

const ShareAndWin = ({ profile }) => {
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
    const [page, setPage] = useState(1)
    const [shareAndWins, setShareAndWins] = useState([])
    const [modal, setModal] = useState(false)

    const { setIsOpen, setSteps, setCurrentStep } = useTour()


    const getShareAndWins = (pagination) => {
        setIsFetchingNextPage(true);
        axios.get(`/api/shareandwin/?page=${pagination}`)
            .then(res => {
                if(pagination > 1) setShareAndWins(shareAndWins.concat(res.data.results));
                else setShareAndWins(res.data.results);
                setHasNextPage(res.data.next);
                setIsFetchingNextPage(false);
            })
            .catch((err) => {
                setHasNextPage(false);
                setIsFetchingNextPage(false);
            })
    }

    const getNextPage = () => {
        if(hasNextPage) {
            let _page = page + 1;
            setPage(_page);
            getShareAndWins(_page);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if(shareAndWins.length > 0 && localStorage.viewed_tour_6 !== 'true') {
                localStorage.viewed_tour_6 = 'true'
                setIsOpen(true)
                setSteps(steps)
                setCurrentStep(0)
            }
        }, 300)
    }, [shareAndWins])

    useEffect(() => {
        document.body.className = 'shareandwin-page';
        getShareAndWins(1)
        return () => { document.body.className = ''; }
    }, [])

    return (
        <>
            <div className="shareandwin pt-3 pb-4">
                <div className='shareandwin-container'>
                    <p className="text-uppercase text-center fw-bold mt-0 mb-2 font-bebas-neue font-size-18">código de invitación</p>
                    <div className="d-flex justify-content-center align-items-center" onClick={() => setModal(true)}>
                        <img src={iconShare} className="shareandwin-icon" />
                    </div>

                    <Modal isOpen={modal} toggle={() => setModal(false)}>
                        <ModalBody>
                            <div className="d-flex justify-content-center fs-1">
                                {/* <FacebookIcon round size={40} className="mx-1 mt-1" onClick={() => window.open('https://www.facebook.com/dialog/feed?app_id=305951608195605&amp;href=http://www.mydomain.com&amp;picture=http://www.mydomain.com/photos/1222/thumbnail&amp;description=Blah&amp;name=Name&amp;redirect_uri=http://www.mydomain.com/"', 'popup')} /> */}
                                {/* https://www.facebook.com/dialog/feed?app_id=xxxxx&amp;link=http://www.mydomain.com&amp;picture=http://www.mydomain.com/photos/1222/thumbnail&amp;description=Blah&amp;name=Name&amp;redirect_uri=http://www.mydomain.com/" */}
                                <WhatsappShareButton
                                    className="mx-1"
                                    url={'http://shazu.start-7.com/'}
                                    title={`Entra en la aplicación Shazu e ingresa este código de invitación: ${profile?.invitation_code}`}>
                                    {/* <i class="fa fa-whatsapp text-success"></i> */}
                                    <WhatsappIcon round size={40} />
                                </WhatsappShareButton>
                                <EmailShareButton
                                    className="mx-1"
                                    subject={`Código de invitación para la aplicación Shazu`}
                                    body={`Entra en la aplicación Shazu e ingresa este código de invitación: ${profile?.invitation_code}`}
                                    url={'http://shazu.start-7.com/'}>
                                    <EmailIcon round size={40} />
                                </EmailShareButton>
                            </div>
                        </ModalBody>
                    </Modal>

                    <div className="d-flex justify-content-center">
                        <div className="shareandwin-button d-flex justify-content-center align-items-center">
                            <CopyToClipboard text={profile?.invitation_code} onCopy={() => toast('Copiado', {type: 'success'})}>
                            <div className="tour-step-share-1 bg-info bg-opacity-10 position-relative d-flex justify-content-center align-items-center w-75 my-3 p-3 rounded-3">
                                <Button color="danger position-absolute">+</Button>
                                <div className="h1 m-0 font-bebas-neue fw-bold font-size-40">{profile?.invitation_code}</div>
                            </div>
                            </CopyToClipboard>
                        </div>
                    </div>

                    <p className="text-uppercase text-center fw-bold mb-2 font-bebas-neue fw-bold font-size-18">historial de compartidos</p>
                    <ul className="m-0 p-0" style={{listStyle: 'none'}}>
                        {shareAndWins?.map((item, key) => {
                            return (
                                <li key={key} className="py-1 border-bottom">
                                    <div className="d-flex align-items-center">
                                        <div className="font-bebas-neue-book font-size-16 fw-normal">
                                            <b className="text-danger-2 font-bebas-neue fw-bold">{item.guest_user.username.indexOf('@') === -1 ? '@' : ''}{item.guest_user.username}</b><br/>
                                            {item.days_remaining > 0 ? <small>{item.days_remaining} dia{item.days_remaining === 1 ? '' : 's'} restante{item.days_remaining === 1 ? '' : 's'} para cangear</small> : null}
                                            {item.days_remaining === 0 && item.status === 'in-progress' ? <small>Será procesado pronto</small> : null}
                                            {item.days_remaining === 0 && item.status === 'finished' ? <small>Finalizado ({item.magnets} imanes fueron acreditados)</small> : null}
                                        </div>
                                        <div className="ms-auto">
                                            <Badge className="font-bebas-neue fw-bold fw-bold text-dark-2">{moment(item.start_date).format('DD-MM-YY')}</Badge>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>

                    {hasNextPage ?
                            <div className="text-center my-3">
                                <Button color="danger" size="sm" onClick={() => getNextPage()}>VER MÁS</Button>
                            </div>
                    : null}   
                </div>             
            </div>
        </>
    )
}

export default ShareAndWin;