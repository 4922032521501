import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';

import iconListGray from '../assets/icons/icon-list-gray.svg';
import iconListRed from '../assets/icons/icon-list-red.svg';
import iconGridGray from '../assets/icons/icon-grid-gray.svg';
import iconGridRed from '../assets/icons/icon-grid-red.svg';

const ViewSelector = ({ active, onClick, types=['list', 'grid'] }) => {
    return (
        <div className="view-selector d-flex justify-content-center">
            {types.map((type, key) => {
                return (
                    <div key={key} className={`view-selector-item d-flex justify-content-center align-items-center mx-1`} onClick={() => onClick(type)}>
                        {type === 'list' ? <img src={active === type ? iconListRed : iconListGray} className="border-0 d-block tour-step-follow-2" /> : null}
                        {type === 'grid' ? <img src={active === type ? iconGridRed : iconGridGray} className="border-0 d-block tour-step-follow-1" />  : null}
                        {type === 'flick' ? <div className={`view-selector-item border ${active === type ? 'border-danger' : 'border-secondary border-opacity-75'}`}><div className={`border w-100 h-100 ${active === type ? 'bg-danger' : 'bg-secondary bg-opacity-75'}`} style={{borderWidth: '0.1vw'}} /></div> : null}
                    </div>
                )
            })}
        </div>
    )
}

export default ViewSelector;