import React, { useState } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormFeedback, Button, Spinner, InputGroup, InputGroupText } from "reactstrap";
import axios from 'axios';

import iconPassword from '../assets/icons/icon-password.svg';
import iconEye2 from '../assets/icons/icon-eye-2.svg';
import iconEye from '../assets/icons/icon-eye.svg';

const FormChangePassword = ({ profile, logout }) => {
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [showPassword1, setShowPasswor1] = useState(false);
    const [showPassword2, setShowPasswor2] = useState(false);

    const handleChange = (field, value) => {
        setFields({ ...fields, [field]: value });
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
    
        if (!fields['password']) {
            formIsValid = false;
            errors['password'] = 'Campo obligatorio.';
        }

        if (fields['password']) {
            let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if(!re.test(fields['password'])) {
            formIsValid = false;
            errors['password'] = 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caracter especial.';
            }
        }

        if (!fields['confirm_password']) {
            formIsValid = false;
            errors['confirm_password'] = 'Campo obligatorio.';
        }

        if (fields['confirm_password'] && fields['password'] && (fields['confirm_password'] !== fields['password'])) {
            formIsValid = false;
            errors['confirm_password'] = 'Las contraseñas no coinciden.';
        }
    
        setErrors(errors);
    
        console.log('handleValidation', errors);

        return formIsValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            savePassword();
        }
    }

    const savePassword = () => {
        let data = {password: fields.password}

        if(profile.has_password) {
          data = {
            old_password: fields.password,
            new_password: fields.password,
          }
        }
    
        axios.put(`/api/auth/${profile.has_password ? 'change-password' : 'set-password'}/`, data)
          .then(async (res) => {
            if(profile.has_password) {
              await alert(`Inicie sesión con su nueva contraseña.`)
              logout();
            }
          })
          .catch((err) => {
            let errors = {};
            for(let key in err.response.data) {
              errors[key] = err.response.data[key];
            }
            setErrors(errors)
          })
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <InputGroup className="input-group-1">
                  <InputGroupText>
                    <img src={iconPassword} />
                  </InputGroupText>
                  <Input
                    className="form-control-1 hide-icon"
                    id="password"
                    name="password"
                    placeholder="Contraseña"
                    type={!showPassword1 ? 'password' : 'text' }
                    value={fields?.password}
                    invalid={errors?.password}
                    style={{boxShadow: 'none'}}
                    onChange={(e) => handleChange('password', e.target.value)}
                  />
                  <InputGroupText className="input-group-text-1-password" onClick={() => setShowPasswor1(!showPassword1)}>
                    <img src={!showPassword1 ? iconEye : iconEye2} />
                  </InputGroupText>
                  <FormFeedback>{errors?.password}</FormFeedback>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <InputGroup className="input-group-1">
                  <InputGroupText>
                    <img src={iconPassword} />
                  </InputGroupText>
                  <Input
                    className="form-control-1 hide-icon"
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="Confirmar contraseña"
                    type={!showPassword2 ? 'password' : 'text' }
                    value={fields?.confirm_password}
                    invalid={errors?.confirm_password}
                    style={{boxShadow: 'none'}}
                    onChange={(e) => handleChange('confirm_password', e.target.value)}
                  />
                  <InputGroupText className="input-group-text-1-password" onClick={() => setShowPasswor2(!showPassword2)}>
                    <img src={!showPassword2 ? iconEye : iconEye2} />
                  </InputGroupText>
                  <FormFeedback>{errors?.confirm_password}</FormFeedback>
                </InputGroup>
            </FormGroup>
            <Button type="submit" color="danger" className="rounded-0 font-bebas-neue fw-light font-size-20 py-1" block disabled={loading}>{!loading ? 'CAMBIAR CONTRASEÑA' : 'AGUARDE...'} {loading ? <Spinner size="sm" /> : null}</Button>
        </Form>
    )
}

export default FormChangePassword;