import React, { useState } from 'react'
import { Card, CardBody, Badge, Button } from 'reactstrap';
import TinderCard from 'react-tinder-card'
import { viewImage } from '../utils';

import iconMagnets from '../assets/icons/icon-circle-navbar.svg';
import iconCam from '../assets/icons/icon-cam.svg';
import iconHeartRed from '../assets/icons/icon-heart-red.svg';
import iconVideoRed from '../assets/icons/icon-video-red.svg';
import iconEnterRed from '../assets/icons/icon-enter-red.svg';
import iconPremium from '../assets/icons/icon-premium.svg';

const _colorsGradient = [
  ['#2b84c5', '#fcfdfe'], // blue
  ['#ffe91e', '#e3302d'], // yellow
  ['#e71d73', '#e71d7350'], // pink
  ['#2fac66', '#2fac6650'], // green
  ['#ca9e67', '#ca9e6750'], // brown
  ['#a3195b', '#a3195b50'], // purple
]

const colorsGradient = [
  'bg-danger-gradient-horizontal',
  'bg-dark-gradient-horizontal',
  'bg-warning-gradient-horizontal',
  'bg-info-gradient-horizontal'
]

function CardFlick ({ items, colors, goToPost, type='like' }) {
  const _items = JSON.parse(JSON.stringify(items)).reverse()

  return (
    <div className="position-relative">
      {_items.map((item, key) =>
        <TinderCard className='position-absolute w-100' key={item.id}>
          <Card className={`card-flick position-relative mb-0 ${item.is_premium ? 'is-premium' : ''}`} style={{backgroundColor: '#ccc', overflow: 'hidden', height: 'auto'}}>
            <div className={`card-flick-circle rounded-pill ${colorsGradient[key % 4]}`} />
            <CardBody className={`d-flex align-items-center text-center position-relative p-2 flex-column`}>
              <Badge color="white" className="card-flick-magnets card-post-magnets ms-auto font-bebas-neue fw-bold text-dark-2 font-size-14">+{item?.magnets}<img src={iconMagnets} /></Badge>
              <div className="position-relative">
                {type === 'like' ? <img src={iconCam} className="card-flick-type" /> : null}
                <img src={process.env.REACT_APP_API_URL + item?.thumbnail} className="card-flick-image border border-5 border-white rounded-4 bg-secondary d-block my-2" />
                {item?.is_premium ? <img src={iconPremium} className="card-flick-premium" /> : null}
              </div>
              <div className="card-flick-content d-flex">
                <div className="d-flex align-items-center">
                  {type === 'like' ? <img src={iconHeartRed} className="card-flick-icon" style={{marginTop: '-1vw'}} /> : null}
                  {type === 'view' ? <img src={iconVideoRed} className="card-flick-icon" style={{marginTop: '-1vw'}} /> : null}
                  <span className="font-bebas-neue fw-light text-dark-2 font-size-18">{item.like_count}</span>
                </div>
                <div className="ms-auto text-uppercase font-bebas-neue fw-bold text-dark-2 font-size-18">
                  <img src={iconEnterRed} className="card-flick-icon-enter" />
                  <b>@{item?.iusername}</b>
                </div>
              </div>
              <div className={`card-flick-content d-flex flex-column mt-4`} onTouchStart={() => goToPost(item)} onClick={() => goToPost(item)}>
                  <div className={`card-flick-button font-bebas-neue fw-bold text-dark-2 font-size-36`}>
                    {type === 'like' ? 'DAR LIKE' : ''}
                    {type === 'view' ? 'VER VÍDEO' : ''}
                  </div>
              </div>
            </CardBody>
          </Card>
          <div className={`card-flick-bottom rounded-bottom ${colorsGradient[key % 4]}`} />
        </TinderCard>
      )}
    </div>
  )
}

export default CardFlick