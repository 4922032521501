import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Badge, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import axios from 'axios';
import ViewSelector from '../components/ViewSelector';
import { useTour } from '@reactour/tour';
import Skeleton from 'react-loading-skeleton';
import CardFlick from '../components/CardFlick';
import { viewImage } from '../utils';

import iconVideo from '../assets/icons/icon-video.svg';
import iconMagnets from '../assets/icons/icon-circle-navbar.svg';
import iconPremium from '../assets/icons/icon-premium.svg';

const steps = [
    {
        selector: '.tour-step-video-1',
        content: <p className="font-size-10">Estás a punto de ser dirigid@ a la publicación de instagram seleccionada para que puedas repruducir el vídeo, a cambio recibirás los imanes acordados. <br/><br/> Deberás permanecer reprocucciendo el video al menos 7 segundos para que tu reproducción pueda ser contabilizado y quede registrada con éxito. <br/><br/> Para un correcto funcionamiento, realizamos rastreos continuos de cada reprocucción asegurandonos de que secumple el tiempo estipulado. </p>,
    }
]

var CryptoJS = require("crypto-js");

var worker;

const colors = [
    '#36a9e1', // blue
    '#f39200', // yellow
    '#e71d73', // pink
    '#2fac66', // green
    '#ca9e67', // brown
    '#a3195b', // purple
]

const colorsGradient = [
    ['#2b84c5', '#fcfdfe'], // blue
    ['#ffe91e', '#e3302d'], // yellow
    ['#e71d73', '#e71d7350'], // pink
    ['#2fac66', '#2fac6650'], // green
    ['#ca9e67', '#ca9e6750'], // brown
    ['#a3195b', '#a3195b50'], // purple
]

var __orders = []

const Video = ({ getProfile }) => {
    const [view, setView] = useState('grid');
    const [intialLoad, setIntialLoad] = useState(true);
    const [orders, setOrders] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [page, setPage] = useState(1)
    const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
    const [modal, setModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [status, setStatus] = useState('');
    const [showButton, setShowButton] = useState(null);
    const [checking, setChecking] = useState(false);
    const [checkingFail, setCheckingFail] = useState(false);

    const { setIsOpen, setSteps, setCurrentStep } = useTour()

    const _setOrders = (_orders) => {
        setOrders(_orders);
        __orders = _orders;
    }

    const getOrders = (pagination) => {
        setIsFetchingNextPage(true);
        axios.get(`/api/orders/items/?page=${pagination}&objective=views`)
            .then(res => {
                setIntialLoad(false);
                if(pagination > 1) _setOrders(orders.concat(res.data.results));
                else _setOrders(res.data.results);

                setHasNextPage(res.data.next);
                setIsFetchingNextPage(false);
            })
            .catch(err => {
                setHasNextPage(false);
                setIsFetchingNextPage(false);
                console.log(err);
            })
    }

    const startWorker = async (item) => {
        if(typeof(Worker) !== "undefined") {
          if(typeof(w) == "undefined") {
            worker = new Worker("/view_worker.js");
          }
          worker.onmessage = function(event) {
            setStatus(`Quedan ${5 - event.data} segundos para que recibas tus imanes`)
            if(event.data >= 5) {
                stopWorker()
                check(item)
            }
          };
        } else {
            await alert('Lo sentimos, su navegador no es compatible.')
        }
      }
      
    const stopWorker = () => { 
        worker.terminate();
        worker = undefined;
    }

    const goToPost = async (item) => {
        setModal(true)
        setShowButton(null)

        try {
            let checkDailyPermission = await axios.get('/api/orders/check_daily_permission')
            if(!checkDailyPermission.data.views) {
                setModal(false)    
                await alert('Ha alcanzado tu límite diario de ver videos.')
                return;
            }
        } catch(err) {
            await alert('Hubo un error al verificar su límite diario.')
            return;
        }        

        setStatus(`Serás redirigido a Instagram para dar ver el vídeo.<br /> Después de realizar la acción, debes regresar a Shazu para recibir tus imanes.`)
        
        setTimeout(() => {
            setShowButton(item)

            // var a = document.createElement('a');
            // a.target="_blank";
            // a.href='https://www.instagram.com/p/' + item.shortcode;
            // window.location.href= navigator.userAgent.match(/iPhone|iPad|iPod/i) ? `instagram://media?id=${item.media_id}` : `https://www.instagram.com/reel/${item.shortcode}/`;
            // a.click();

            if(navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                window.location.href = `instagram://media?id=${item.media_id}`
            }
            else {
                var a = document.createElement('a');
                a.href = `https://www.instagram.com/reel/${item.shortcode}/`
                a.target="_blank";
                a.click()
            }  
            
            startWorker(item)
        }, 2000)
    }

    const confirmAction = (item, hash) => {
        let _orders = __orders.filter(order => order.id !== item.id);
        axios.post(`/api/orders/perform_action/?hash=${hash}`)
            .then(res => {
                setStatus(`Hemos añadido <b>+${item.magnets}</b> imanes a tu cuenta de Shazu`)
                setShowButton(null)
                getProfile()
                setTimeout(() => {
                    _setOrders(_orders);
                    setModal(false)
                    setStatus('')
                    setChecking(false)
                    setCheckingFail(false)
                }, 3000)
            })
            .catch(async (err) => {
                if(err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
                    _setOrders(_orders);
                }
                await alert(err.response.data.detail);

                setModal(false)
                setStatus(false)
                setChecking(false)
                setCheckingFail(false)
            })
    }

    const check = (item) => {
        const payload = JSON.stringify({id: item.id})
        var derived_key = CryptoJS.enc.Base64.parse("LefjQ2pEXmiy/nNZvEJ43i8hJuaAnzbA1Cbn1hOuAgA=")
        var iv = CryptoJS.enc.Utf8.parse("1020304050607080");
        var hash = CryptoJS.AES.encrypt(payload, derived_key, {iv: iv, mode: CryptoJS.mode.CBC}).toString();
        
        confirmAction(item, encodeURIComponent(hash))
    }


    const removeFromFeed = (id) => {
        axios.post(`/api/orders/remove_from_feed/?item=${id}`)
            .then(res => {
                let _orders = orders.filter(order => order.id !== id);
                _setOrders(_orders);
            })
            .catch(async (err) => {
                await alert(err.response.data.detail);
            })
    }

    const getNextPage = () => {
        if(hasNextPage) {
            let _page = page + 1;
            setPage(_page);
            getOrders(_page);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if(orders.length > 0 && localStorage.viewed_tour_4 !== 'true') {
                localStorage.viewed_tour_4 = 'true'
                setIsOpen(true)
                setSteps(steps)
                setCurrentStep(0)
            }
        }, 200)
    }, [orders])

    useEffect(() => {
        getOrders(1)
    }, [])

    return (
        <>
            <Container className={`video my-3 px-0 mx-auto ${orders.length === 0 ? 'h-100' : ''}`}>
                {orders.length ? <ViewSelector types={['grid', 'flick']} active={view} onClick={setView} /> : null}
                <Row className="video-row my-2">
                    {view === 'grid' && orders.map((item, key) => {
                        return (
                            <Col className={`video-col ${view === 'grid' ? 'video-col-grid' : 'video-col-list'}`} key={key} xs={6}>
                                <Card className={`card-post text-white mb-4 pb-1 ${item.is_premium ? 'is-premium' : ''}`} style={{backgroundColor: colors[key % 6]}} onClick={() => goToPost(item)}>
                                    <CardBody>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                                <img src={iconVideo} className="card-post-icon" />
                                                <div className="video-div bg-white mx-1" />
                                                <span className="fw-bold font-size-8">{item?.like_count}</span>
                                            </div>
                                            <div className="d-flex align-items-center ms-auto">
                                                <Badge color="white" className="card-post-magnets ms-auto font-bebas-neue fw-bold text-dark-2 font-size-14">+{item?.magnets}<img src={iconMagnets} /></Badge>
                                            </div>
                                        </div>
                                        <div className="mt-2 position-relative">
                                            <img src={process.env.REACT_APP_API_URL + item?.thumbnail} className="card-post-image border border-2 border-white rounded w-100 d-block" />
                                            <Badge color="white" className="card-post-username font-bebas-neue text-secondary position-absolute font-size-8" style={{bottom: 0, right: 0}}>@{item.iusername}</Badge>
                                            {item?.is_premium ? <img src={iconPremium} className="card-post-premium" /> : null}
                                        </div>
                                        <div className={`text-center font-size-22 fw-medium mt-1 ${key === 0 ? 'tour-step-video-1' : ''}`}>VER VIDEO</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}

                    {intialLoad ? 
                        <>
                            <Col className='video-col video-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='video-col video-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='video-col video-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='video-col video-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='video-col video-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='video-col video-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                        </>
                    : null}                    
                </Row>

                {view === 'flick' && orders.length > 0 ? <div className='like-flick-height'><CardFlick type="view" items={orders} colors={colors} goToPost={goToPost} /></div> : null}

                {/* {intialLoad ? 
                <div className="d-flex flex-column justify-content-center align-items-center text-center h-100">
                    <p className="font-size-16 mb-0">
                        Cargando... <Spinner size="sm" />
                    </p>
                </div>
                : null} */}

                {!intialLoad && orders.length === 0 ?
                <div className="d-flex flex-column justify-content-center align-items-center text-center h-100">
                    <p className="font-size-12 mb-0">
                        En esto momento no hay vídeos disponibles.<br/>
                        Ten un poco de paciencia, pronto llegarán.
                    </p>
                    <Button color="danger" className="text-uppercase font-size-14 mt-3 px-4" onClick={() => getOrders(1)}>{isFetchingNextPage ? 'Actualizando...' : 'Actualizar'} {isFetchingNextPage ? <Spinner size="sm" /> : null}</Button>
                </div>
                : null}

                {hasNextPage ?
                        <div className="text-center my-3">
                            <Button color="danger" size="sm" onClick={() => getNextPage()}>VER MÁS</Button>
                        </div>
                : null}

                <Modal isOpen={modal} toggle={() => setModal(false)} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={() => setModal(false)}>Shazu</ModalHeader>
                    <ModalBody className="d-flex justify-content-center align-items-center text-center">
                        <div>
                            <p className="m-0" dangerouslySetInnerHTML={{ __html: status }} ></p>
                            {loadingModal ? <Spinner /> : null}
                            {/* {showButton ? <Button color="danger" className="mt-3" onClick={() => check(showButton)}>{!checking ? (!checkingFail ? 'Ya vi el video' : 'Revisar otra vez') : 'Comprobando...'} {checking ? <Spinner size="sm" /> : null}</Button> : null} */}
                        </div>
                    </ModalBody>
                </Modal>
            </Container>
        </>

    )
}

export default Video;