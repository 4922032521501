import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Spinner, Button, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Alert } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTour } from '@reactour/tour';
import Skeleton from 'react-loading-skeleton';
import SelectProfile from '../components/SelectProfile';
import PromoteProfile from '../components/PromoteProfile';
import PromoteCards from '../components/PromoteCards';
import { viewImage, getWebProfileInfo } from '../utils';

import iconMagnets from '../assets/icons/icon-circle-navbar.svg';

const steps = [
    {
        selector: '.tour-step-promote-1',
        content: 'Desde esta sección podrás seleccionar el tipo de campaña y si quires promocionar uno de tus perfiles, fotografías o vídeos de Insatagram',
    },
    {
        selector: '.tour-step-promote-2',
        content: 'Selecciona el la cuenta de Instagram que quieres promocionar para llegar a más audiencia, este perfil será el seleccionado por tí para que reciba el trafico de tus campañas.',
    },
    {
        selector: '.tour-step-promote-3',
        content: 'Introduce la cantidad exacta de seguidores, likes o reproducciones que quieres conseguir según la campaña seleccionada.',
    },
    // {
    //     selector: '.tour-step-promote-4',
    //     content: 'Si activas esta opción, podrás ofrecer el número de imanes que tu quieras (de 2 en 2) a los usuarios de SHAZU para que te sigan, te hagan like o reproduzcan tus vídeos, de esta forma tus campañas serán mucho más rápidas y efectivas.',
    // },
    {
        selector: '.tour-step-promote-5',
        content: 'Una vez registrados correctamente los datos de la campaña debemos pulsar en enviar orden para activar la promoción.',
    },
    {
        selector: '.tour-step-promote-6',
        content: 'Sin salir de esta pantalla tendrás acceso a todas las ordenes creadas recientes y con anterioridad, tendás un listado completo y detallado de todas y cada una de ellas.'
    },
    {
        selector: '.tour-step-promote-7',
        content: 'Cada vez que pongas una orden, tendás presente la suma total de imanes que te cuesta una o varias campañas. de esta forma podrás saber en todo momento los costes de tu promoción. Puedes activar varias al mismo tiempo y en una sola orden.'
    }
]

const Promote = ({ config, accounts, setDefault, getProfile, profile, posts, setPosts, postsAllAccounts, setPostsAllAccounts, videosAllAccounts, setVideosAllAccounts, videos, setVideos }) => {
    const [activeTab, setActiveTab] = useState(2)
    const [quantityOfFollow, setQuantityOfFollow] = useState(0);
    const [isPrivate, setIsPrivate] = useState(false);
    // const [posts, setPosts] = useState(null);
    // const [videos, setVideos] = useState(null);
    const [itemsLike, setItemsLike] = useState([])
    const [itemsView, setItemsView] = useState([])
    const [sendingOrder, setSendingOrder] = useState(false);
    const [loadingPosts, setLoadingPosts] = useState(false);
    const [loadingVideos, setLoadingVideos] = useState(false);
    const [csrftoken, setCsrftoken] = useState('')

    const { setIsOpen, setSteps, setCurrentStep } = useTour()

    const navigate = useNavigate();

    const account = accounts?.find((item) => item.is_default === true)

    const getPosts = (count='12', max_id=null, callback=null) => {
        setLoadingPosts(true);
        axios.get(`/api/instagram/feed/?username=${account?.iusername}&count=${count}${max_id ? '&max_id=' + max_id : ''}`, {
            withCredentials: true
        })
            .then(async (res) => {
                if(res.data.error) {
                    await alert('Hubo un error al obtener los posts de Instagram.');
                }
                setLoadingPosts(false);
                let _posts = posts ? {
                    ...res.data,
                    items: [
                        ...posts.items,
                        ...res.data.items
                    ]
                } : res.data
                
                setPosts(_posts)
                
                let _postsAllAccounts = {...postsAllAccounts}
                _postsAllAccounts[account?.iuser_id] = _posts
                setPostsAllAccounts(_postsAllAccounts)

                setCsrftoken(res.headers.get('x-csrftoken'))
                if(callback) callback(res.headers.get('x-csrftoken'))
            })
            .catch(async (err) => {
                console.log('err', err)
                setLoadingPosts(false);
                await alert('Hubo un error al obtener los posts de Instagram');
            })
    }

    const getVideos = (count='12', max_id=null, csrftoken) => {
        setLoadingVideos(true);
        axios.get(`/api/instagram/videos/?userid=${account?.iuser_id}&count=${count}${max_id ? '&max_id=' + max_id : ''}&csrftoken=${csrftoken}`, {
            withCredentials: true
        })
            .then(async (res) => {
                if(res.data.error) {
                    await alert('Hubo un error al obtener los videos de Instagram.');
                }
                setLoadingVideos(false);
                let _videos = videos ? {
                    ...res.data,
                    items: [
                        ...videos.items,
                        ...res.data.items
                    ]
                } : res.data
                setVideos(_videos)
            })
            .catch(async (err) => {
                setLoadingVideos(false);
                await alert('Hubo un error al obtener los videos de Instagram.');
            })
    }

    const getValue = (code, obj) => {
        let item = obj.find((itemLike) => itemLike.shortcode === code)
        return item ? item.quantity : 0
    }

    const sendOrder = () => {
        setSendingOrder(true);
        let objective = null;
        let items = [];

        if(activeTab === 1) {
            objective = 'followers';
            items.push({
                objective: 'followers',
                shortcode: account?.iusername,
                quantity: quantityOfFollow,
                magnets: config.magnets_per_follower,
            })
        }
        else if(activeTab === 2) {
            objective = 'likes';
            items = [...itemsLike];
        }
        else if(activeTab === 3) {
            objective = 'views';
            items = [...itemsView];
        }
        axios.post('/api/orders/', {
            profile: account.id,
            objective: objective,
            items: items,
        })
            .then(async res => {
                console.log('test 1')
                setSendingOrder(false);
                console.log('test 2')
                if(res.status === 201) {
                    console.log('test 3')
                    // checkProfile();
                    getProfile()
                    console.log('test 4')
                    await alert(`Orden enviada con éxito!`)
                    console.log('test 5')
                    navigate('/promote/history/')
                    console.log('test 6')
                }
            })
            .catch(async err => {
                console.log('err sendOrder', err)
                console.log('err sendOrder', err.response.data)
                setSendingOrder(false);
                for(let key in err.response.data) {
                    if(err.response.data[key].length) {
                        await alert(err.response.data[key])
                    }
                }
            })
    }

    const getTotalLikes = () => {
        let total = 0;
        itemsLike.forEach(item => {
            total += item.quantity * item.magnets;
        })
        return total;
    }

    const getTotalViews = () => {
        let total = 0;
        itemsView.forEach(item => {
            total += item.quantity * item.magnets;
        })
        return total;
    }

    const setDefaultAccount = (key) => {
        setDefault(key);
        setPosts(null);
        setVideos(null);
    }

    useEffect(() => {
        if(account && !posts) {
            // setPosts(null)
            // setVideos(null)

            setLoadingPosts(true);
            setLoadingVideos(true);
            axios.post('/api/instagram/web_profile_info/?username=' + account.iusername, {
                withCredentials: true
            })
                .then((res) => {
                    let is_private = res.data.data.user.is_private
                    setIsPrivate(is_private)
                    if(!is_private) {
                        getPosts('12', null, (csrftoken) => {
                            setTimeout(() => {
                                getVideos('12', null, csrftoken)
                            }, 1000)
                        })
                    }
                })
        }
    }, [account])

    useEffect(() => {
        setTimeout(() => {
            if(posts?.items && posts?.items.length > 0 && localStorage.viewed_tour_5 !== 'true') {
                localStorage.viewed_tour_5 = 'true'
                setIsOpen(true)
                setSteps(steps)
                setCurrentStep(0)
            }
        }, 200)
    }, [posts])

    useEffect(() => {
        setActiveTab(parseInt(window.location.hash.replace('#', '')))
        
        document.body.className = 'page-promote';
        return () => { document.body.className = ''; }
    }, [])

    const totalLikes = getTotalLikes();
    const totalViews = getTotalViews();
    
    return (
        <Container className="promote px-0">
            <Card className="promote-card-top">
                <CardBody>
                    <div className="d-flex align-items-center">
                        <div className="me-2">
                            <img src={account?.iprofile_pic ? account?.iprofile_pic : null} className="promote-card-top-image border rounded-circle" />
                        </div>
                        <div className="promote-card-top-content d-flex flex-column ps-1 w-100">
                            <div className="d-flex align-items-center mb-2">
                                <div className="promote-card-top-name text-uppercase font-bebas-neue fw-bold text-dark-2 font-size-16"><b>{profile?.first_name} {profile?.last_name}</b></div>
                                <div className="ms-auto">
                                    <Link to="/profile/" className="promote-card-top-btn btn bg-secondary rounded-pill bg-opacity-25 text-dark-6 font-size-8 d-flex align-items-center">Editar perfil</Link>
                                </div>
                            </div>
                            <div className="mt-auto">
                                <div className="d-flex align-items-center">
                                    <div className="promote-card-top-data">
                                        <p className="text-uppercase font-bebas-neue fw-light text-dark-6 font-size-12 mb-0">Balance disponible</p>
                                        <span className="text-uppercase font-bebas-neue fw-bold text-dark-4 font-size-16 promote-card-top-image">{profile?.magnets} <img src={iconMagnets} /></span>
                                    </div>
                                    <div className="promote-card-top-data-2">
                                        <p className="text-uppercase font-bebas-neue fw-light text-dark-6 font-size-12 mb-0" style={{fontSize: 8}}>ID usuario</p>
                                        <span className="text-uppercase font-bebas-neue fw-bold text-dark-4 font-size-16">{profile?.id}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="d-flex flex-column ps-1">
                            <a className="btn bg-secondary rounded-pill bg-opacity-25">Editar perfil</a>
                            <div className="mt-auto">
                                <p className="text-uppercase mb-0"><small>ID usuario</small></p>
                                <span><b>8.547</b></span>
                            </div>
                        </div> */}
                    </div>
                </CardBody>
            </Card>

            <div className="promote-dashed my-2" />

            <Row className="mb-2">
                <Col>
                    <div className="text-uppercase"><b className="d-flex text-dark-2 font-bebas-neue font-size-14">¿que quieres Conseguir? <span className='ms-auto'>nueva orden</span></b></div>
                </Col>
            </Row>

            <div>
                <Nav className="promote-tabs" tabs>
                    <NavItem className="col text-center text-uppercase">
                        <NavLink className={`font-bebas-neue fw-bold font-size-14 ${activeTab === 1 ? 'active text-dark-4' : 'border text-secondary'}`} style={{fontSize: 10}} onClick={() => setActiveTab(1)}>seguidores</NavLink>
                    </NavItem>
                    <NavItem className="col text-center text-uppercase">
                        <NavLink className={`font-bebas-neue fw-bold font-size-14 tour-step-promote-1 ${activeTab === 2 ? 'active text-dark-4' : 'border text-secondary'}`} style={{fontSize: 10}} onClick={() => setActiveTab(2)}>likes</NavLink>
                    </NavItem>
                    <NavItem className="col text-center text-uppercase">
                        <NavLink className={`font-bebas-neue fw-bold font-size-14 ${activeTab === 3 ? 'active text-dark-4' : 'border text-secondary'}`} style={{fontSize: 10}} onClick={() => setActiveTab(3)}>REPRODUCCIONES</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                        <Card className="border-top-0 rounded-0 rounded-bottom">
                            <CardBody className="px-2 pt-0 pb-4">
                                <SelectProfile accounts={accounts} setDefault={setDefaultAccount} />
                                <PromoteProfile config={config} account={account} quantityOfFollow={quantityOfFollow} setQuantityOfFollow={setQuantityOfFollow} />
                            </CardBody>
                        </Card>
                    </TabPane>
                    <TabPane tabId={2}>
                        <Card className="border-top-0 rounded-0 rounded-bottom">
                            <CardBody className="p-2">
                                <div className='tour-step-promote-2'>
                                    <SelectProfile accounts={accounts} setDefault={setDefaultAccount} />
                                </div>
                                
                                <Row className="promote-cards mt-3">
                                    { config && posts?.items?.map((item, key) => {
                                        return (
                                            <PromoteCards 
                                                key={key}
                                                mediaId={item?.pk}
                                                type="likes"
                                                value={getValue(item.code, itemsLike)}
                                                video={item?.product_type === 'clips'}
                                                images={item.image_versions2 ? item.image_versions2?.candidates : item.carousel_media[0].image_versions2?.candidates}
                                                imageSize={{width: 320, height: 320}}
                                                magnetsPerItem={config?.magnets_per_like} 
                                                maxInteractions={config?.max_likes}
                                                onChange={(value, magnets, is_premium, image_url) => {
                                                    console.log('image', image_url)
                                                    let _itemsLike = [...itemsLike];
                                                    let _itemExist = false;
                                                    _itemsLike.forEach((_item, index) => {
                                                        if(_item.shortcode === item.code) {
                                                            if(value > 0) {
                                                                _itemsLike[index].quantity = value;
                                                                _itemsLike[index].magnets = magnets;
                                                                _itemsLike[index].is_premium = is_premium;
                                                            }
                                                            else {
                                                                _itemsLike.splice(index, 1);
                                                            }
                                                            _itemExist = true;
                                                        }
                                                    })
                                                    if(!_itemExist) {
                                                        _itemsLike.push({
                                                            objective: 'likes',
                                                            shortcode: item.code,
                                                            media_id: item.pk,
                                                            quantity: value,
                                                            magnets: magnets,
                                                            is_premium: is_premium,
                                                            thumbnail_url: image_url,
                                                            is_video: item?.product_type === 'clips',
                                                            comment_count: item?.comment_count,
                                                            like_count: item?.like_count,
                                                            view_count: item?.view_count,
                                                        })
                                                    }
                                                    setItemsLike(_itemsLike);
                                                }}
                                            />
                                        )
                                    })}

                                    {loadingPosts && !posts ? 
                                        <>
                                            <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                            <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                            <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                            <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                            <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                            <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                            <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                            <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                        </>
                                    : null}

                                    <div>
                                        {/* {loadingPosts && !posts ? <p className="font-size-14 text-center">Cargando... <Spinner size="sm" /></p> : null} */}
                                    </div>
                                </Row>
                                <div className="d-flex justify-content-center">
                                    {posts?.next_max_id ? 
                                        <Button color="danger" className="text-uppercase font-size-14 px-3" disabled={loadingPosts} onClick={() => getPosts('12', posts?.next_max_id)}>
                                            {!loadingPosts ? 'Ver más' : 'Cargando...'}
                                            {loadingPosts ? <Spinner size="sm" /> : null}
                                        </Button> 
                                    : null}
                                </div>
                            </CardBody>
                        </Card>
                    </TabPane>
                    <TabPane tabId={3}>
                        <Card className="border-top-0 rounded-0 rounded-bottom">
                            <CardBody>
                                <SelectProfile accounts={accounts} setDefault={setDefaultAccount} />
                                <div className="promote-cards mt-3">
                                    { config && videos?.items?.map((item, key) => {
                                        return (
                                            <PromoteCards 
                                                key={key}
                                                mediaId={item?.media?.pk}
                                                type="views"
                                                value={getValue(item.media.code, itemsView)}
                                                images={item.media.image_versions2.candidates}
                                                imageSize={{width: 320, height: 569}}
                                                magnetsPerItem={config?.magnets_per_video_view} 
                                                maxInteractions={config?.max_video_view}
                                                onChange={(value, magnets, is_premium, image_url) => {
                                                    console.log('item', item.media.pk)
                                                    let _itemsView = [...itemsView];
                                                    let _itemExist = false;
                                                    _itemsView.forEach((_item, index) => {
                                                        if(_item.shortcode === item.media.code) {
                                                            if(value > 0) {
                                                                _itemsView[index].quantity = value;
                                                                _itemsView[index].magnets = magnets;
                                                                _itemsView[index].is_premium = is_premium;
                                                            }
                                                            else {
                                                                _itemsView.splice(index, 1);
                                                            }
                                                            _itemExist = true;
                                                        }
                                                    })
                                                    if(!_itemExist) {
                                                        _itemsView.push({
                                                            objective: 'views',
                                                            shortcode: item?.media?.code,
                                                            media_id: item?.media?.pk,
                                                            quantity: value,
                                                            magnets: magnets,
                                                            is_premium: is_premium,
                                                            thumbnail_url: image_url,
                                                            is_video: true,
                                                            comment_count: item?.media?.comment_count,
                                                            like_count: item?.media?.like_count,
                                                            view_count: item?.media?.play_count, // item?.media?.view_count (para cantidad de visualizaciones)
                                                        })
                                                    }
                                                    setItemsView(_itemsView);
                                                }}
                                            />
                                        )
                                    })}

                                {loadingVideos && !videos ? 
                                    <>
                                        <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                        <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                        <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                        <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                        <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                        <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                        <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                        <div className="promote-card mb-3 promote-skeleton"><Skeleton /></div>
                                    </>
                                : null}

                                </div>
                                {/* <div className="d-flex justify-content-center text-center">
                                    {loadingVideos && !videos ? <p className="font-size-14 text-center">Cargando... <Spinner size="sm" /></p> : null}
                                </div> */}
                                <div className="d-flex justify-content-center">
                                    {videos?.paging_info?.max_id ?
                                        <Button color="danger" className="text-uppercase font-size-14 px-3" disabled={loadingVideos} onClick={() => getVideos('12', videos?.paging_info?.max_id, csrftoken)}>
                                            {!loadingVideos ? 'Ver más' : 'Cargando...'}
                                            {loadingVideos ? <Spinner size="sm" /> : null}
                                        </Button> 
                                    : null}
                                </div>
                            </CardBody>
                        </Card>
                    </TabPane>
                </TabContent>
            </div>

            {           
                isPrivate ? 
                <Alert color="danger" className="mt-3 p-3">Las cuentas privadas no pueden promocionarse en la app.</Alert>
                : null
            }

            <Card className="mt-2 border-0 p-0 px-3">
                <CardBody className="d-flex p-0">
                    {/* <div className="text-dark-6 font-size-6">
                        {activeTab === 2 ? `${config?.magnets_per_like === 1 ? 'IMÁN' : 'IMANES'} POR CADA LIKE` : null}
                        {activeTab === 3 ? `${config?.magnets_per_video_view === 1 ? 'IMÁN' : 'IMANES'} POR CADA REPRODUCCIÓN` : null}
                    </div> */}
                    <div className="ms-auto d-flex align-items-center">
                        <div className="text-uppercase font-bebas-neue fw-bold text-dark-2 font-size-16">total de la orden</div>
                        <div className="text-danger ms-4 text-danger-2 font-bebas-neue fw-bold font-size-20 tour-step-promote-7">
                            {activeTab === 1 ? `-${quantityOfFollow * (config?.magnets_per_follower ? config?.magnets_per_follower : 0)}` : null}
                            {activeTab === 2 ? `-${totalLikes}` : null}
                            {activeTab === 3 ? `-${totalViews}` : null}
                        </div>
                        <img src={iconMagnets} className="promote-magnets ms-1 mb-1" />
                    </div>
                </CardBody>
            </Card>
            <div className="text-center mt-2">
                <Button 
                    color="danger" 
                    className="text-uppercase font-bebas-neue fw-bold font-size-16 py-2 px-4 tour-step-promote-5"
                    disabled={(activeTab === 1 && quantityOfFollow === 0 || activeTab === 2 && totalLikes === 0 || activeTab === 3 && totalViews === 0)}
                    onClick={() => sendOrder()}
                >
                    {!sendingOrder ? 'enviar orden' : 'Aguarde... '}
                    {sendingOrder ? <Spinner size="sm" /> : null}
                </Button>
            </div>

            <div className="d-flex justify-content-end mt-5">
                <Button color="link" className="text-uppercase text-secondary font-bebas-neue fw-bold text-dark-5 font-size-14 mt-4 tour-step-promote-6" onClick={() => navigate('/promote/history/')}>ver todas +</Button>
            </div>
        </Container>
    )
}

export default Promote;