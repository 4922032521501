import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Badge, Modal, ModalBody, Spinner, ModalHeader } from 'reactstrap';
import axios from 'axios';
import { useTour } from '@reactour/tour';
import Skeleton from 'react-loading-skeleton';
import ViewSelector from '../components/ViewSelector';
import { isMobile, viewImage } from '../utils'

import iconMagnets from '../assets/icons/icon-circle-navbar.svg';

var CryptoJS = require("crypto-js");

var profileToAction = null;

const colors = [
    'bg-danger-gradient-horizontal',
    'bg-dark-gradient-horizontal',
    'bg-warning-gradient-horizontal',
    'bg-info-gradient-horizontal'
]
const steps = [
    {
        selector: '.tour-step-follow-1',
        content: 'Puedes personalizar la vista de los paneles de interacción en cualquier momento, para una mejor adaptación visual de cada usuario.',
    },
    {
        selector: '.tour-step-follow-2',
        content: 'Puedes personalizar la vista de los paneles de interacción en cualquier momento, para una mejor adaptación visual de cada usuario.',
    },
    {
        selector: '.tour-step-follow-3',
        content: 'Este indicador muestra la cantidad de imanes que ofrece cada usuario en promoción por cada acción hacia su cuenta de Inatagram.',
    },
    {
        selector: '.tour-step-follow-5',
        content: <p className="font-size-10">Estás a punto de ser dirigid@ al perfil de instagram seleccionado para que puedas seguirlo, a cambio recibirás los imanes acordados, pero si dejas de seguir a los usuarios de SHAZU que te compensaron por ello, serás penalizad@ y tu cuenta podría ser eliminada de la comunidad. <br/><br/> Para detectar este tipo de acciones, realizamos rastreos continuos de cada acción asegurandonos de que secumplan nuestras normas comunitarias.</p>,
    }
]

var __orders = []

const Follow = ({ getProfile, defaultAccount }) => {
    const [view, setView] = useState('grid');
    const [intialLoad, setIntialLoad] = useState(true);
    const [orders, setOrders] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [page, setPage] = useState(1)
    const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
    const [modal, setModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [status, setStatus] = useState('');
    const [showButton, setShowButton] = useState(null);
    const [checking, setChecking] = useState(false);
    const [checkingFail, setCheckingFail] = useState(false);

    const { setIsOpen, setSteps, setCurrentStep } = useTour()

    const _setOrders = (_orders) => {
        setOrders(_orders);
        __orders = _orders;
    }

    const getOrders = (pagination) => {
        setIsFetchingNextPage(true);
        axios.get(`/api/orders/items/?page=${pagination}&objective=followers`)
            .then(res => {
                setIntialLoad(false);
                if(pagination > 1) _setOrders(orders.concat(res.data.results));
                else _setOrders(res.data.results);

                setHasNextPage(res.data.next);
                setIsFetchingNextPage(false);
            })
            .catch(err => {
                setHasNextPage(false);
                setIsFetchingNextPage(false);
                console.log(err);
            })
    }



    const goToPost = async (item) => {
        setModal(true)
        setLoadingModal(true)
        setStatus(`Verificando el perfil de @${item.iusername}...`)
        setShowButton(null)
        setCheckingFail(false)

        try {
            let checkDailyPermission = await axios.get('/api/orders/check_daily_permission')
            if(!checkDailyPermission.data.follow) {
                setModal(false)
                setLoadingModal(false)
                setStatus('')
                setShowButton(null)
                setCheckingFail(false)            
                await alert('Ha alcanzado tu límite diario de seguir.')
                return;
            }
        } catch(err) {
            await alert('Hubo un error al verificar su límite diario.')
            return;
        }

        axios.get('/api/instagram/web_profile_info/?gotopost=true&username=' + item.iusername, {
            withCredentials: true
        })
            .then((res) => {
                setLoadingModal(false)
                if(
                    (res.data.data.user.followed_by_viewer || res.data.data.user.requested_by_viewer) && 
                    res.data.data.user.username === item.iusername
                ) {
                    setStatus(`Ya estabas siguiendo a @${item.iusername} con el usuario @${defaultAccount.iusername}`)
                    removeFromFeed(item.id)
                    setTimeout(() => {
                        setModal(false)
                        setStatus('')
                    }, 2000)
                } else {
                    setStatus(`Serás redirigido al perfil de Instagram del usuario para seguirlo.<br /> Después de realizar la acción, debes regresar a Shazu para recibir tus imanes.`)
                    setTimeout(() => {
                        // var a = document.createElement('a');
                        // a.target="_blank";
                        if(navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                            window.location.href = `instagram://user?username=${item.iusername}` 
                        }
                        else if(navigator.userAgent.match(/Android/i)) {
                            window.location.href = `https://www.instagram.com/_u/${item.iusername}/`
                        } else {
                            var a = document.createElement('a');
                            a.href = `https://www.instagram.com/${item.iusername}`
                            a.target="_blank";
                            a.click()
                        }

                        profileToAction = item;
                        setShowButton(item)
                    }, 2000)
                }
            })
            .catch(async (err) => {
                setModal(false)
                setStatus('')
            })

        // let a = document.createElement('a');
        // a.target="_blank";
        // a.href=`https://www.instagram.com/${item.iusername}/`;
        // a.click();
    }

    const follow = (item, hash) => {
        let _orders = __orders.filter(order => order.id !== item.id);
        axios.post(`/api/orders/perform_action/?hash=${hash}`)
            .then(res => {
                setStatus(`Hemos añadido <b>+${item.magnets}</b> imanes a tu cuenta de Shazu`)
                setShowButton(null)
                getProfile()
                profileToAction = null;
                setTimeout(() => {
                    _setOrders(_orders);
                    setModal(false)
                    setStatus('')
                    setChecking(false)
                    setCheckingFail(false)
                }, 3000)
            })
            .catch(async (err) => {
                if(err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
                    _setOrders(_orders);
                }
                await alert(err.response.data.detail);

                setModal(false)
                setStatus(false)
                setChecking(false)
                setCheckingFail(false)
            })
    }

    const check = (item) => {
        setChecking(true)
        axios.get('/api/instagram/web_profile_info/?username=' + item.iusername, {
            withCredentials: true
        })
            .then((res) => {
                setChecking(false)
                if(
                    (res.data.data.user.followed_by_viewer || res.data.data.user.requested_by_viewer) && 
                    res.data.data.user.username === item.iusername
                ) {
                    const payload = JSON.stringify({id: item.id})
                    var derived_key = CryptoJS.enc.Base64.parse("LefjQ2pEXmiy/nNZvEJ43i8hJuaAnzbA1Cbn1hOuAgA=")
                    var iv = CryptoJS.enc.Utf8.parse("1020304050607080");
                    var hash = CryptoJS.AES.encrypt(payload, derived_key, {iv: iv, mode: CryptoJS.mode.CBC}).toString();
                    
                    follow(item, encodeURIComponent(hash))

                    // setStatus(`Ya hemos añadido tu ${item.magnets} a tu cuenta de Shazu`)
                    // removeFromFeed(item.id)
                    // setTimeout(() => {
                    //     setModal(false)
                    //     setStatus('')
                    // }, 2000)
                } else {
                    setStatus(`No estás siguiendo el perfil @${item.iusername} con el usuario @${defaultAccount.iusername}.`)
                    setCheckingFail(true)
                }
            })
    }


    const removeFromFeed = (id) => {
        axios.post(`/api/orders/remove_from_feed/?item=${id}`)
            .then(res => {
                let _orders = orders.filter(order => order.id !== id);
                _setOrders(_orders);
            })
            .catch(async (err) => {
                await alert(err.response.data.detail);
            })
    }

    const getNextPage = () => {
        if(hasNextPage) {
            let _page = page + 1;
            setPage(_page);
            getOrders(_page);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if(orders.length > 0 && localStorage.viewed_tour_2 !== 'true') {
                localStorage.viewed_tour_2 = 'true'
                setIsOpen(true)
                setSteps(steps)
                setCurrentStep(0)
            }
        }, 200)
    }, [orders])

    useEffect(() => {
        window.addEventListener('focus', () => {
            if(profileToAction) {
                check(profileToAction)
            }
        })
        getOrders(1)
    }, [])

    return (
        <>
            <Container className={`follow my-3 px-0 mx-auto ${orders.length === 0 ? 'h-100' : ''}`}>
                {orders.length ? <ViewSelector types={['list', 'grid']} active={view} onClick={setView} /> : null}
                <Row className="follow-row my-2">
                    {orders.map((item, key) => {
                        return (
                            <Col className={`follow-col ${view === 'grid' ? 'follow-col-grid' : 'follow-col-list'}`} key={key} xs={view === 'grid' ? 6 : 12}>
                                <Card className={`follow-card ${view} bg-secondary bg-opacity-10 position-relative mb-1 px-0 pb-0 border-0`} style={{overflow: 'hidden', height: view === 'grid' ? 'auto' : ''}}>
                                    <div className={`${view === 'grid' ? 'follow-card-circle-grid' : 'follow-card-circle-list'} rounded-pill ${colors[key % 4]} border border-2 border-white`} />
                                    <CardBody className={`d-flex align-items-center text-center position-relative p-2 ${view === 'grid' ? 'flex-column' : ''}`} onClick={() => goToPost(item)}>
                                            {view === 'grid' ? <Badge color="white" className={`follow-card-magnets ms-auto font-bebas-neue fw-bold text-dark-2 font-size-10 ${key === 0 ? 'tour-step-follow-3' : 0}`}>+{item?.magnets}<img src={iconMagnets} /></Badge> : null}
                                            <img src={process.env.REACT_APP_API_URL + item?.iprofile_pic} className={`border border-5 border-white bg-secondary d-block ${view === 'grid' ? 'follow-card-image-grid' : 'follow-card-image-list'}`} />
                                            <div className={view === 'list' ? 'ps-3' : ''}>
                                                <p className="m-0 mt-1 text-uppercase text-dark-4 font-bebas-neue fw-bold font-size-14"><b>{item?.ifullname}</b></p>
                                                {view === 'list' ? <b className="magnets">+{item?.magnets} <img src={iconMagnets} /></b> : null}
                                                <div className={`d-flex ${view === 'grid' ? 'flex-column' : 'flex-column-reverse'}`}>
                                                    <p className="follow-card-username m-0 text-uppercase text-dark-5 font-bebas-neue fw-light font-size-10"><small>@{item?.iusername}</small></p>
                                                    <Button color="link" className={`text-danger-2 text-decoration-none fw-bold p-0 font-size-24 font-poppins ${view === 'list' ? 'bg-white' : ''} ${key === 0 ? 'tour-step-follow-5' : ''}`}>SEGUIR</Button>
                                                </div>
                                            </div>
                                    </CardBody>
                                    <div className={`${colors[key % 4]} ${view === 'grid' ? 'rounded-bottom' : 'rounded-left position-absolute'}`} style={{minHeight: view === 'grid' ? '1.5vw' : '100%', width: view === 'grid' ? '100%' : '1.5vw', right: view === 'list' ? 0 : null}} />
                                </Card>
                            </Col>
                        )
                    })}

                    {intialLoad ? 
                        <>
                            <Col className='follow-col follow-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='follow-col follow-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='follow-col follow-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='follow-col follow-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='follow-col follow-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                            <Col className='follow-col follow-col-grid' xs={6}><Skeleton className='card-skeleton' /></Col>
                        </>
                    : null}
                </Row>
                

                {/* {intialLoad ? 
                <div className="d-flex flex-column justify-content-center align-items-center text-center h-100">
                    <p className="font-size-16 mb-0">
                        Cargando... <Spinner size="sm" />
                    </p>
                </div>
                : null} */}

                {!intialLoad && orders.length === 0 ?
                <div className="d-flex flex-column justify-content-center align-items-center text-center h-100">
                    <p className="font-size-12 mb-0">
                        En esto momento no hay usuarios disponibles.<br/>
                        Ten un poco de paciencia, pronto llegarán.
                    </p>
                    <Button color="danger" className="text-uppercase font-size-14 mt-3 px-4" onClick={() => getOrders(1)}>{isFetchingNextPage ? 'Actualizando...' : 'Actualizar'} {isFetchingNextPage ? <Spinner size="sm" /> : null}</Button>
                </div>
                : null}

                {hasNextPage ?
                        <div className="text-center my-3">
                            <Button color="danger" size="sm" onClick={() => getNextPage()}>VER MÁS</Button>
                        </div>
                : null}

                <Modal isOpen={modal} toggle={() => setModal(false)} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={() => setModal(false)}>Shazu</ModalHeader>
                    <ModalBody className="d-flex justify-content-center align-items-center text-center">
                        <div>
                            <p className="m-0" dangerouslySetInnerHTML={{ __html: status }} ></p>
                            {loadingModal ? <Spinner /> : null}
                            {showButton ? <Button color="danger" className="text-uppercase font-size-14 mt-3 px-4" onClick={() => check(showButton)}>{!checking ? (!checkingFail ? 'Ya seguí el perfil' : 'Revisar otra vez') : 'Comprobando...'} {checking ? <Spinner size="sm" /> : null}</Button> : null}
                        </div>
                    </ModalBody>
                </Modal>
            </Container>
        </>

    )
}

export default Follow;