import React from 'react';
import { Portal } from 'react-portal';

import iconClose from  '../assets/icons/close.svg';

const ModalFull = ({ children, isOpen, className }) => {
    const [show, setShow] = React.useState(false);
    const [showInner, setShowInner] = React.useState(false);

    const toggleShow = (b) => {
        if(b) setShow(b);
        if(!b) setShowInner(b);
        setTimeout(() => {
            if(b) setShowInner(b);
            if(!b) setShow(b);
        })
    }

    React.useEffect(() => {
        toggleShow(isOpen);
    }, [isOpen])

    React.useEffect(() => {
        toggleShow();
    }, [])

    return (
        show ?
            <Portal>
                <div className={`modal-full ${showInner ? 'modal-full-open' : ''} ${className}`}>
                    {children}
                </div>
            </Portal>
        : null
    )
}

const ModalFullHeader = ({ left, center, right, toggle }) => {
    return (
        <div className="modal-full-header">
            <div className="d-flex justify-content-center align-items-center h-100 w-100 bg-danger-gradient text-white">
                <div className="modal-full-header-left"></div>
                <div className="modal-full-header-center">{center}</div>
                <div className="modal-full-header-right">
                    {!right ? <div className="modal-full-header-close" onClick={() => toggle()}><img src={iconClose} /></div> : null}
                </div>
            </div>
        </div>
    )
}

const ModalFullBody = ({ children, className }) => {
    return (
        <div className={`modal-full-body ${className}`}>
            {children}
        </div>
    )
}

export { ModalFull, ModalFullHeader, ModalFullBody }