import React, { useState } from 'react';
import { Card, CardBody, ButtonGroup, Button } from 'reactstrap';

import iconUserFollow from '../assets/icons/icon-user-follow.svg';

const PromoteProfile = ({ config, account, quantityOfFollow, setQuantityOfFollow }) => {
    const [showMessage, setShowMessage] = useState(false);

    return (
        <Card className="mt-3 bg-gray-4 border-0">
            <CardBody className="p-0">
                <div className="d-flex">
                    <div>
                        <div className="border border-1 border-secondary border-opacity-75 rounded bg-white p-3 py-2 font-size-4 text-center">
                            <img src={iconUserFollow} className="d-block mx-auto mb-1 promote-icon-user" />
                            Seguidores
                        </div>
                    </div>
                    <div className="ps-2 text-uppercase">
                        <div style={{fontSize: 10}}>
                            <b className="font-bebas-neue fw-bold text-dark-2 font-size-10">PROMOCIONA TU PERFIL</b><br/>
                            <span className="text-dark-5 font-size-8 d-block">@{account?.iusername}</span>
                            <small className="text-danger-2 font-bebas-neue fw-bold font-size-6">{config?.magnets_per_follower} IMANES POR CADA SEGUIDOR</small>
                        </div>
                    </div>
                    <div className="ms-auto d-flex align-items-center">
                        <div>
                            <ButtonGroup className="promote-probile-btns">
                                <Button color="white" className="bg-white border rounded-pill text-dark-5" onClick={() => {
                                    setQuantityOfFollow(quantityOfFollow > 0 ? quantityOfFollow - 1 : quantityOfFollow);
                                    setShowMessage(false);
                                }}>-</Button>
                                <div className="font-bebas-neue fw-bold text-danger-2 font-size-24 px-1">{quantityOfFollow}</div>
                                <Button color="white" className="bg-white border rounded-pill text-dark-5" onClick={() => {
                                    setQuantityOfFollow(quantityOfFollow < config?.max_followers ? quantityOfFollow + 1 : quantityOfFollow);
                                    setShowMessage(quantityOfFollow + 1 > config?.max_followers)
                                }}>+</Button>
                            </ButtonGroup>
                            {showMessage ? <div className="text-dark-6 font-size-6">MAXIMO {config?.max_followers} POR ORDEN</div> : null}
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default PromoteProfile;