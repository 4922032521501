import React from 'react';
import { Card, CardHeader, CardBody, Button, ListGroup, ListGroupItem, ButtonGroup } from 'reactstrap';
import axios from 'axios';
import PayButton from './PayButton';
import Drawer from './Drawer';

const MiniCart = ({ isOpenMiniCart, toggleMiniCart, cartItems, getCartItems }) => {   
    const updateCartItem = (id, product, quantity) => {
        axios.patch(`/api/checkout/cart/${id}/`, {
            product: product,
            quantity: quantity
        })
            .then((res) => {
                getCartItems();
            })
            .catch((err) => {
                console.log('error addToCard', err.response.data);
            })
    }

    const removeCartItem = (id) => {
        axios.delete(`/api/checkout/cart/${id}/`)
            .then((res) => {
                getCartItems();
            })
            .catch((err) => {
                console.log('error getItems', err.response.data);
            })
    }

    const getSubtotal = () => {
        let subtotal = 0;
        cartItems.map((item) => {
            subtotal += item.product_details.price * item.quantity;
        })
        return subtotal;
    }

    return (
        <Drawer isOpen={isOpenMiniCart} direction="right" toogle={() => toggleMiniCart()}>
            <CardHeader className="d-flex align-items-center bg-white font-size-18">
                Carrito
                <Button className="ms-auto bg-white border-0 text-secondary font-size-24 pt-0" onClick={() => toggleMiniCart()}>×</Button>
            </CardHeader>
            {cartItems.length ? 
                <>
                    <CardBody>
                        <ListGroup>
                            {cartItems.map((item, key) => {
                                return (
                                    <ListGroupItem key={key}>
                                        <p className="font-size-14">
                                            <b>{item.product_details.name}</b> <br/>
                                            {item.product_details.price}€ x {item.product_details.magnets}
                                        </p>
            
                                        <ButtonGroup>
                                            <Button color="light" onClick={() => item?.quantity > 1 ? updateCartItem(item.id, item.product, item?.quantity - 1) : removeCartItem(item.id)}>-</Button>
                                            <Button color="light" disabled>{item.quantity}</Button>
                                            <Button color="light" onClick={() => updateCartItem(item.id, item.product, item?.quantity + 1)}>+</Button>
                                        </ButtonGroup>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                    </CardBody>
                    <div className="p-3">
                        <div className="d-flex">
                            <div className="font-size-18"><b>Subtotal</b></div>
                            <div className="ms-auto font-size-18"><b>{getSubtotal()}€</b></div>
                        </div>
                        
                        <PayButton cartItems={cartItems} />
                    </div>
                </>
            :
                <div className="d-flex align-items-center justify-content-center h-100">
                    <p>El carrito está vacío</p>
                </div>
            }
        </Drawer>
    )
}

export default MiniCart;