import React, { useEffect, useState } from 'react';
import {
    Container,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    InputGroup,
    Input,
    InputGroupText
} from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import {
    EmailShareButton,
    WhatsappShareButton,
  } from "react-share";

const FrequentQuestions = ({ profile }) => {
    const [allQuestions, setAllQuestions] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [open, setOpen] = useState('');

    const toggle = (id) => {
        if (open === id) {
          setOpen();
        } else {
          setOpen(id);
        }
    };

    const filterQuestions = (text) => {
        if (text === '' || text === null) setQuestions(allQuestions);

        var filteredItems = allQuestions.filter((item, i) => {
          return item.question.toLowerCase().indexOf(text.toLowerCase()) > -1;    
        })  
        setQuestions(filteredItems);
    }

    useEffect(() => {
        axios.get('/api/cms/frequentquestions/')
            .then(res => {
                setAllQuestions(res.data);
                setQuestions(res.data);
            })
    }, [])

    return (
        <div className="frequent-questions py-2">
            <div className="frequent-questions-container">
                <div className="frequent-questions-search d-flex justify-content-center">
                    <InputGroup className="mb-2 w-75">
                        <Input placeholder="Buscar pregunta..." onChange={(e) => filterQuestions(e.target.value)} />
                        <InputGroupText><i className="material-icons">search</i></InputGroupText>
                    </InputGroup>
                </div>
                <Accordion open={open} toggle={toggle}>
                    {questions?.map((question, key) => {
                        return (
                            <AccordionItem key={key} className="mb-1">
                                <AccordionHeader className="frequent-questions-item" targetId={key}>{question.question}</AccordionHeader>
                                <AccordionBody accordionId={key}>
                                    <div className="font-size-10 font-bebas-neue text-dark-5" dangerouslySetInnerHTML={{__html: question.answer.replace(/\r\n/g, '<br/>')}} />
                                </AccordionBody>
                            </AccordionItem>
                        )
                    })}
                </Accordion>             
            </div>
        </div>
    )
}

export default FrequentQuestions;