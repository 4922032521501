import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Container } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';

const Notification = ({ getNotifications }) => {
    const [notification, setNotification] = useState(null);

    const params = useParams();

    const setRead = () => {
        axios.patch(`/api/notifications/${params.id}/`, {read: true})
            .then(() => {
                getNotifications()
            })
    }

    useEffect(() => {
        axios.get(`/api/notifications/${params.id}/`)
            .then((res) => {
                setNotification(res.data);
                setRead();
            })
    }, [])

    return (
        <div className="container-app py-3">
            <p className="text-center font-size-16">{moment(notification?.created_at).format('DD/MM/YYYY')}</p>
            <div className="font-size-16" dangerouslySetInnerHTML={{__html: notification?.message?.replace(/\r\n/g, '<br/>')}} />
        </div>
    )
}

export default Notification;