import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navbar, NavbarToggler, CardBody, CardHeader, Nav, NavItem, Button, NavbarText, Badge } from 'reactstrap';
import axios from 'axios';
import { Portal } from 'react-portal';
import Drawer from './Drawer';
import { viewImage } from '../utils';

import iconMenu from '../assets/icons/icon-menu.svg';
import iconShazu from '../assets/icons/logo-shazu-menu.svg';
import iconMagnet from '../assets/icons/icon-magnet.svg';
import iconNotification from '../assets/icons/icon-notification.svg';
import iconCart from '../assets/icons/icon-cart.svg';
import iconClose from  '../assets/icons/close.svg';
import iconShazu2 from  '../assets/images/shazu.svg';
import iconLogo from '../assets/icons/logo-shazu2.png'
import avatar from '../assets/images/avatar.png';
import iconWhatsapp from '../assets/icons/icon-whatsapp.svg';
import iconInstagram from '../assets/icons/icon-instagram.svg';
import iconFacebook from '../assets/icons/icon-facebook.svg';
import iconTwitter from '../assets/icons/icon-twitter.svg';
import iconYoutube from '../assets/icons/icon-youtube.svg';

const Header = ({ config, pageTitle, defaultAccount, hideHeaderButtons, incompleteProfile, profile, cartItems, toggleMiniCart, logout, isOpenMenu, toggleMenu, notifications }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [pages, setPages] = useState([])

    const location = useLocation();
    const navigate = useNavigate();

    let qtd = 0;
    cartItems.map((item) => {
      qtd += item.quantity;
    })

    const navigateTo = async (to, closeMenu=true) => {
        if(incompleteProfile) {
            await alert(incompleteProfile)
        } else {
            navigate(to)
            if(closeMenu) toggleMenu(false)
        }
    }

    const getColor = () => {
        return location.pathname.includes('/profile') || location.pathname.includes('/recharge') || location.pathname.includes('/tutorial') || location.pathname.includes('/promote/history') ? 'danger' : 'dark';
    }

    const getLogo = () => {
        if(
            location.pathname === '/' ||
            location.pathname === '/follow' ||
            location.pathname === '/like' ||
            location.pathname === '/video' ||
            location.pathname === '/promote' ||
            location.pathname === '/frequent-questions' ||
            location.pathname === '/change-password' ||
            location.pathname.includes('/notifications') ||
            location.pathname.includes('/page/')
        ) {
            return iconShazu;
        }
        else if(
            location.pathname === '/recharge' ||
            location.pathname === '/tutorial'
        ) {
            return iconLogo;
        }
    }

    const getPages = () => {
        axios.get('/api/cms/page/?display_in_menu=true')
            .then((res) => {
                setPages(res.data)
            })
    }

    useEffect(() => {
        getPages();
    }, [])

    const logo = getLogo();

    return (
        <div className="header">
            <Navbar color={getColor()} dark className={`header-navbar ${location.pathname === '/promote' ? 'header-navbar-promote' : ''}`}>
                {!hideHeaderButtons ? <div class="navbar-toggler" onClick={() => toggleMenu()}><img src={iconMenu} /></div> : null}
                <NavbarText className={`${hideHeaderButtons ? 'm-auto' : ''}`}>{logo ? <img src={logo} className="navbar-logo" /> :null}</NavbarText>
                {!hideHeaderButtons ?
                <NavbarText>
                    <div className="d-flex justify-content-end">
                        <div className="navbar-cart tour-step-7" onClick={() => toggleMiniCart()}>
                            <img src={iconCart} /> 
                            <span className={`font-bebas-neue fw-normal font-size-10 ${getColor() === 'danger' ? 'text-white' : 'text-danger'}`}>{qtd}</span>
                        </div>
                        <div className="navbar-notification tour-step-8" onClick={() => navigateTo('/notifications', false)}>
                            <img src={iconNotification} /> 
                            {notifications ? <div className="navbar-notification-circle"></div> : null}
                        </div>
                    </div>
                    <Badge className="fw-bold tour-step-6 font-bebas-neue font-size-10 navbar-magnets">+{profile?.magnets} <img src={iconMagnet} /></Badge> 
                </NavbarText>
                : null}

                {location.pathname.includes('/profile') ?
                <div className="header-profile w-100 pb-3">
                    <span className="font-metropolis text-white font-size-12">PERFIL</span><br/>
                    <span className="header-profile-name font-metropolis text-white font-size-24 d-block">{profile?.first_name} {profile?.last_name}</span>
                </div>
                : null}

                {location.pathname.includes('/shareandwin') || location.pathname.includes('/promote/history') || location.pathname.includes('/balance') ?
                <div className="w-100 text-center">
                    <img src={defaultAccount?.iprofile_pic} className="header-photo rounded-pill border border-white border-3 mb-2" />
                    <div className="font-metropolis fw-bold text-white font-size-22 mb-2">{defaultAccount?.ifullname}</div>
                </div>
                : null}

                {
                    location.pathname.includes('/balance') ? 
                        <p className="text-center w-100 text-white font-poppins fw-normal font-size-14 text-uppercase">
                            Balance <br/>
                            <span className="font-size-34 fw-medium">{profile?.magnets}</span>
                        </p>
                    : null
                }

                {
                    location.pathname.includes('/follow') || 
                    location.pathname.includes('/like') || 
                    location.pathname.includes('/video') || 
                    location.pathname.includes('/shareandwin') ||
                    location.pathname.includes('/promote/history') ||
                    location.pathname.includes('/recharge') ||
                    location.pathname.includes('/frequent-questions') ||
                    location.pathname.includes('/change-password') ||
                    location.pathname.includes('/notifications') ||
                    location.pathname.includes('/karma') ||
                    location.pathname.includes('/tutorial') ||
                    location.pathname.includes('/page/')
                ?
                <div className="header-title mx-auto">
                    <div className="py-2 text-center bg-gray-2 rounded-top-left font-bebas-neue fw-bold font-size-18">
                        {location.pathname.includes('/follow') ? 'PERFILES DESTACADOS' : null}
                        {location.pathname.includes('/like') ? 'PUBLICACIONES DESTACADAS' : null}
                        {location.pathname.includes('/video') ? 'VIDEOS DESTACADOS' : null}
                        {location.pathname.includes('/shareandwin') ? 'comprarte y gana' : null}
                        {location.pathname.includes('/promote/history') ? 'Historial de ordenes' : null}
                        {location.pathname.includes('/recharge') ? 'Recarga magnetismo' : null}
                        {location.pathname.includes('/frequent-questions') ? 'PREGUNTAS FRECUENTES' : null}
                        {location.pathname.includes('/change-password') ? 'CAMBIAR CONTRASEÑA' : null}
                        {location.pathname.includes('/notifications') ? 'NOTIFICACIONES' : null}
                        {location.pathname.includes('/karma') ? 'Karma' : null}
                        {location.pathname.includes('/tutorial') ? 'BIENVENID@!' : null}
                        {location.pathname.includes('/page/') ? pageTitle : null}
                    </div>
                </div>
                : null}

            </Navbar>

            <Portal node={document && document.getElementById('menu')}>
                <Drawer className="header-menu" isOpen={isOpenMenu} direction="left" toogle={() => toggleMenu()}>
                    <div className="header-menu-circle" />
                    <CardHeader className="d-flex align-items-center border-0">
                        <div className="header-menu-left"></div>
                        <div className="header-menu-logo">
                            <img src={iconShazu2} />
                        </div>
                        <div className="header-menu-close" onClick={() => toggleMenu()}>
                            <img src={iconClose} />
                        </div>
                    </CardHeader>
                    <img src={defaultAccount ? defaultAccount?.iprofile_pic : avatar} className="header-menu-image border border-white border-4 d-block m-auto my-2 bg-secondary" />
                    <CardBody className="p-0 pb-0">
                        <Nav className="me-auto" navbar>
                            <NavItem>
                                <div className="nav-link text-uppercase" onClick={() => navigateTo('/')}>Home <div className="header-menu-arrow" /></div>
                            </NavItem>
                            <NavItem>
                                <div className="nav-link text-uppercase" onClick={() => navigateTo('/profile')}>Mi cuenta <div className="header-menu-arrow" /></div>
                            </NavItem>
                            <NavItem>
                                <div className="nav-link text-uppercase" onClick={() => navigateTo('/change-password')}>Cambiar contraseña <div className="header-menu-arrow" /></div>
                            </NavItem>
                            <NavItem>
                                <div className="nav-link text-uppercase" onClick={() => navigateTo('/karma')}>Karma <div className="header-menu-arrow" /></div>
                            </NavItem>
                            <NavItem>
                                <div className="nav-link text-uppercase" onClick={() => navigateTo('/promote/history/')}>MIS CAMPAÑAS <div className="header-menu-arrow" /></div>
                            </NavItem>
                            <NavItem>
                                <div className="nav-link text-uppercase" onClick={() => navigateTo('/shareandwin/')}>comparte y gana <div className="header-menu-arrow" /></div>
                            </NavItem>
                            <NavItem>
                                <div className="nav-link text-uppercase" onClick={() => navigateTo('/recharge')}>Recarga <div className="header-menu-arrow" /></div>
                            </NavItem>
                            <NavItem>
                                <div className="nav-link text-uppercase" onClick={() => navigateTo('/frequent-questions')}>Pregunta frecuentes <div className="header-menu-arrow" /></div>
                            </NavItem>
                            <NavItem>
                                <a href={config?.url_soporte_whatsapp} target="_blank" className="nav-link text-uppercase">Soporte <img src={iconWhatsapp} className="header-menu-icon-whatsapp"  />  <div className="header-menu-arrow" /></a>
                            </NavItem>
                            {/* <NavItem>
                                <div className="nav-link text-uppercase" onClick={() => navigateTo('/page/terminos-y-condiciones')}>TERMINOS Y CONDICIONES - legal</div>
                            </NavItem> */}

                            {pages?.map((page, key) => {
                                return (
                                    <NavItem key={key}>
                                        <div className="nav-link text-uppercase" onClick={() => navigateTo('/page/' + page.slug)}>{page.title}</div>
                                    </NavItem>
                                )
                            })}

                            <NavItem>
                                <div className="nav-link text-uppercase" onClick={() => logout('/recharge')}>Salir</div>
                            </NavItem>
                        </Nav>

                        <div className="header-menu-social pt-5 pb-4">
                            <p className="m-0 text-danger-2 font-bebas-neue font-size-22 fw-bold ps-2">nuestras redes</p>
                            <div className="header-menu-bottom">
                                {config?.url_instagram ? 
                                <a href={config?.url_instagram} target="_blank" className="header-menu-bottom-item d-block text-decoration-none">
                                    <div className="header-menu-bottom-btn">
                                        <img src={iconInstagram} />
                                        @shazuapp
                                    </div>
                                </a>
                                : null}

                                {config?.url_facebook ? 
                                <a href={config?.url_facebook} target="_blank" className="header-menu-bottom-item d-block text-decoration-none">
                                    <div className="header-menu-bottom-btn">
                                        <img src={iconFacebook} />
                                        shazuapp
                                    </div>
                                </a>
                                : null}

                                {config?.url_twitter ? 
                                <a href={config?.url_twitter} target="_blank" className="header-menu-bottom-item d-block text-decoration-none">
                                    <div className="header-menu-bottom-btn">
                                        <img src={iconTwitter} />
                                        shazuapp
                                    </div>
                                </a>
                                : null}

                                {config?.url_youtube ? 
                                <a href={config?.url_youtube} target="_blank" className="header-menu-bottom-item d-block text-decoration-none">
                                    <div className="header-menu-bottom-btn">
                                        <img src={iconYoutube} />
                                        shazuapp
                                    </div>
                                </a>
                                : null}
                            </div>
                            <p className="font-bebas-neue fw-light font-size-10 text-center py-3 m-0" onClick={() => navigateTo('/page/terminos-y-condiciones')}>TERMINOS Y CONDICIONES - legal</p>
                        </div>
                    </CardBody>
                </Drawer>
            </Portal>
        </div>
    )
}

export default Header;